@font-face {
    font-family: "OswaldBold";
    src: local("Oswald-Bold"),
        url("../../assets/fonts/Oswald/Oswald-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "BarlowSemiCondensed-Bold";
    src: local("BarlowSemiCondensed-Bold"),
        url("../../assets/fonts/barlow-semi-condensed/BarlowSemiCondensed-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Inter-Regular";
    src: local("Inter-Regular"),
        url("../../assets/fonts/inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Regular";
    src: local("Roboto-Regular"),
        url("../../assets/fonts/roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Impact";
    src: local("Impact"), url("../../assets/fonts/Impact.ttf") format("truetype");
}

@font-face {
    font-family: "Avenir";
    src: local("Avenir"),
        url("../../assets/fonts/avenir-light.ttf") format("truetype");
}

@font-face {
    font-family: "Avenir-medium";
    src: local("Avenir"),
        url("../../assets/fonts/avenir-medium.otf") format("truetype");
}

@font-face {
    font-family: "FUTURA1986";
    src: local("FUTURA1986"),
        url("../../assets/fonts/futura/FUTURA1986.otf") format("truetype");
}


.watchlist-main {
    margin-top: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
}

.watchlist-title-text {
    opacity: 1;
    color: rgba(255, 255, 255, 1);
    font-family: "AvenirNext-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
}

.watchlist-subtitle-text {
    margin-top: 16px;
    opacity: 1;
    color: rgba(255, 255, 255, 1);
    font-family: "AvenirNext-Medium";
    font-size: 18px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: center;
}

@media only screen and (max-width: 360px) {

    .watchlist-main img{
        height: 200px;
    }
    .watchlist-main p{
        font-size: 14px;
    }
}

@media only screen and (max-width: 375px) {

    .watchlist-main img{
        height: 200px;
    }
    .watchlist-main p{
        font-size: 14px;
    }
}

@media only screen and (max-width: 389px) {

    .watchlist-main img{
        height: 200px;
    }
    .watchlist-main p{
        font-size: 14px;
    }
}

@media only screen and (max-width: 420px) {

    .watchlist-main img{
        height: 200px;
    }
    .watchlist-main p{
        font-size: 14px;
    }
}

@media only screen and (max-width: 480px) {

    .watchlist-main img{
        height: 200px;
    }
    .watchlist-main p{
        font-size: 14px;
    }
}

@media only screen and (max-width: 576px) {

    .watchlist-main img{
        height: 200px;
    }
    .watchlist-main p{
        font-size: 14px;
    }
}