.countdown-timer-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
}

.timer-circle {
    position: relative;
    width: 180px;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.timer-circle svg {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
}

.timer-circle circle {
    fill: none;
    stroke-width: 15;
    stroke: #333;
}

.timer-circle circle.progress {
    stroke: #f4f4f4;
    transition: all 1s linear;
    stroke-linecap: round;
}

.timer-text {
    font-size: 32px;
    color: black;
    font-weight: bold;
    z-index: 1;
}

.timer-label {
    font-size: 16px;
    color: black;
    text-transform: uppercase;
    margin-top: 10px;
    z-index: 1;
}

.time-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 0;
    color: black;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}

.img-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .img-div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }