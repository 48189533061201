@font-face {
    font-family: "Avenir";
    src: local("Avenir"),
        url("../../assets/fonts/avenir-light.ttf") format("truetype");
}

@font-face {
    font-family: "Avenir-medium";
    src: local("Avenir"),
        url("../../assets/fonts/avenir-medium.otf") format("truetype");
}


#order-confiramtion-modal {
    max-width: 638px;
}

#order-confiramtion-modal .modal-content {
    background-color: #F8FAFB;
    height: auto;
    border-radius: 8px;
    border: unset;
    text-align: center;
    padding-bottom: 30px;
}

.portal-order-confiramtion-modal {
    margin-top: 17px;
}

#order-confiramtion-modal p {
    margin-bottom: 0 !important;
}

.order-title p {
    margin-bottom: 0 !important;
}

.order-title-text {
    color: #000;
    font-family: "Avenir";
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0px;
}

.stream-text {
    color: rgba(0, 0, 0, 1);
    font-family: "Avenir-medium";
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0px;
    text-transform: uppercase;
}

.link-text {
    color: rgba(0, 9, 190, 1);
    font-family: "Avenir";
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0px;
}

.order-image {
    margin: 0 auto;
    margin-top: 29px;
    width: 337px;
    height: 196px;
    border-radius: 8px;
}

.order-promo {
    margin: 20px auto;
    width: 290px;
}

.order-subtotal,
.order-total {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 17px;
}

.order-subtotal h1,
.order-total h1 {
    color: rgba(88, 88, 88, 1);
    font-family: "Avenir";
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    line-height: 24px;
}

.order-subtotal h2,
.order-total h2 {
    color: rgba(88, 88, 88, 1);
    font-family: "Avenir-medium";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: right;
    line-height: 24px;
    text-transform: uppercase;
}

.order-total h1{
    font-size: 16px;
    color: #1B2125;
}

.order-total h2{
    font-size: 16px;
    color: #1B2125;
}

.order-line {
    border: 2px solid rgba(200, 210, 218, 1);
    width: auto;
    color: #C8D2DA;
    margin: 20px 0;
}

.order-title img {
    height: 12px;
    width: 12px;
}

#order-confiramtion-modal .icon-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 29px;
    width: 29px;
}

#order-confiramtion-modal .top-hr {
    width: 497px;
    border-top: 1px solid #C0C0C0;
    color: #C0C0C0;
    margin-top: 13px;
    margin-bottom: 19px;
}
