@font-face {
    font-family: "Avenir-medium";
    src: local("Avenir"),
        url("../../assets/fonts/avenir-medium.otf") format("truetype");
}

@font-face {
    font-family: "Avenir";
    src: local("Avenir"),
        url("../../assets/fonts/avenir-light.ttf") format("truetype");
}

.countdown-info {
    color: #3DACFF;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    font-family: "Avenir-medium";
    letter-spacing: 1px;
}

.countdown-main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.countdown-number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 82px;
}

.countdown-number>p {
    margin: 0;
}

.countdown-number>p:nth-child(1) {
    color: white;
    font-size: 60px;
    font-weight: 300;
    font-family: "Avenir";
}

.countdown-number>p:nth-child(2) {
    color: white;
    font-size: 13px;
    font-weight: 300;
    text-transform: uppercase;
    transform: translateY(-75%);
    font-family: "Avenir";
}

.countdown-column {
    font-weight: 500;
    font-size: 42.77px;
    color: #3DACFF;
    display: block;
    margin-inline: 18px;
}

.countdown-btn {
    outline: none;
    border: none;
    background-color: #3DACFF;
    height: 53px;
    opacity: 1;
    color: rgba(0, 0, 0, 1);
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-inline: auto;
    display: block;
    border-radius: 8px;
    width: 412px;
    font-family: "Avenir-medium";
}

.count-replay {
    color: #FFFFFF;
    text-align: center;
    letter-spacing: 0.8999999761581421px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    font-family: "Avenir-medium";
    padding-top: 14px;
}

.countdown-order {
    padding-top: 35px;
}