.buytickets-container {
  display: flex;
  justify-content: center;
  align-items: start;
  height: 100vh;
  background-color: black;
}
.ticket-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  color: white;
  padding: 20px;
  border-radius: 10px;
  width: 350px;
  margin: 0 auto;
  margin-top: 100px;

}

.artist-name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.event-details {
  font-size: 14px;
  text-align: center;
  margin-bottom: 5px;
}

.event-date {
  font-size: 14px;
  margin-bottom: 20px;
}

.ticket-info {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  margin-bottom: 15px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.ticket-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.ticket-type {
  font-size: 16px;
  font-weight: bold;
  margin: 0px;
}

.ticket-price {
  font-size: 16px;
  font-weight: bold;
  margin: 0px;

}

.ticket-subtext {
  font-size: 12px;
  margin: 0px;
}

.order-button {
  background-color: #007bff;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
}

.order-button:hover {
  background-color: #0056b3;
}

.replay-info {
  font-size: 12px;
  text-align: center;
}
