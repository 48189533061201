@font-face {
  font-family: "OswaldBold";
  src: local("Oswald-Bold"),
    url("../../assets/fonts/Oswald/Oswald-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "BarlowSemiCondensed-Bold";
  src: local("BarlowSemiCondensed-Bold"),
    url("../../assets/fonts/barlow-semi-condensed/BarlowSemiCondensed-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-Regular"),
    url("../../assets/fonts/inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url("../../assets/fonts/roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Impact";
  src: local("Impact"), url("../../assets/fonts/Impact.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir";
  src: local("Avenir"),
    url("../../assets/fonts/avenir-light.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-medium";
  src: local("Avenir"),
    url("../../assets/fonts/avenir-medium.otf") format("truetype");
}

@font-face {
  font-family: "FUTURA1986";
  src: local("FUTURA1986"),
    url("../../assets/fonts/futura/FUTURA1986.otf") format("truetype");
}

.d-btn {
  height: 48px;
  width: 160px;
  border-radius: 100px !important;
  background: #003087 !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
  color: #c2ffd7 !important;
  margin-top: 32px;
  border: 2px solid #003087 !important;
  padding: 0 !important;
}

#home .swiper-button-prev {
  top: 57.8% !important;
}

#home .swiper-button-next {
  top: 57.8% !important;
}

.brand-list-1 {
  /* width: 90%; */
  width: 185px;
  height: 77px;
}

.brand-list-2 {
  /* width: 90%; */
  width: 395px;
  height: 77px;
}


.d-appbgimg {
  /* background-image: url("../images/smoke-blue.png"); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

#favorites-contens .swiper-container {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.d-app-img {
  height: 232px;
  width: 156px;
  border: 1px solid #003087cc;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #00000080;
  object-fit: cover;
}

img.img-fluid.d-influ-img {
  height: 232px;
  width: 156px;
  border: 1px solid #003087cc;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #00000080;
  object-fit: cover;
}

.d-video-banner {
  height: 100%;
  width: 100%;
  object-fit: cover !important;
  left: 0;
  position: absolute;
  top: 0;
}

.d-block-description {
  top: -80px;
}

.d-movie-title {
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 32px !important;
  letter-spacing: 1px !important;
  text-align: left !important;
  font-family: "Inter-Regular";
}

.d-view-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 1px;
  text-align: right;
  background: linear-gradient(180deg, #003087 0%, #003087 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-family: "Poppins", sans-serif;
}

.main-content {
  padding-top: 36px;
}

.d-slide-txt {
  width: 100% !important;
}

/* 
#d-slider {
    width: 194px !important;
    height: 288px !important;
    margin-right: 20px !important;
}  */

/* #d-influ-slider {
    width: 194px !important;
    height: 194px !important;
    margin-right: 20px !important;
} */

#d-play-btn {
  font-family: "Barlow Semi Condensed", sans-serif;
  border: 2px solid #2dd0ff !important;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%) !important;
}

.d-set-prev {
  background-color: unset !important;
  left: -43px !important;
  top: 49% !important;
  height: auto !important;
}

.d-set-next {
  right: -43px !important;
  background-color: unset !important;
  top: 49% !important;
  height: auto !important;
}

.s-set-prev {
  background-color: unset !important;
  left: -43px !important;
  top: 49% !important;
  height: auto !important;
}

.s-set-next {
  right: -43px !important;
  background-color: unset !important;
  top: 49% !important;
  height: auto !important;
}

#d-container {
  width: 90% !important;
  margin: 0 auto;
  padding: 0 !important;
}

.d-icon {
  height: 11px !important;
  width: 20px !important;
  color: #fff;
}

#iq-tvthrillers .d-icon {
  color: #ffffff !important;
}

#iq-brand .d-icon {
  color: #ffffff !important;
}

.d-title-txt-one {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 40px;
  letter-spacing: 1px;
  color: #ffffff;
  padding-bottom: 25px;
}

.d-txt-one {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 38px;
  letter-spacing: 1px;
  color: #ffffff;
  overflow: scroll;
  height: 106px !important;
}

.d-txt-one::-webkit-scrollbar {
  display: none;
}

.d-txt-one {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.d-set-overflow {
  overflow: unset !important;
}

/* .d-vedio-border {
    border-top: 1px solid #1891B4;
    border-bottom: 1px solid #1891B4;
} */

.d-brand-img {
  height: 136px;
  width: 224px;
  border-radius: 20px;
  /* box-shadow: 0px 0px 15px 2px #000000bf; */
  border: 1px solid #003087cc;
  object-fit: cover;
}

/* #d-brand-slider {
    width: 289px !important;
    height: 194px !important;
    margin-right: 11px !important;
} */

.d-brand-network {
  font-family: "Inter-Regular";
  font-size: 40px;
  font-weight: 900;
  line-height: 48px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 27px;
}

#b-container {
  width: 90%;
  padding: 0 !important;
  margin: 0 auto !important;
}

.b-set-prev {
  background-color: unset !important;
  top: 45% !important;
  height: auto !important;
  left: -43px !important;
}

.b-set-next {
  background-color: unset !important;
  top: 45% !important;
  height: auto !important;
  right: -43px !important;
}

.d-brand-con {
  width: 100% !important;
  padding: 0 !important;
}

.d-brand-prev {
  background-color: unset !important;
  height: auto !important;
}

.e-brand-prev {
  top: 56.8% !important;
  background-color: unset !important;
  height: auto !important;
}

.d-channel-prev {
  top: 45.5% !important;
  background-color: unset !important;
  height: auto !important;
  left: 32px !important;
}

.d-channel-next {
  top: 45.5% !important;
  background-color: unset !important;
  height: auto !important;
  right: 32px !important;
}

#brand-slider .slick-bg {
  height: auto !important;
}

.d-brand-btn {
  height: 48px;
  width: 160px;
  border-radius: 100px !important;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%) !important;
  border: 2px solid #2dd0ff;
  font-size: 16px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 1px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  padding: 0 !important;
  margin-top: 32px;
  box-shadow: 0px 4px 10px 2px #00000080;
}

#d-brand-network {
  /* z-index: 2;
    position: absolute;
    left: 0;
    right: 0; */
  display: block !important;
}

.d-brand-networks {
  position: relative;
  padding: 0 !important;
}

.ratings span {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 38px;
  letter-spacing: 1px;
  color: #ffffff;
}

.d-season {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 38px;
  letter-spacing: 1px;
  color: #ffffff;
  padding-top: 17px;
}

.d-ratings-and-season {
  display: flex;
  padding-bottom: 10px;
}

.d-trending-info {
  padding: 115px 0 0 0;
}

.d-title-feature {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding-top: 11px;
}

#feature-bgimg {
  background-size: cover !important;
}

.d-set-featured {
  padding-top: 291px;
  padding-bottom: 94px;
}

.set-brand-con {
  margin: 0 !important;
}

#d-space-show {
  margin: 0 !important;
}

.parallax-window {
  padding: 99px 0;
}

.d-para-window {
  padding-top: 99px;
}

.education-block {
  height: 440px;
  padding-top: 1px;
  margin-top: 72px;
}

.parallax-img img {
  text-align: end;
  height: 379px;
  width: 677px !important;
  border-radius: 30px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.6);
}

.edu-initi {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 45px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: left;
  color: #f2f2f2 !important;
}

.para-window {
  width: 1364px !important;
  margin: 0 auto;
}

.edu-description {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #e4e4e4;
  margin: 0 !important;
}

/* #d-tvchannel-slider {
    width: 289px !important;
    height: 194px !important;
    margin-right: 22px !important;
} */

.d-tvchannel-img {
  width: 289px !important;
  height: 194px !important;
  border-radius: 10px !important;
  object-fit: cover;
}

.exclusive-event {
  height: 555px !important;
  width: 100% !important;
}

.exclusive-events {
  box-sizing: content-box;
  /* border-top: 1px solid #1891B4; */
  width: 100% !important;
  /* border-bottom: 1px solid #1891B4; */
}

.event-videos {
  object-fit: cover !important;
  width: 100% !important;
  height: 438px !important;
  padding: 1px;
}

.event-video {
  object-fit: cover !important;
  width: 100% !important;
  height: 555px !important;
}

.exclusive-event-events {
  box-sizing: content-box;
  /* border-top: 1px solid #1891B4; */
  width: 100% !important;
  /* border-bottom: 1px solid #1891B4; */
}

.event-video-events {
  object-fit: cover !important;
  width: 100% !important;
  height: 438px !important;
  padding: 1px;
}

#event-slider .slick-bg {
  height: auto !important;
}

img.d-img1 {
  height: auto !important;
  width: 230px !important;
}

.d-img-brand {
  height: auto !important;
  width: 216px !important;
}

.swiper-slide {
  /* width: 100% !important; */
  height: auto !important;
}

/* .s-margin {
    border-top: 1px solid #2DD0FF;
    margin-top: 0 !important;
    border-bottom: 1px solid #2DD0FF;
} */

.s-margin {
  margin-top: 33px !important;
}

section#space-series {
  margin-top: 0px !important;
}

section#iq-brand {
  margin-top: 72px !important;
}

#iq-trending {
  margin-top: 0px !important;
}

section#iq-tvthrillers {
  border-bottom: 0 !important;
  margin-top: 72px !important;
  border-top: 0 !important;
}

/* #main-content {
    padding-bottom: 64px !important;
} */

#parallex {
  margin-top: 0px;
}

.app-content p {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #bfbfbf;
  padding-top: 12px;
  margin-bottom: 29px !important;
}

.app-content h4 {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: center;
}

.app-technologies {
  padding-bottom: 214px;
  padding-top: 11px;
  margin: 0 auto;
}

.tech-border {
  padding: 10px !important;
}

#tech-section {
  width: 1055px;
  margin: 0 auto;
}

.tech-video {
  height: 285px;
  border-radius: 30px;
  width: 508px !important;
}

#series-rating-mob {
  display: none;
}

#series-rating {
  display: block;
}

.series-btn {
  margin-top: 40px !important;
}

.series-btn1 {
  margin-top: 40px !important;
}

#slider-ratting {
  margin-right: 16px !important;
}

div#feature-bgimg {
  height: 553px !important;
}

div#prevBrand3 {
  top: 56.8%;
}

div#nextBrand3 {
  top: 56.8%;
}

div#nextEvent {
  top: 43%;
}

div#prevEvent {
  top: 43%;
}

.events-title {
  padding-bottom: 12px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px !important;
  line-height: 24px !important;
  letter-spacing: 1px;
  color: #ffffff;
}

#set-sign-in {
  height: 682px;
  width: 482px;
  background: linear-gradient(0deg, #000000 0%, #181818 100%);
  box-shadow: 0px -8px 50px rgb(73 192 69 / 33%);
  border-radius: 35px;
}

#set-sign-in1 {
  background: #0ffa6173;
  border: 0.5px solid #003087;
  /* background: background: #0ffa6173;
    border: 1px solid #003087;
    padding: 1px; */
  border-radius: 35px;
}

.tx-uppercase {
  text-transform: uppercase;
}

.login-acc {
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding-top: 23px;
  margin: 0 !important;
  padding-bottom: 15px !important;
}

.login-acc span {
  /* font-weight: 700; */
  color: #16a34a !important;
  margin-left: 5px;
  font-family: "Poppins", sans-serif;
}

#set-input-width {
  width: 356px;
  margin: 0 auto;
  padding-top: 40px;
}

#set-input-width input::placeholder {
  color: #939393 !important;
}

#set-input-width .first-name {
  padding-right: 10px !important;
}

#set-input-width .second-name {
  padding-left: 10px !important;
}

#set-input-width .form-group {
  margin-bottom: 16px !important;
}

.sign-in-input {
  height: 40px !important;
  border-radius: 23px !important;
  border: 0.5px solid #003087 !important;
  font-family: "Inter-Regular" !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff !important;
  background-color: #252525 !important;
}

.sign-in-input-pwd {
  height: 40px !important;
  border-radius: 23px !important;
  border: 0.5px solid #003087 !important;
  font-family: "Inter-Regular" !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff !important;
  background-color: #252525 !important;
  border-right: none !important;
  border-bottom-right-radius: unset !important;
  border-top-right-radius: 0px !important;
}

.sign-pwd-i-icon {
  border-bottom-right-radius: 23px !important;
  border-top-right-radius: 23px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border: 0.5px solid #003087 !important;
  border-left: none !important;
  background-color: #252525 !important;
}

.sign-in-forgot-pwd {
  display: flex;
  width: 342px;
  margin: 0 auto;
  justify-content: center;
  padding-bottom: 14px;
  padding-top: 8px;
}

.set-app-signin {
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #16a34a;
  padding-bottom: 10px;
}

#set-app-signin {
  color: #ffffff !important;
}

#sign-inbtn.disabled {
  border: 1px solid #616161;
  background: linear-gradient(180deg, #101010 0%, #000000 100%),
    linear-gradient(0deg, #616161, #616161);
  height: 40px;
  width: 356px;
  /* background: linear-gradient(180deg, rgba(45, 208, 255, 0.5) 0%, rgba(24, 145, 180, 0.5) 100%);
    border: 1px solid #2DD0FF;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5); */
  border-radius: 18px !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px !important;
  letter-spacing: 0em !important;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px !important;
  text-transform: unset;
  opacity: 1 !important;
}

#sign-inbtn {
  background: #0ffa6173;
  border: 2px solid #003087;
  height: 40px;
  width: 356px;
  border-radius: 18px !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px !important;
  letter-spacing: 0em !important;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px !important;
  text-transform: unset;
}

.form-group {
  margin-bottom: 1.5rem !important;
}

.sign-in-logo {
  text-align: center;
  margin-top: 40px;
}

.sign-in-logo1 {
  text-align: center;
  margin-top: 40px;
}

.d-container2 {
  width: 479px;
}

.set-sign-up {
  padding-top: 8px;
  padding-bottom: 16px;
}

#sign-up {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.d-eye-off {
  display: block;
  display: inline-block;
  background: url(../images/Vector.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 16px;
  width: 20px;
}

.d-eye-on {
  display: block;
  display: inline-block;
  background: url(../images/Vector-blue.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 16px;
  width: 20px;
}

.sign-vector-img {
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 5px;
}

.set-sign-height {
  height: 422px !important;
}

.forgot-pwd-height {
  height: 454px !important;
}

#sign-group {
  margin-bottom: 9px !important;
}

.sign-up-policy p {
  font-family: "Inter-Regular" !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #bdbdbd;
  margin: 0 !important;
  padding-bottom: 41px;
}

.terms-use {
  color: #16a34a;
  font-family: "Inter-Regular" !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.sign-up-policy {
  width: 276px;
  margin: 0 auto;
}

.sign-in-logo img {
  width: 215px;
  height: auto !important;
}

.sign-in-logo1 img {
  width: 215px;
  height: auto !important;
}

.forgot-pwd {
  margin: 0 !important;
  padding-bottom: 0px;
  padding-top: 24px;
  font-family: "Inter-Regular" !important;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.text-body-pwd {
  padding-top: 8px;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 auto !important;
  width: 358px;
  letter-spacing: 0em;
  text-align: center;
  padding-bottom: 24px;
  color: #ffffff;
}

#forgot-inbtn {
  background: #0ffa6173;
  border: 2px solid #003087;
  height: 40px;
  width: 356px;
  border-radius: 18px !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px !important;
  letter-spacing: 0em !important;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px !important;
  text-transform: unset;
}

#forgot-inbtn.disabled {
  border: 1px solid #616161;
  background: linear-gradient(180deg, #101010 0%, #000000 100%),
    linear-gradient(0deg, #616161, #616161);
  height: 40px;
  width: 356px;
  border-radius: 18px !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px !important;
  letter-spacing: 0em !important;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px !important;
  text-transform: unset;
  opacity: 1 !important;
}

.forgot-input {
  height: 40px !important;
  border-radius: 23px !important;
  border: 0.5px solid #003087 !important;
  font-family: "Inter-Regular" !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px !important;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff !important;
  background-color: #252525 !important;
}

.back-login {
  text-align: center;
  padding-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2dd0ff;
  font-family: "Inter-Regular" !important;
}

.block-social-info {
  margin-top: 10px;
}

.trending-button {
  display: none !important;
}

.tranding-block2 {
  height: 552px;
  background-size: cover !important;
}

.app-channel-btn {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  color: #ffffff !important;
  transition: color 0.3s ease;
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  position: relative;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 1px;
  padding: 0 !important;
  width: 179px;
  height: 36px;
}

.app-channel-signin {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  color: #ffffff !important;
  transition: color 0.3s ease;
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  position: relative;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  letter-spacing: 1px;
  width: 110px;
  height: 36px;
  padding: 0 !important;
}

.set-view-all-text {
  align-items: baseline !important;
  margin-bottom: 12px !important;
}

.badge-secondary {
  background: linear-gradient(180deg, #181818 0%, #000000 100%) !important;
  color: #fff;
  height: 23px;
  width: auto;
  border-radius: 5px !important;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  border: 1px solid #000000 !important;
  line-height: 13px !important;
}

.d-season1-series {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 100px;
  line-height: 40px;
  letter-spacing: 1px;
  color: #ffffff;
  text-align: center;
}

.block-description-featured-channel-video {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 10px;
  z-index: 999;
  text-align: left;
  color: #f2f2f2 !important;
}

.featured-channel-video {
  object-fit: cover !important;
  width: 100% !important;
  height: 555px !important;
}

.d-set-featured-video,
.d-set-featured-channel-photo {
  padding-top: 125px;
  text-align: center;
}

.d-image-1,
.d-image-2 {
  width: 70%;
  padding-bottom: 20px;
}

.d-parallax-window {
  padding: 0 !important;
}

.world-edu-event {
  height: 578px !important;
  width: 100% !important;
}

.world-edu-video {
  object-fit: cover !important;
  width: 100% !important;
  height: 578px !important;
}

.education-block-img {
  object-fit: cover !important;
  width: 100% !important;
  height: 415px !important;
  /* border-bottom: 1px solid #1891B4;
    border-top: 1px solid #1891B4; */
  background-size: cover;
}

#series-rating {
  display: block;
  width: 220px;
}

.dynamic-star-container {
  height: 40px;
}

.d-ratings-and-season {
  display: flex;
  padding-bottom: 10px;
  height: 50px;
}

.network-timeline {
  width: 829px;
  margin: 0 auto;
  margin-bottom: 62px !important;
}

.set-timeline {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.timeline-circle {
  height: 32px;
  width: 32px;
  border-radius: 25px;
  background: #fff;
  z-index: 100;
  color: #616161;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
}

.timeline-circle-line {
  width: 100%;
  height: 2px;
  position: absolute;
  color: #d9d9d9;
  border: none !important;
}

.timeline-text {
  position: absolute;
  top: 31px;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  color: #ffffff;
  margin: 0 !important;
}

/* .channel-registration {
    height: 662px;
    width: 1005px;
    margin: 0 auto;
    border-radius: 15px;
    background: #181818;
    border: 1px solid #1891B4;
    padding-left: 74px;
    padding-right: 64px;
} */

.channel-set-center {
  text-align: center;
}

.channel-logos {
  padding-top: 17px;
}

.channel-card-title {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 12px;
  color: #ffffff;
}

.channel-upgrade {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #989898;
  padding-top: 8px;
  margin-bottom: 38px !important;
}

.choose-domain {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin-bottom: 18px;
}

.set-domain {
  width: 508px;
  margin-bottom: 30px;
}

.channel-label {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.set-domain-name {
  height: 46px;
  width: 867px;
}

.set-domain-name1 {
  background: #252525 !important;
  border: none !important;
  border-bottom-left-radius: 5px !important;
  border-top-left-radius: 5px !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px !important;
  letter-spacing: 0em;
  height: 46px !important;
}

.set-domain-name1::placeholder {
  color: #797979 !important;
}

.set-domain-name2 {
  background: #252525 !important;
  border: none !important;
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px !important;
  letter-spacing: 0em;
  height: 46px !important;
  color: #797979 !important;
}

.set-domain-name2::placeholder {
  color: #797979 !important;
}

.set-channel-img {
  background-repeat: no-repeat !important;
  height: 100vh;
  position: relative;
  background: url(../images/desktop.png);
  background-size: cover !important;
}

.channel-basic {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin-bottom: 4px;
}

.basic-channel-field {
  height: 46px !important;
  width: 198px !important;
  border-radius: 5px !important;
  background: #252525 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff !important;
  border: none !important;
}

.basic-channel-field::placeholder {
  color: #797979 !important;
}

.basic-channel-field1 {
  height: 46px !important;
  width: 423px !important;
  border-radius: 5px !important;
  background: #252525 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #fff !important;
  border: none !important;
}

.basic-channel-field1::placeholder {
  color: #797979 !important;
}

.select-channel {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding-bottom: 30px;
}

.select-channel-type {
  height: 46px !important;
  width: 420px !important;
  border-radius: 5px !important;
  background: #252525 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px !important;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff !important;
}

.select-channel-subtype {
  height: 46px !important;
  width: 423px !important;
  border-radius: 5px !important;
  background: #252525 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px !important;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff !important;
}

.d-bgcolor-round {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
}

.d-text-color-round {
  color: #2dd0ff;
}

.footer-channel-text {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #616161;
  padding-top: 114px;
}

.footer-channel-text span {
  color: #2dd0ff;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.choose-plan {
  width: 278px;
}

.show-movie-details {
  padding-top: 90px;
}

.show-movie-img,
.show-movie-video {
  position: relative;
}

.movie-bgimg {
  width: 100%;
  height: 604px;
  border-top: 1px solid #2dd0ff;
  border-bottom: 1px solid #2dd0ff;
}

.view-movie-details {
  position: absolute;
  left: 90px;
  top: 130px;
}

.movie-name {
  font-family: OswaldBold;
  font-size: 55px;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.ratings-img {
  height: 16px;
  width: 16px;
  margin-right: 2px;
}

.show-star-content {
  display: flex;
  align-items: center;
}

.show-ratings {
  margin-right: 5px;
}

.show-rating-details {
  display: flex;
  align-items: center;
}

.show-rating-details p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #2dd0ff;
  padding-left: 3px;
  margin: 0 !important;
}

.show-rating-details h3 {
  font-family: "Poppins", sans-serif;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 32px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-left: 14px;
}

.show-rating-details h4 {
  font-family: "Poppins", sans-serif;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 32px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-left: 17px;
}

.show-tv-btn {
  height: 24px;
  min-width: 71px;
  border-radius: 0px !important;
  border: 1px solid #bdbdbd;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #bdbdbd;
  margin-left: 18px;
  padding: 0px 1px !important;
  background: none !important;
}

.movie-description p {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  width: 549px;
  padding-top: 9px;
}

.movies-reviews {
  padding-top: 13px;
  width: 624px;
}

.show-movie-reviews {
  display: flex;
}

.movie-starrings p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
  width: 415px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.movie-starring p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
}

.movie-starring-content p {
  width: 415px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #2dd0ff;
  margin: 0 !important;
}

.movie-starring-content {
  padding-left: 92px;
}

.movie-starrings.movie-starring1 {
  padding-left: 98px;
}

.movie-starrings.movie-starring2 {
  padding-left: 87px;
}

.movie-starrings.movie-starring3 {
  padding-left: 16px;
}

.show-like-details {
  display: flex;
  padding-top: 35px;
  align-items: center;
}

.show-like-img {
  height: 24px;
  width: 24px;
}

.show-like-details p {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding-left: 8px;
}

.show-episodes-btn {
  padding-top: 20px;
}

.show-episode {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  box-shadow: 0px 4px 10px 2px #00000080;
  height: 45px;
  width: 214px;
  border-radius: 30px !important;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding: 0 !important;
  box-shadow: 0px 4px 10px 2px #00000080;
}

.show-episode span {
  font-weight: 700;
}

.show-rent {
  height: 45px;
  width: 130px;
  border-radius: 30px !important;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0 !important;
  color: #ffffff;
  margin-left: 23px;
  box-shadow: 0px 4px 10px 2px #00000080;
}

.show-rent span {
  font-weight: 700;
}

.show-like-btn {
  height: 44px;
  width: 44px;
  border-radius: 10px !important;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  box-shadow: 0px 4px 10px 2px #00000080;
  padding: 0 !important;
  margin-left: 23px;
}

/* .show-like-img1 {
    height: 26px;
    width: 28px;
    margin-top: 8px;
} */

.show-watchlist-icon {
  height: 44px;
  width: 44px;
  border-radius: 10px !important;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  box-shadow: 0px 4px 10px 2px #00000080;
  padding: 0 !important;
  margin-left: 11px;
}

.show-dinner-btn {
  margin-left: 11px;
  padding: 0 !important;
  height: 45px;
  width: 163px;
  border-radius: 30px !important;
  background: linear-gradient(180deg,
      rgba(148, 255, 132, 0.35) 0%,
      rgba(71, 199, 69, 0.35) 100%);
  border: 2px solid #66ca6a;
  box-shadow: 0px 4px 10px 2px #00000080;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.show-terms p {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-top: 23px;
}

.show-terms span {
  color: #2dd0ff;
  font-size: 15px !important;
}

.show-movie-tabs {
  padding: 40px 90px;
}

.movie-tab-item {
  padding: 0 !important;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd !important;
  padding-right: 50px !important;
}

.movie-tab-item.active {
  color: #2dd0ff !important;
  background: none !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link .movie-tab-item {
  background: none !important;
}

.show-movie-pills {
  padding-bottom: 7px;
  border-bottom: 1px solid #616161;
  justify-content: space-between;
}

.show-tabs-icon {
  height: 39px;
  width: 43px;
  border-radius: 10px !important;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  box-shadow: 0px 4px 10px 2px #00000080;
  padding: 0 !important;
}

.show-tabs-img {
  width: 23px;
  height: 26px;
}

.show-nav-items {
  display: flex;
}

.show-home-tabs {
  padding-top: 40px;
}

.show-detailtab-img {
  height: 297px;
  width: 200px;
  border-radius: 10px;
  border: 2px solid #1891b4;
}

.show-tab-details {
  display: flex;
}

.detail-tab-content {
  padding-left: 41px;
  flex: 1;
}

.detail-actions {
  display: flex;
  align-items: center;
}

.detail-actions p {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.detail-action-btn {
  padding: 0px 1px !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #bdbdbd;
  height: 24px;
  min-width: 71px;
  border-radius: 0px !important;
  border: 1px solid #bdbdbd;
  background: none !important;
  margin-left: 10px;
}

.details-action-content p {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-top: 7px;
  padding-right: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.details-of-movie {
  display: flex;
  align-items: center;
}

.details-of-movie h4 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  width: 116px;
}

.details-of-movie p {
  width: 472px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #2dd0ff;
  padding-left: 51px;
}

.details-of-movie span {
  color: #bdbdbd;
}

.details-show-movie {
  padding-top: 15px;
}

.details-subtitle p {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: right;
  color: #bdbdbd;
}

.details-subtitle h4 {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: right;
  color: #2dd0ff;
}

.details-subtitle {
  padding-top: 40px;
}

.details-contents-button {
  display: flex;
  padding-top: 33px;
}

.crime-btn {
  padding: 0px 1px !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  background: none !important;
  border: 2px solid#1891B4;
  height: 26px;
  min-width: 90px;
  color: #ffffff;
}

.drama-btn {
  padding: 0px 1px !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  height: 26px;
  min-width: 90px;
  border-radius: 13px !important;
  background: none !important;
  border: 2px solid#1891B4;
  margin-left: 20px;
  color: #ffffff;
}

.comedy-btn {
  padding: 0px 1px !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  height: 26px;
  min-width: 111px;
  border-radius: 13px !important;
  background: none !important;
  border: 2px solid#1891B4;
  margin-left: 20px;
}

.top-cast {
  padding-bottom: 37px;
  border-bottom: 1px solid #616161;
  padding-top: 37px;
}

.top-cast p {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
  padding-bottom: 10px;
  border-bottom: 1px solid #616161;
}

.show-profile-img {
  height: 100px;
  width: 100px;
  border-radius: 200px;
  border: 2px solid#1891B4;
}

.show-img-boxes h4 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-top: 9px;
}

#show-img-boxes h4 {
  text-align: center;
}

.top-cast .show-img-boxes p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-bottom: 0;
  border-bottom: none;
  padding: 0 !important;
}

.show-cast-agency {
  text-align: center;
  padding-top: 29px;
}

.show-casting {
  height: 45px;
  min-width: 226px;
  padding: 0 1px !important;
  border-radius: 22px !important;
  background: linear-gradient(180deg,
      rgba(255, 228, 0, 0.5) 0%,
      rgba(254, 122, 0, 0.5) 100%);
  border: 2px solid #e29e19;
  box-shadow: 0px 4px 10px 2px #00000040;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.show-img-boxes {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: fit-content;
}

.m-set-prev {
  background-color: unset !important;
  left: -53px !important;
  top: 22% !important;
  height: auto !important;
}

.m-set-next {
  background-color: unset !important;
  right: -53px !important;
  top: 22% !important;
  height: auto !important;
}

.movie-app-img {
  height: 288px;
  width: 194px;
  border-radius: 10px;
}

.sm-set-prev {
  background-color: unset !important;
  left: -53px !important;
  top: 38% !important;
  height: auto !important;
}

.sm-set-next {
  background-color: unset !important;
  right: -53px !important;
  top: 38% !important;
  height: auto !important;
}

.movie-extra {
  padding-top: 40px;
  border-bottom: 1px solid #616161;
  display: flex;
  padding-bottom: 40px;
}

.show-extratab-img {
  height: 130px;
  width: 200px;
  border-radius: 20px;
  border: 2px solid #1891b4;
}

.show-movie-tab {
  padding-left: 41px;
  flex: 1 1;
}

.show-movie-tab h4 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.show-movie-tab p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-top: 3px;
  margin-bottom: 0px;
  width: 46%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.show-subtitle p {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: right;
  color: #bdbdbd;
}

.show-subtitle h4 {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: right;
  color: #2dd0ff;
}

.extra-photo-sec {
  display: flex;
  align-items: center;
}

.extra-photo {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-bottom: 10px;
  margin: 0 !important;
  border-bottom: 1px solid #616161;
  padding-top: 40px;
}

.show-count-data {
  padding-bottom: 18px;
}

.ratings-review {
  display: flex;
}

.rating-progressbar {
  display: flex;
  padding-top: 4px;
  align-items: center;
}

.rating-progressbar p {
  padding-right: 6px;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
  width: 55px;
}

.rating-progressbar span {
  width: 49px;
  padding-left: 16px;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: right;
  color: #2dd0ff;
}

.show-count-data p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
  padding-top: 7px;
}

.set-progress-bg {
  height: 10px !important;
  width: 220px !important;
  border-radius: 4px !important;
  background: linear-gradient(270deg, #2dd0ff 0%, #29b4dc 100%) !important;
}

.write-review-btn {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  height: 46px;
  width: 326px;
  border-radius: 23px !important;
  padding: 0 !important;
  margin-top: 32px;
}

.review-description {
  padding-left: 57px;
  width: 100%;
}

.filter-review {
  display: flex;
}

.filter-review p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  color: #bdbdbd;
  text-transform: initial;
  padding-right: 18px;
}

.filter-review h6 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  color: #bdbdbd;
  text-transform: initial;
}

.top-reviews {
  /* margin-left: 18px; */
  align-items: center;
  display: flex;
}

.top-comment {
  /* margin-left: 18px; */
  align-items: center;
  display: flex;
}

.sorted-top-review p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  color: #bdbdbd;
  padding-top: 5px;
}

.review-tab-description {
  background: #181818;
  border-radius: 20px;
  margin-top: 31px;
  padding: 23px;
}

.view-tab {
  display: flex;
  align-items: center;
}

.view-tab1 {
  display: flex;
  align-items: center;
}

.review-tab-img {
  height: 36px;
  width: 36px;
  border-radius: 50px;
  border: 1px solid#1891B4;
}

div#movie-detail-ratting {
  margin: 0 !important;
}

#movie-rating .dynamic-star-container {
  height: auto !important;
}

.photos-extra-img {
  height: 130px;
  width: 200px;
  border-radius: 20px;
  border: 2px solid #1891b4;
}

.p-set-prev {
  background-color: unset !important;
  left: -53px !important;
  top: 31% !important;
  height: auto !important;
}

.p-set-next {
  background-color: unset !important;
  right: -16% !important;
  top: 31% !important;
  height: auto !important;
}

.movies-content {
  padding-top: 40px;
}

.movies-content p {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-bottom: 10px;
  margin: 0 !important;
  border-bottom: 1px solid #616161;
}

.view-tab p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
  padding-left: 15px;
}

.view-tab span {
  color: #2dd0ff;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  padding-left: 14px;
}

.view-tab1 p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
  padding-left: 15px;
}

.view-tab1 span {
  color: #2dd0ff;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  padding-left: 14px;
}

.verified-ratings {
  display: flex;
  padding-top: 11px;
}

.verified-ratings p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding-left: 15px;
  margin: 0 !important;
}

.review-purchase h5 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #2dd0ff;
  padding-top: 14px;
}

.review-purchase p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  color: #bdbdbd;
  padding-top: 13px;
}

.review-purchase h6 {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #616161;
  padding-top: 8px;
}

.review-rating-calc {
  margin-top: 21px;
  display: flex;
  align-items: center;
}

.review-rating-calc p {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #bdbdbd;
  margin: 0 !important;
  padding-left: 18px;
}

.rating-percentage {
  text-align: right;
}

.review-report {
  display: flex;
  padding-top: 22px;
  align-items: center;
}

.review-report p {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #2dd0ff;
  margin: 0 !important;
  padding-left: 31px;
}

img.review-blue-img {
  height: 18px;
  width: 20px;
}

.verified-ratings>div>span {
  display: none;
}

.review-avg-rating {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin: 0 !important;
  padding-left: 20px;
}

.show-ratings>div>span {
  display: none;
}

#pills-tab a {
  cursor: pointer;
}

.remaining-photo p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: right;
  color: #bdbdbd;
  margin: 0 !important;
}

.remaining-photo {
  width: 10%;
}

.main-content.extra-tabs-set {
  width: 90%;
}

#movie-rating {
  height: 16px;
  width: 100px;
}

.show-time-details-mobile {
  display: none;
}

.show-minute-mobile,
.show-date-mobile {
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  color: #616161;
  padding-right: 5px;
}

.show-subtitle-mobile {
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  color: #2dd0ff;
}

#details-subtitle-mob {
  display: none;
}

.details-share-mob {
  display: none;
}

.details-share-mob p {
  padding-right: 7px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin: 0 !important;
}

.share-mob-view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 19px;
}

.show-share-img {
  width: 12px;
  height: 14px;
}

.details-contents-button-mob {
  display: none;
}

.show-mob-btn {
  display: none;
}

.show-comments {
  display: flex;
}

.filter-comment {
  display: flex;
}

.filter-comment h6 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  color: #bdbdbd;
  text-transform: initial;
}

.filter-comment p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  color: #bdbdbd;
  text-transform: initial;
  padding-right: 18px;
}

.sorted-top-comment p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  color: #bdbdbd;
  padding-top: 5px;
}

.comment-review-right {
  height: 1330px;
  width: 352px;
  border-radius: 20px;
  background: #181818;
  padding: 24px;
  padding-bottom: 3px !important;
}

.comment-people p {
  width: 181px;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #bdbdbd;
  margin: 0 auto;
}

.set-follow-info {
  display: flex;
  align-items: center;
  border-top: 1px solid #616161;
  padding-top: 10px;
  padding-bottom: 10px;
}

.set-follow-info h4 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-left: 14px;
}

.comments-img {
  height: 36px;
  width: 36px;
  border-radius: 50px;
  border: 1px solid #1891b4;
}

.set-follow-info p {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  color: #616161;
  margin: 0 !important;
  padding-left: 30px;
}

.comment-review-left {
  flex: 1;
  margin-right: 36px;
}

.comment-button-visit {
  height: 45px;
  min-width: 226px;
  border-radius: 22px !important;
  background: linear-gradient(180deg,
      rgba(255, 228, 0, 0.5) 0%,
      rgba(254, 122, 0, 0.5) 100%);
  border: 2px solid #e29e19;
  box-shadow: 0px 4px 10px 2px #00000040;
  padding: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.comments-show {
  display: flex;
  justify-content: space-between;
}

.comment-people {
  margin-bottom: 20px;
}

.create-comment p {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
}

.create-comment1 p {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
}

.create-comment1 {
  margin-top: 24px;
}

.create-comment {
  margin-top: 24px;
}

.set-comment-title {
  margin-top: 37px;
  background: linear-gradient(0deg, #181818, #181818) !important;
  border: 1px solid #1891b4 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #797979;
  border-radius: 10px !important;
  height: 103px !important;
  padding-top: 6px !important;
  padding-left: 14px !important;
  resize: none;
}

.set-comment-title::placeholder {
  color: #797979 !important;
}

.comment-vector-img {
  position: absolute;
  height: 20px;
  width: 20px;
  bottom: 18px;
  right: 9px;
}

.comment-title {
  position: relative;
}

.comment-title1 {
  position: relative;
}

.show-comment-sec {
  margin-top: 36px;
  padding: 23px;
  background: #181818;
  border-radius: 20px;
}

.review-report1 {
  display: flex;
  align-items: center;
  padding-top: 26px;
}

.review-report1 .MuiCheckbox-colorSecondary.Mui-checked {
  color: #616161 !important;
}

.review-report1 label.MuiFormControlLabel-root {
  margin: 0 !important;
}

.review-report1 .MuiCheckbox-root {
  color: #616161 !important;
  padding: 0 !important;
  height: 15px !important;
  width: 18px !important;
  font-size: 1.356rem !important;
}

.review-report1 .MuiSvgIcon-root {
  /* height: 15px !important;
    width: 18px !important; */
  font-size: 1.356rem !important;
}

.review-like-img {
  height: 15px;
  width: 18px;
  color: #616161;
}

.review-report1 input[type="checkbox"] {
  width: 18px;
  margin-right: 0px !important;
  height: 15px;
  display: inline-block;
}

.review-comment-img {
  height: 15.75px;
  width: 17px;
  color: #616161;
  margin-left: 24px;
}

.review-report1 h6 {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-left: 5px;
}

.review-report1 p {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #2dd0ff;
  margin: 0 !important;
  padding-left: 30px;
}

.review-share-img {
  margin-left: 24px;
  margin-bottom: 3px;
}

.comment-new-sec {
  padding-top: 29px;
}

#set-comment-border {
  padding-bottom: 23px;
  border-bottom: 1px solid #616161;
}

.comment-sec-new {
  padding-left: 53px;
  padding-top: 7px;
}

.comment-para {
  padding-left: 47px;
  margin-top: 17px;
}

.comment-para p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
  background-color: #272626;
  border-radius: 10px;
  padding-top: 11px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 14px;
  width: 800px;
}

.like-comment-icon {
  display: flex;
  align-items: center;
  padding-top: 13px;
}

#comment-para p {
  width: 325px;
}

.comment-reply-sec {
  display: flex;
  padding-top: 23px;
}

.set-reply-title {
  height: 47px !important;
  border-radius: 23px !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #797979;
  margin-left: 14px;
  border: 1px solid #1891b4;
  background: linear-gradient(0deg, #181818, #181818);
  padding-top: 9px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 9px;
  resize: none;
}

.set-reply-title::placeholder {
  color: #797979 !important;
}

.comment-scrolbar {
  height: 1204px;
  overflow-y: scroll;
}

.comment-scrolbar::-webkit-scrollbar {
  width: 15px;
  height: 25px;
}

.comment-scrolbar::-webkit-scrollbar-track {
  border: 1px solid #616161;
  background: linear-gradient(0deg, #000000, #000000);
}

.comment-scrolbar::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  border: 1px solid #2dd0ff;
  border-radius: 4.5px;
  height: 25px;
  width: 11px;
}

.create-comment1 {
  display: none;
}

.comment-title1 {
  display: none;
}

.review-purchase1 p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  color: #bdbdbd;
  padding-top: 13px;
}

.review-purchase1 h6 {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #616161;
  padding-top: 8px;
}

.like-comment-icon .MuiCheckbox-colorSecondary.Mui-checked {
  color: #616161 !important;
}

.like-comment-icon label.MuiFormControlLabel-root {
  margin: 0 !important;
}

.like-comment-icon .MuiCheckbox-root {
  color: #616161 !important;
  padding: 0 !important;
  height: 15px !important;
  width: 18px !important;
  font-size: 1.356rem !important;
}

.like-comment-icon .MuiSvgIcon-root {
  font-size: 1.356rem !important;
}

.like-comment-icon input[type="checkbox"] {
  width: 18px;
  margin-right: 0px !important;
  height: 15px;
  display: inline-block;
}

.review-report .MuiCheckbox-colorSecondary.Mui-checked {
  color: #2dd0ff !important;
}

.review-report label.MuiFormControlLabel-root {
  margin: 0 !important;
}

.review-report .MuiCheckbox-root {
  color: #2dd0ff !important;
  padding: 0 !important;
  height: 18px !important;
  width: 20px !important;
  font-size: 1.35rem !important;
}

.review-report .MuiSvgIcon-root {
  font-size: 1.35rem !important;
}

.review-report input[type="checkbox"] {
  width: 20px;
  margin-right: 0px !important;
  height: 18px;
  display: inline-block;
}

.show-like-btn .MuiCheckbox-colorSecondary.Mui-checked {
  color: #ffffff !important;
}

.show-like-btn label.MuiFormControlLabel-root {
  margin: 0 !important;
}

.show-like-btn .MuiCheckbox-root {
  color: #ffffff !important;
  padding: 0 !important;
  height: 18px !important;
  width: 20px !important;
  font-size: 1.35rem !important;
}

.show-like-btn .MuiSvgIcon-root {
  font-size: 1.35rem !important;
}

.show-like-btn input[type="checkbox"] {
  width: 20px;
  margin-right: 0px !important;
  height: 18px;
  display: inline-block;
}

img.topreview-vector {
  margin-left: 9px;
  height: 7px;
  width: 13px;
}

.review-description .btn {
  background: none !important;
  padding: 0 !important;
  border: none !important;
  border-radius: unset !important;
}

.review-description .dropdown-toggle::after {
  display: none !important;
}

.filter-comment .btn {
  background: none !important;
  padding: 0 !important;
  border: none !important;
  border-radius: unset !important;
}

.filter-comment .dropdown-toggle::after {
  display: none !important;
}

.rating-vector-img {
  height: 11.375px;
  width: 20.001039505004883px;
}

.set-accordion-ratings .MuiButtonBase-root.MuiAccordionSummary-root.review-rating-calc {
  flex-direction: row-reverse;
  padding: 0 !important;
  min-height: 0 !important;
  background-color: unset !important;
  color: none !important;
  align-items: center;
  margin-top: 21px;
}

.MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd {
  height: 11px !important;
  width: 20px !important;
  border-radius: 0px;
  color: #616161 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.our-ratings .MuiAccordionSummary-content {
  margin: 0 !important;
}

.MuiPaper-root.MuiAccordion-root.set-accordion-ratings.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  flex-direction: row-reverse;
  padding: 0 !important;
  min-height: 0 !important;
  background-color: unset !important;
  color: none !important;
}

.review-rating-calc1 p {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #bdbdbd;
  margin: 0 !important;
}

.MuiAccordionDetails-root.review-rating-calc1 {
  padding: 10px !important;
  background-color: #181818;
  margin-top: 7px;
}

.our-ratings {
  width: 294px !important;
}

.set-accordion-ratings .MuiSvgIcon-root {
  font-size: 2.5rem !important;
  padding: 0 !important;
}

.show-star-content h5 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding-right: 12px;
}

.app-series p {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 31.5px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
}

.movie-starring-content span {
  color: #bdbdbd;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

#view-series-details {
  top: 98px;
}

#series-season .btn {
  display: flex;
  background: none !important;
  padding: 0 !important;
  border: none !important;
  border-radius: unset !important;
  align-items: center;
}

/* #series-season .dropdown-toggle::after {
    display: none !important;
} */

.movie-tab-item span {
  font-weight: 400;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 30px;
}

.season-vector {
  height: 11px;
  width: 20px;
}

#pills-season-tab {
  padding-right: 15px !important;
}

#series-season {
  padding-right: 50px;
}

.series-tabs-icon {
  height: 39px;
  width: 43px;
  border-radius: 10px !important;
  background: linear-gradient(180deg,
      rgba(255, 227, 1, 0.5) 0%,
      rgba(255, 123, 1, 0.5) 100%);
  border: 2px solid #e29e19;
  box-shadow: 0px 4px 10px 2px #00000080;
  padding: 0 !important;
}

.show-season-description {
  display: none;
}

.mobile-season {
  border-bottom: 0;
  padding-bottom: 0;
}

.details-moive-btn {
  padding: 0px 10px !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  height: 26px;
  min-width: 111px;
  border-radius: 13px !important;
  background: none !important;
  border: 2px solid#1891B4;
  margin-right: 20px;
}

video.movie-bgvideo {
  width: 100%;
  height: 604px;
  border-top: 1px solid #2dd0ff;
  border-bottom: 1px solid #2dd0ff;
  object-fit: cover;
}

p.slider-text.d-slide-txt {
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin: 20px 0 0 !important;
  color: #ffffff !important;
  padding-bottom: 16px;
}

h1.slider-text.text-uppercase {
  font-family: "Inter-Regular";
  color: #ffffff !important;
  font-size: 48px;
  font-weight: 900;
  line-height: 40px;
  letter-spacing: 0.01em;
  text-align: center;
  margin: 0 !important;
}

/* #b-container .set-col-brand {
    padding: 0 !important;
} */

.main-content.set-main-brand {
  padding-top: 39px;
  padding-bottom: 36px;
}

img.count-series-img {
  width: 352px;
  height: auto;
  margin-bottom: 24px;
}

.exclusive-event-series {
  box-sizing: content-box;
  border-top: 1px solid #1891b4;
  width: 100% !important;
  border-bottom: 1px solid #1891b4;
}

.event-video-series {
  object-fit: cover !important;
  width: 100% !important;
  height: 438px !important;
  padding: 1px;
}

#d-series-network {
  margin-top: 72px;
  display: block !important;
}

.WEI-txt {
  text-transform: uppercase;
  padding-top: 24px;
  width: 404px !important;
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 auto !important;
  color: #ffffff !important;
  /* box-shadow: 0px 4px 4px 0px #00000040; */
}

.set-app-footer {
  width: 100%;
  display: flex;
  align-items: center !important;
  justify-content: center;
}

footer .footer-standard .widget ul li {
  /* display: inline-block; */
  padding-right: 0px !important;
}

#set-list-footer {
  text-align: center;
  /* margin-left: 113px; */
  margin-top: 35px;
}

.footer-list-text {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #16a34a !important;
}

footer.footer-one .widget .textwidget p {
  color: #cccccc;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 0;
  padding-top: 36px;
}

img.img-fluid.logo {
  max-width: 143px !important;
  width: 143px !important;
}

video.d-video-banner {
  height: 440px !important;
}

#footer-container {
  width: 83% !important;
  margin: 0 auto;
  padding: 0 !important;
}

.info-share-mob {
  display: none;
}

.musicartists-img {
  height: 268px;
  width: 186.3333282470703px;
  border-radius: 15px;
  border: 1px solid #1891b4;
  object-fit: cover;
}

.world-event-stock {
  margin: 0 auto;
  width: 1118px;
  display: flex;
  padding-top: 35px;
  padding-bottom: 35px;
  justify-content: center;
}

.world-stock-img {
  height: 196px;
  width: 352px;
  border-radius: 20px;
  box-shadow: 0px 0px 15px 0px #00000080;
}

.secure-connect {
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-align: center;
  margin: 0 !important;
  color: #ffffff !important;
  padding-top: 12px;
}

.set-section-stock {
  padding-right: 32px;
}

.set-section-stock1 {
  padding-right: 32px;
}

.modal-fullscreen {
  width: 95vw;
  max-width: none;
  height: 100%;
  margin: 20px auto;
}

.modal-header {
  border-bottom: none !important;
  padding-bottom: 0 !important;
  padding-top: 10px !important;
}

#series-bg-img .d-btn {
  margin-top: 16px !important;
}

.podcast-img {
  height: 156px;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  object-fit: cover;
  /* border: 1px solid #616161;
    border-bottom: 0; */
}

.maintenance-phase {
  width: 100%;
  height: 76px;
  /* border: 1px solid #616161;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; */
  padding: 16px 12px;
  border-top: 0;
}

p.set-maintenance {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin: 0 !important;
  height: 42px;
}

/* .world-event-stock-border {
    border-bottom: 1px solid #1891B4;
} */

.set-profile-info h6 {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

div#series-bg-img {
  /* border-bottom: 1px solid #1891B4;
    border-top: 1px solid #1891B4; */
  height: 440px;
  background-size: cover !important;
}

#set-privacy-policy {
  width: 1064px;
  border-radius: 15px;
  box-shadow: 0px 0px 25px 10px #00000080;
  margin: 0 auto;
}

.privacy-text-sec {
  height: 100px;
  background: linear-gradient(0deg, #000000 0%, #181818 100%);
  border-radius: 15px 15px 0px 0px;
  border: 1px solid #003087;
}

.privacy-text-sec p {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 95px;
  letter-spacing: 0em;
  text-align: center;
  box-shadow: 0px 1px 4px 0px #000000;
  margin: 0 !important;
  /* text-shadow: 0px 1px 4px #000000; */
  background: -webkit-linear-gradient(180deg, #ffffff 0%, #c5c5c5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  border-radius: 13px 13px 0px 0px;
}

/* 
.iq-privacy-policy1 {
  border-bottom: 1px solid #003087;
  border-left: 1px solid #003087;
  border-right: 1px solid #003087;
  background: #181818;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.contact-privacy p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin: 0 !important;
}

.contact-privacy {
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 32px;
  padding-bottom: 31px !important;
  border-bottom: 1px solid #616161;
}

.add-info {
  color: #003087;
}

.set-bottom-privacy {
  padding-top: 10px;
  padding-left: 19px;
  padding-right: 29px;
}

.privacy-text-set p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 28px !important;
  color: #ffffff !important;
}

.privacy-text-set span {
  font-weight: 700 !important;
}

.contacting-us {
  font-family: "BarlowSemiCondensed-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  color: #ffffff;
  margin-top: 30px !important;
}

.list-contact-set {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 28px !important;
  color: #ffffff !important;
  margin-left: -16px !important;
  list-style-type: disc;
} */

.d-btn-view-now {
  margin-left: 20px;
}

.show-podcast-details {
  padding-top: 90px;
}

.show-podcast-tabs {
  padding: 40px 90px;
}

.podcast-sec {
  display: flex;
  justify-content: space-between;
}

.podcast-sec-right1 {
  height: 603px;
  width: 603px;
  border-radius: 12px;
}

.podcast-sec-right {
  text-align: right;
  position: relative;
}

.podcast-sec-left {
  width: 50%;
}

.podcast-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 95px;
  line-height: 120px;
  color: #ffffff;
  width: 87%;
}

.podcast-para {
  font-family: "Poppins", sans-serif;
  margin: 0 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  width: 83%;
  padding-top: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.set-btns-now {
  display: flex;
  align-items: center;
  padding-top: 71px;
}

.show-Listen-btn {
  width: 321px;
  height: 59.09px;
  background: linear-gradient(180deg, #0065d7 0%, #002679 100%);
  border-radius: 4px !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24.6923px;
  line-height: 37px;
  letter-spacing: 0.08em;
  color: #ffffff;
  padding: 0 !important;
  border: none !important;
  z-index: 9;
}

.show-Subscribe-btn {
  font-family: "Poppins", sans-serif;
  border: 2px solid #002679;
  width: 321px;
  height: 59.09px;
  border-radius: 4px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24.6923px;
  line-height: 37px;
  letter-spacing: 0.08em;
  color: #ffffff;
  background: transparent;
  padding: 0 !important;
  margin-left: 30px;
  z-index: 9;
}

.podcast-footer {
  background: linear-gradient(180deg, #141313 0%, #0f0e0e 53.87%, #141313 100%);
  width: 100%;
  position: fixed;
  margin-top: 73px;
  padding: 28px 90px;
  bottom: 0;
  z-index: 999;
}

.podcast-footer-list {
  display: flex;
  align-items: center;
}

.podcast-footer-list1 img {
  width: 100px;
  border-radius: 5px;
}

.podcast-center {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  border-bottom: 1px solid #616161;
  padding-bottom: 40px;
}

.podcast-center-left1 {
  width: 231px;
  height: 153px;
  border-radius: 7px;
  max-width: unset !important;
}

.podcast-timingsec {
  display: flex;
  align-items: center;
}

.podcast-calender-sec {
  display: flex;
  align-items: center;
}

/* .podcast-calender-sec img {
    height: 23.814661026000977px;
    width: 23.814661026000977px;
    max-width: unset !important;
} */

.podcast-calender-sec p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 0px;
  padding-left: 13px;
}

.podcast-episodes {
  padding-top: 30px;
}

.podcast-timing-sec {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

/* .podcast-timing-sec img {
    height: 17.79323959350586px;
    width: 23.814661026000977px;
    max-width: unset !important;
} */

.podcast-timing-sec p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 0px;
  padding-left: 13px;
}

.podcast-episode-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 32px;
  color: #ffffff;
}

.podcast-episode-para {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  color: #bdbdbd;
  margin-bottom: 0px;
  padding-top: 5px;
  width: 97%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.podcast-center-right {
  margin-left: 51px;
  flex: 1;
}

p.podcast-episode-para.podcast-episode-para1 {
  display: none;
}

.podcast-footer-list2 p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  margin-bottom: 0 !important;
}

.podcast-footer-list2 {
  padding-left: 22px;
  width: 100%;
}

.podcast-sec {
  margin-bottom: 40px;
}

.set-play-img {
  width: 104px !important;
}

button.btn.set-play-img1 {
  position: absolute;
  bottom: 20px;
  left: 30px;
  background-color: unset !important;
  padding: 0 !important;
}

img.podcast-center-left2 {
  width: 40px;
  z-index: 9;
}

button.btn.podcast-center-left2 {
  position: absolute;
  left: 10px;
  bottom: 10px;
  background-color: unset !important;
  padding: 0 !important;
}

.podcast-center-left {
  position: relative;
}

/* ------------------------Audio Player----------------------------------------------- */

.podcast-footer-list2 .rhap_container {
  background-color: unset !important;
  padding: 0;
}

.podcast-footer-list2 .rhap_additional-controls {
  display: none !important;
}

.podcast-footer-list2 .rhap_progress-filled {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%) !important;
}

.podcast-footer-list2 .rhap_volume-filled {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%) !important;
}

.podcast-footer-list2 .rhap_progress-indicator {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%) !important;
  width: 14px;
  height: 14px;
  top: -5px;
  margin-left: -1px;
}

.podcast-footer-list2 .rhap_time {
  color: #ffffff !important;
  font-size: 15px !important;
}

.podcast-footer-list2 .rhap_progress-bar {
  height: 6px !important;
  background-color: #ffffff !important;
  border-radius: 6px !important;
}

.podcast-footer-list2 .rhap_progress-container {
  margin: 0;
  height: 13px;
}

.podcast-footer-list2 .rhap_progress-section {
  position: relative;
  display: block;
  padding-top: 10px;
}

.podcast-footer-list2 div#rhap_current-time {
  position: absolute;
  top: 26px;
}

.podcast-footer-list2 .rhap_time.rhap_total-time {
  padding-top: 3px;
  text-align: right;
}

.podcast-footer-list2 .rhap_main {
  flex-direction: row;
}

.podcast-footer-list2 .rhap_stacked .rhap_controls-section {
  margin-top: 0px;
  /* margin-left: 60px; */
  /* flex: 1 !important; */
}

/* .podcast-footer-list2  .rhap_volume-bar {
    background: linear-gradient(180deg, #0065D7 0%, #002679 100%) !important;
} */

.podcast-footer-list2 .rhap_main-controls {
  flex: 1 1 auto;
}

.podcast-footer-list2 .rhap_volume-indicator {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%) !important;
}

.podcast-footer-list2 .rhap_volume-button {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
  color: #ffffff !important;
  margin-right: 3px !important;
}

.podcast-footer-list1 p {
  display: none;
}

.podcast-footer-list2 .rhap_play-pause-button {
  font-size: 38px;
  width: 38px;
  height: 38px;
}

.podcast-footer-list2 .rhap_main-controls-button {
  /* font-size: 30px;
    width: 30px;
    height: 30px; */
  color: #ffffff;
}

.podcast-footer-list2 button.rhap_button-clear.rhap_main-controls-button.rhap_rewind-button {
  height: 42px;
  color: #ffffff00 !important;
  width: 42px;
  font-size: 42px;
  margin-bottom: 6px;
  margin-right: 30px;
}

.podcast-footer-list2 button.rhap_button-clear.rhap_main-controls-button.rhap_forward-button {
  height: 42px;
  color: #ffffff00 !important;
  width: 42px;
  font-size: 42px;
  margin-left: 20px;
  margin-bottom: 6px;
}

.podcast-footer-list2 button.rhap_button-clear.rhap_main-controls-button.rhap_rewind-button svg {
  background-image: url("../images/Backward.svg");
}

.podcast-footer-list2 button.rhap_button-clear.rhap_main-controls-button.rhap_forward-button {
  background-image: url("../images/Foreward.svg");
}

#donate-now {
  margin-left: 10px;
  padding: 0 !important;
  line-height: 46px;
}

/* 
div#movie {
    height: 88vh !important;
} */

.modal-body {
  padding-top: 7px !important;
  /* height: 90vh !important; */
}

div#movie {
  height: 100% !important;
  min-height: 100% important;
  max-height: 87vh !important;
}

div#series {
  height: 100% !important;
  min-height: 100% important;
  max-height: 87vh !important;
}

.jwplayer.jw-stretch-uniform .jw-media video {
  object-fit: cover !important;
}

#header-container {
  padding: 0 96px;
}

#d-header {
  height: 88px !important;
  margin: 0 !important;
  align-items: center;
}

img.d-video-banner {
  height: 440px !important;
}

.set-gradient-border {
  background: #003087cc;
  margin: 0 !important;
  border: 0 !important;
  /* height: 1px !important; */
  height: 0px !important;
  padding-top: 1px !important;
}

.set-gradient-border1 {
  background: #003087cc;
  margin: 0 !important;
  border: 0 !important;
  height: 0px !important;
  padding-top: 1px !important;
}

#set-gradient-border {
  background: #003087cc;
  margin: 0 !important;
  border: 0 !important;
  /* height: 1px !important;
    padding-bottom: 1px !important; */
  height: 0px !important;
  padding-top: 1px !important;
}

.set-watchlistadd {
  background-color: #50c7076b !important;
  border: 2px solid #50c707 !important;
}

.wraper {
  flex-grow: 1;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#set-seconds-hours {
  width: 63px;
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #e6e6e6 !important;
}

#set-watchlist-row .col-1-5 {
  flex: 0 0 20% !important;
  max-width: 20% !important;
}

.watchlist-container {
  padding: 40px 90px;
  margin-top: 70px;
}

.watchlist-text p {
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 32px !important;
  letter-spacing: 1px !important;
  text-align: left !important;
  font-family: "Inter-Regular";
  margin: 0 !important;
}

.watchlist-container nav.nav.nav-tabs {
  border: 2px solid #2dd0ff;
  border-top: 2px solid #2dd0ff;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  margin-top: 31px;
  height: 51px;
  padding-left: 30px;
  border-radius: 10px;
}

.watchlist-container .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none !important;
  color: #ffffff !important;
  background-color: transparent;
  /* border-bottom: 2px solid white !important; */
  padding-bottom: 1px;
  border-radius: 1px;
  margin-bottom: 8px;
}

.watchlist-container .nav-link {
  padding: 0 !important;
}

.watchlist-container .nav-link {
  padding-top: 8px !important;
  border: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  font-family: "Poppins", sans-serif;
  color: #f9f3f37d;
}

.watchlist-container .tab-content {
  margin-top: 88px;
}

a#watchlist-tabs-tab-second {
  margin-left: 68px;
}

#watchlist-episodename {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 17px !important;
}

#watchlist-date {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

#watchlist-season {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: #ffffff;
}

#watchlist-episode {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #dcc07b;
}

#episode-watchlist {
  margin-top: 9px;
}

#set-watchlist-column {
  padding: 0 20px;
  max-width: 348px !important;
}

.watchlist-container .epi-desc.p-3 {
  padding: 8px 16px !important;
  width: 100%;
}

.footer-podscast {
  height: 400px;
}

/* #series-remove {
    margin-left: 5px;
} */

#series-remove {
  height: 40px;
  /* margin-left: 10px; */
  width: 40px;
  cursor: pointer;
}

.episode-play-info {
  display: flex;
  flex-direction: row;
}

#series-play {
  height: 40px;
  width: 40px;
}

.episode-play-info:hover>* {
  display: block;
  /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.9);
    background: rgba(0, 0, 0, 0.9); */
}

.episode-play-info:hover {
  background: rgba(0, 0, 0, 0.9);
}

.episode-play-info>* {
  display: none;
}

a#changepwd-save {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5),
      rgba(24, 145, 180, 0.5));
  border: 2px solid #2dd0ff;
  box-shadow: 0 0 1px transparent;
  color: #fff !important;
  display: inline-block;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 36px;
  letter-spacing: 1px;
  line-height: 31px;
  padding: 0 !important;
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  transition: color 0.3s ease;
  vertical-align: middle;
  width: 120px;
}

#viewall-images {
  aspect-ratio: 35/52;
  width: 100%;
  border: 1px solid #003087;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  border-radius: 15px;
}

#portal-viewall {
  width: 90%;
}

#watchlist-img-set {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  aspect-ratio: 9/9;
  width: 100%;
  object-fit: cover;
}

#payment-back {
  background: #0ffa6173;
  border: 2px solid #003087;
  height: 40px;
  width: 190px;
  border-radius: 18px !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px !important;
  letter-spacing: 0em !important;
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px !important;
  text-transform: unset;
}

.payment-img {
  margin-top: 30px;
  width: 80px;
}

#payment-msg {
  margin-top: 25px;
}

#ongoing-color {
  color: #0082f9;
}

.show-episode-text {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  box-shadow: 0px 4px 10px 2px #00000080;
  height: 45px;
  width: 140px;
  border-radius: 30px !important;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding: 0 !important;
  box-shadow: 0px 4px 10px 2px #00000080;
}

.series-movie-text {
  padding: 15px;
}

#d-space-show .swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled .d-icon {
  color: #979797 !important;
}

#d-space-show .swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled .d-icon {
  color: #979797 !important;
}

a#create-channel {
  display: flex;
  justify-content: center;
  align-items: center;
}

div#viewall-row {
  margin-top: 30px;
  padding: 0 !important;
}

#view-all-movie {
  flex: 0 0 14.285%;
  max-width: 14.285%;
}

#view-all-movie1 {
  flex: 0 0 14.285%;
  max-width: 14.285%;
}

#img-box-podcast {
  border-radius: 15px;
  border: 1px solid #616161;
}

#view-all-podcast {
  flex: 0 0 10%;
  max-width: 10%;
}

.episode-play {
  margin-right: 10px;
}

.iq-privacy-policy2 {
  border-bottom: 2px solid #616161;
  border-left: 2px solid #616161;
  border-right: 2px solid #616161;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 30px;
}

#info-logopages {
  width: 222px;
  height: 80px;
}

.set-input-width {
  padding-top: 24px !important;
}

.back-login {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #16a34a;
  font-family: "Inter-Regular" !important;
}

svg.sign-vector-img1 {
  margin-bottom: 6px;
  margin-left: 7px;
}

.set-reset-height {
  height: 474px !important;
}

.set-reset-height #set-input-width {
  padding-top: 15px;
}

.set-reset-height #sign-inbtn {
  margin-top: 23px;
}

#d-header .rightside-icon {
  text-align: right;
}

#d-header .center-logo {
  text-align: center;
  display: flex;
  justify-content: center;
  flex: 1 1;
}

/* #d-header .left-logo {
    justify-content: left !important;
} */

div#right-sidetoggle {
  display: flex;
  flex-direction: row-reverse;
  flex: 1 1;
}

#d-header-col {
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 0 !important;
  justify-content: space-between;
}

.header-profile {
  display: flex;
  flex: 1 1;
  align-items: center;
}

.header-profile-drawer {
  display: flex;
  flex: 1 1;
  align-items: center;
}

.header-profile p {
  margin: 0 !important;
  padding-left: 12px;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #ffffff;
  width: 184px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.header-profile-drawer p {
  margin: 0 !important;
  padding-left: 12px;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #ffffff;
  width: 184px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.iq-sub-dropdown.dropdown-menu.dropdown-menu-right.dropdown-menu.show {
  width: 256px !important;
  background: linear-gradient(180deg,
      #181818 10.62%,
      #000000 94.74%) !important;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.75) !important;
  height: auto !important;
  border-radius: 20px !important;
  border: none !important;
  transform: translate3d(37px, 54px, 0px) !important;
}

svg.black-polygon {
  position: absolute;
  top: -19px;
  right: 31px;
}

#polygon-set {
  position: relative;
}

#header-drawer .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
  width: 256px !important;
  background: #181818 !important;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
}

.drawer-content {
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

#header-drawer .drawer-profile {
  width: 32px;
  height: 32px;
}

#header-drawer .header-profile p {
  padding-left: 8px;
  margin: 0 !important;
  font-family: "Roboto-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  width: 184px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #bdbdbd;
  text-align: left;
}

#header-drawer .header-profile-drawer p {
  padding-left: 8px;
  margin: 0 !important;
  font-family: "Roboto-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  width: 184px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #bdbdbd;
  text-align: left;
}

.profilename-text {
  display: none;
}

#header-drawer .header-category {
  display: flex;
  align-items: center;
  margin-top: 28px;
  height: 23px;
}

#header-drawer .iq-sub-card.setting-dropdown {
  padding: 0 !important;
}

#header-drawer .set-profile-info h6 {
  padding-left: 8px;
  font-family: "Roboto-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: #bdbdbd;
}

#header-drawer .right-icon {
  width: 32px;
  text-align: center;
}

#header-drawer .drawer-icon {
  font-size: 22px !important;
  color: #bdbdbd !important;
}

#header-drawer .drawer-icon-active {
  font-size: 22px !important;
  color: #2dd0ff !important;
}

#header-drawer .set-profile-info-active h6 {
  color: #2dd0ff !important;
  padding-left: 8px;
  font-family: "Roboto-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
}

#right-sidetoggle .iq-card-body.iq-card-body {
  padding: 40px 32px 24px 32px;
}

#right-sidetoggle .app-channel-create {
  width: 192px;
  height: 36px;
  background: #0ffa6173;
  border: 2px solid #003087;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  text-transform: capitalize;
  color: #c2ffd7 !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 16px;
  padding: 0 !important;
}

#right-sidetoggle .app-channel-signin {
  width: 192px;
  height: 36px;
  background: rgba(97, 97, 97, 0.5);
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 100px !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 34px;
  color: #ffffff !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none !important;
}

#right-sidetoggle .toggle-text {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 24px;
}

#right-sidetoggle .toggle-text p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #ffffff;
  padding: 0 22px;
  margin: 0 !important;
}

#right-sidetoggle .toggle-text-line {
  width: 64px;
  height: 1px;
  background: #616161;
  border: none !important;
  margin: 0 !important;
}

#create-channels {
  height: auto !important;
  width: 986px;
  background: linear-gradient(0deg, #000000 0%, #181818 100%);
  box-shadow: 0px -8px 50px rgba(45, 208, 255, 0.3);
  border-radius: 35px;
  padding: 40px 84px !important;
  min-height: 626px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

#channel-logos {
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: center;
}

#create-channels .app-logo {
  width: 122px;
  height: 48px;
}

#create-channels .set-rounds {
  display: flex;
  align-items: center;
}

#create-channels .set-rounds-no {
  border: 2px solid #616161;
  background: transparent !important;
  font-family: "Roboto-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23.44px;
  color: #616161;
  height: 28px;
  width: 28px;
  border-radius: 100%;
}

#create-channels .set-rounds p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #616161;
  margin: 0 !important;
  padding-left: 8px;
}

#create-channels .set-rounds-active {
  display: flex;
  align-items: center;
}

#create-channels .set-rounds-no-active {
  border: 2px solid #2dd0ff;
  background: transparent !important;
  font-family: "Roboto-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23.44px;
  color: #2dd0ff;
  height: 28px;
  width: 28px;
  border-radius: 100%;
}

#create-channels .set-rounds-active p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #2dd0ff;
  margin: 0 !important;
  padding-left: 8px;
}

#create-channels .close-vector {
  width: 14px;
  height: 14px;
  margin-top: 5px;
}

.channel-registration {
  background: #181818;
  border: 1px solid #1891b4;
  border-radius: 15px;
  margin: 0 auto;
  padding-left: 74px;
  padding-right: 64px;
  width: 1005px;
}

.set-channel-img {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: auto;
  padding-top: 206px !important;
}

#m-profile {
  padding-top: 0px !important;
}

#new-channel-row {
  padding: 0 !important;
}

#create-channels .new-channel-text {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin: 0 !important;
  text-align: left;
  padding-left: 12px;
}

#create-channels .new-channel-text span {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

#newchannel-fieldset {
  padding-top: 24px;
}

#create-channels .new-channel-form-label {
  font-family: "Roboto-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding-bottom: 8px;
  padding-left: 12px;
  margin: 0 !important;
}

#create-channels .new-channel-form-label span {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #bdbdbd;
  padding-left: 8px;
}

#create-channels .new-channel-field {
  background: #252525 !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  border: none !important;
  height: 40px !important;
}

#create-channels .new-channel-field::placeholder {
  color: #939393 !important;
}

#create-channels .new-channel-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#create-channels .new-channel-select {
  background: #252525 !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  border: none !important;
  height: 40px !important;
  width: 400px !important;
  flex: unset !important;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../images/category.svg") !important;
  /* background-image: url("../images/arrow.png") !important; */
  background-repeat: no-repeat !important;
  background-position: calc(100% - 20px) 17px !important;
  background-size: 15px !important;
}

#create-channels .custom-control-inline {
  padding: 0 12px;
  width: 316px !important;
  height: 40px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#create-channels .new-channel-radio {
  right: 0;
  width: 16px;
  height: 16px;
  top: 1px;
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  border: 2px solid #2dd0ff;
  border-radius: 5px;
  margin: 0 !important;
}

.header-profile {
  width: 33%;
}

.center-logo {
  width: 33%;
}

#create-channels #input-group-dropdown-1 {
  background: #252525 !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  border: none !important;
  height: 40px !important;
  width: 400px !important;
  flex: unset !important;
}

#create-channels .custom-control-inline1 {
  width: 484px !important;
  height: 40px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
}

#create-channels .channel-label1 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

#create-channels .custom-control-label::after {
  position: unset !important;
  top: 0.25rem;
  left: 16.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

#create-channels .custom-control-label::before {
  position: absolute;
  right: 0;
  left: 0 !important;
  display: flex;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}

#create-channels .channel-label1 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

#create-channels .set-domain-name1 {
  background: #252525 !important;
  border-radius: 20px 0px 0px 20px !important;
  height: 40px !important;
  width: 538px;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  flex: unset !important;
}

#create-channels .set-domain-name2 {
  background: #252525 !important;
  border-radius: 0px 20px 20px 0px !important;
  height: 40px !important;
  width: 112px;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  flex: unset !important;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../images/category.svg") !important;
  /* background-image: url("../images/arrow.png") !important; */
  background-repeat: no-repeat !important;
  background-position: calc(100% - 20px) 17px !important;
  background-size: 15px !important;
}

#create-channels .input-group-prepend {
  margin-right: -1px;
  margin-left: 2px;
}

#create-channels .form-check {
  padding-left: 0 !important;
}

#create-channels .set-domain-name1::placeholder {
  color: #939393;
}

#new-channel-row {
  padding-top: 99px !important;
  margin: 0 !important;
}

#create-channels .new-channel-check {
  width: 148px;
  height: 36px;
  background: linear-gradient(180deg, #101010 0%, #000000 100%);
  border: 1px solid #616161;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
  border-radius: 18px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 0 !important;
}

#create-channels .new-channel-check.active {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 1px solid #2dd0ff;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
}

#create-channels .new-channel-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#create-channels .new-channel-back {
  padding-top: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#create-channels .new-channel-back1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#create-channels .new-back-btn {
  background: rgba(97, 97, 97, 0.5);
  border-radius: 18px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  width: 120px;
  height: 32px;
  margin-right: 14px;
  padding: 0 !important;
}

#create-channels .new-channel-next {
  width: 120px;
  height: 32px;
  background: linear-gradient(180deg, #101010 0%, #000000 100%);
  border: 1px solid #616161;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
  border-radius: 18px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0 !important;
}

#create-channels .new-channel-next.active {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 1px solid #2dd0ff;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
}

#create-channels .footer-channel-text {
  padding: 0 !important;
}

#create-channels .footer-channel-text span {
  color: #2dd0ff;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 3px;
}

#create-channels .footer-channel-text p {
  margin: 0 !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #bdbdbd;
}

.error-code {
  font-family: "Inter-Regular" !important;
}

.error-text {
  font-family: "Inter-Regular" !important;
}

div#set-header-line {
  border-top: 1px solid #616161;
  margin-bottom: 50px;
}

#iq-main-slider {
  padding-top: 88px !important;
}

.header-profile img {
  cursor: pointer;
}

div#BannerSeriesTrailer {
  max-height: 90vh;
}

.modal-body {
  max-height: 92vh;
}

.video-mobile-back {
  display: flex;
  align-items: center;
}

.video-mobile-back .video-series-title {
  color: #ffffff;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  margin: 0 !important;
}

.video-movies {
  padding-left: 10px;
}

.video-mobile-back .video-series-episode {
  color: #ffffff;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin: 0 !important;
}

.new-channel-myexample {
  padding-top: 16px;
  padding-bottom: 22px;
}

#create-channels .new-channel-availability {
  margin: 0 !important;
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #ffffff;
  padding-bottom: 8px;
  padding-left: 12px;
}

#create-channels .new-channel-availability span {
  color: #2dd0ff;
}

#create-channels .newchannel-domain-example {
  height: 48px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 12px;
}

#create-channels .newchannel-mydomain {
  margin: 0 !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  width: 50%;
  text-align: left;
}

#create-channels .newchannel-close {
  display: flex;
  align-items: center;
}

#create-channels .newchannel-close p {
  margin: 0 !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  padding-left: 9px;
}

#create-channels .domain-suggestions {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 820px;
  height: 48px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px 10px 0px 0px;
  padding: 0 12px;
  margin-bottom: 2px;
}

#create-channels #domain-suggestion {
  margin-top: 40px !important;
}

#create-channels #domain-suggestions1 {
  margin-top: 2px;
  border-radius: 0px;
}

#create-channels #domain-suggestions2 {
  margin-top: 2px;
  border-radius: 0px 0px 10px 10px;
}

#create-channels .newchannel-available {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#create-channels .newchannel-available p {
  margin: 0 !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  padding-left: 9px;
}

#create-channels .new-channel-radio1 {
  right: 0;
  width: 16px;
  height: 16px;
  top: 4px;
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  border: 2px solid #2dd0ff;
  border-radius: 5px;
  margin: 0 !important;
}

#create-channels .channel-notavailable {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

/* #create-channels [type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #000000 !important;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
} */

.new-channel-dataplan {
  display: flex;
  align-items: center;
}

.new-create-channel {
  margin: 50px 0;
}

.channel-plan {
  padding: 24px 16px 20px 0;
  width: 258px;
  height: 386px;
  border-radius: 15px;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1));
}

.dataplan-standard {
  background: linear-gradient(165.99deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 90.01%);
  border-radius: 15px;
  padding: 1px;
  margin-right: 20px;
}

.dataplan-premium {
  background: linear-gradient(165.99deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 90.01%);
  border-radius: 15px;
  padding: 1px;
  margin-right: 20px;
}

.dataplan-standard-text {
  width: 167px;
  height: 40px;
}

.dataplan-standard-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dataplan-enterprise {
  background: linear-gradient(165.99deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 90.01%);
  border-radius: 15px;
  padding: 1px;
}

.dataplan-standard-price-tag p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin: 0 !important;
}

.dataplan-standard-price-tag span {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}

.dataplan-standard-timings {
  padding-left: 16px;
  padding-top: 20px;
}

.dataplan-standard-timing {
  display: flex;
  align-items: center;
  padding-bottom: 9px;
}

.dataplan-standard-mins {
  padding-left: 8px;
  text-align: left;
}

.dataplan-standard-mins p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-align: left;
  margin: 0 !important;
}

.dataplan-standard-mins span {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
  text-align: left;
}

.dataplan-select {
  width: 148px;
  height: 32px;
  border: 1px solid #ffffff;
  border-radius: 16px !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  background: transparent;
  padding: 0 !important;
  margin-top: 5px;
  text-transform: unset;
  color: #ffffff !important;
}

.dataplan-select:hover {
  background-color: unset !important;
  text-transform: unset;
  border-color: unset;
}

.dataplan-standard .dataplan-select.active {
  background: linear-gradient(180deg, #126088 0%, #06344b 100%) !important;
  border: unset !important;
  text-transform: unset !important;
}

.dataplan-premium .dataplan-select.active {
  background: linear-gradient(180deg, #1d9bd1 0%, #04628a 100%) !important;
  border: unset !important;
  text-transform: unset !important;
}

.dataplan-enterprise .dataplan-select.active {
  background: linear-gradient(180deg, #63cdfa 0%, #1891b4 100%) !important;
  border: unset !important;
  text-transform: unset !important;
}

.dataplan-standard-price-tag .dollar {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin: 0 !important;
}

.payments-option {
  display: flex;
}

.payment-monthly {
  padding-top: 16px;
}

.payment-monthly h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  padding-bottom: 12px;
  text-align: left;
}

.payment-method {
  width: 484px;
  height: 296px;
  border: 1px solid #616161;
  border-radius: 10px;
  padding: 16px 0;
}

.payment-method h5 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding-bottom: 16px;
  text-align: left;
  padding-left: 16px;
}

.new-card-add {
  padding: 16px 13px;
  width: 132px;
  height: 84px;
  /* mix-blend-mode: overlay; */
  opacity: 0.2;
  border-radius: 9.64911px;
  border: 1px solid #616161;
  margin-left: 16px;
  margin-bottom: 15.5px;
}

.new-card-add p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin: 0 !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-top: 12px;
}

.payments-monthly {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #616161;
  padding: 16px 22px;
}

.billing-address {
  display: flex;
  align-items: center;
}

.billing-address-selected p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin: 0 !important;
  text-align: left;
}

.billing-address-selected {
  padding-left: 22px;
}

.billing-address-selected span {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #bdbdbd;
  text-align: left;
}

.series-listname {
  font-family: "Inter-Regular";
  color: #ffffff !important;
  font-size: 48px;
  font-weight: 900;
  line-height: 40px;
  letter-spacing: 0.01em;
  text-align: center;
  margin-bottom: 10px !important;
  text-transform: uppercase;
}

.payments-order {
  padding: 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin-left: 20px;
  width: 316px;
  height: 536px;
}

.payments-order-total {
  display: flex;
  justify-content: space-between;
  padding-bottom: 11.1px;
  border-bottom: 1px solid #616161;
  margin-bottom: 7px;
}

.payments-order-total p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0 !important;
}

.payments-order-total h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.payments-dataplan {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
}

.payments-dataplan p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #bdbdbd;
  margin: 0 !important;
}

.payments-dataplan h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #bdbdbd;
}

.setup-monthly {
  padding-top: 24px;
}

.setup-monthly-pay {
  width: 284px;
  height: 48px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px 10px 0px 0px;
  padding: 4px 12px;
}

.setup-monthly-autopay h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  text-align: left;
}

.setup-monthly-autopay p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #bdbdbd;
  text-align: left;
  margin: 0 !important;
}

.setup-monthly-pay {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-channel-radio-pay {
  right: 0;
  width: 16px;
  height: 16px;
  bottom: -8px;
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  border: 2px solid #2dd0ff;
  border-radius: 5px;
  margin: 0 !important;
}

.setup-monthly-amount {
  width: 284px;
  height: 48px;
  background: rgba(255, 255, 255, 0.1);
}

.setup-monthly-price {
  width: 284px;
  height: 48px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  margin: 4px 0;
}

.setup-monthly-payment {
  width: 284px;
  height: 48px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  border-radius: 0px 0px 10px 10px;
}

.place-order-btn {
  margin-top: 24px;
  margin-bottom: 16px;
  width: 284px;
  height: 40px;
  background: linear-gradient(180deg, #101010 0%, #000000 100%);
  border: 1px solid #616161;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
  border-radius: 18px;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.discount-code {
  width: 284px !important;
  height: 36px !important;
  background: #252525 !important;
  border: 1px solid #616161 !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff !important;
  margin-top: 24px;
}

.discount-code::placeholder {
  color: #939393 !important;
}

.discout-form-group {
  margin: 0 !important;
}

.terms-services {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin: 0 auto !important;
  text-align: center;
  color: #bdbdbd;
}

.terms-services span {
  color: #2dd0ff;
  font-size: 12px;
}

.set-col-brand {
  padding: 0 !important;
}

#influencer-list {
  padding: 0 !important;
}

#error-page {
  margin-top: 50px;
  margin-bottom: 50px;
}

/* footer#contact {
    border-top: 1px solid #003087cc;
}

.main-header {
    border-bottom: 1px solid #003087cc;
} */

.buy-ticket {
  padding-top: 88px;
}

.buy-ticket-payment {
  width: 400px;
  padding: 10px;
}

.ticket-add {
  box-shadow: 0px -8px 50px rgb(73 192 69 / 33%);
  border: 0.5px solid #003087;
  width: 1200px;
  margin: 60px auto;
  display: flex;
  /* align-items: center; */
  background: linear-gradient(0deg, #000000 0%, #181818 100%);
  border-radius: 18px;
}

.buy-ticket-card {
  padding: 25px;
}

.buy-ticket-title {
  margin-bottom: 30px;
}

.buy-ticket-title h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  padding: 0 !important;
  color: #ffffff !important;
  text-align: center;
}

.buy-ticket-title p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.01em;
  padding: 0 !important;
  color: #ffffff !important;
  margin-top: 16px;
  text-align: center;
  margin-bottom: 0 !important;
}

.buy-ticket-make {
  padding: 20px;
  border: 0.5px solid #ffffff5c;
  border-radius: 16px;
  width: 643px;
  margin: 0 auto;
  margin-top: 16px !important;
}

.buy-ticket-description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.buy-ticket-description p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.01em;
  padding: 0;
  color: #ffffff !important;
  margin: 0 !important;
}

.buy-ticket-add {
  display: flex;
  align-items: center;
}

.buy-ticket-add .form-group {
  margin: 0 !important;
}

.buy-ticket-add .payment-input {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  padding: 0 !important;
  color: #ffffff !important;
  text-align: center;
  width: 50px;
  height: 32px;
  border: none !important;
  margin: 0 5px;
  background: #93939305;
}

.buy-ticket-price-list h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #ffffff !important;
}

.buy-ticket-price-list h5 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #cbbfbf !important;
}

.buy-ticket-price-list h6 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff !important;
  padding-top: 5px;
}

.buy-ticket-price-list p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #cbbfbf !important;
  margin: 0 !important;
  padding-top: 15px;
  max-height: 75px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.buy-ticket-price-list .description-more {
  background: none !important;
  color: #003087;
  border: none !important;
  padding: 0 !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

.app-event-img {
  width: 100%;
  height: 200px;
  border-radius: 8px;
}

.buy-payment-summary {
  padding: 30px 20px;
}

.buy-payment-summary p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.01em;
  padding-bottom: 16px;
  color: #ffffff !important;
  margin: 0 !important;
}

.buy-payment-order p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #cbbfbf !important;
  margin: 0 !important;
  padding: 0;
  width: 60%;
  text-align: left;
}

.buy-payment-order span {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #cbbfbf !important;
  width: 40%;
  text-align: right;
}

.buy-payment-line {
  border-top: 1px solid #616161;
}

.buy-payment-order {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 7px 0;
}

.buy-payment-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}

.buy-payment-total p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #cbbfbf !important;
  margin: 0 !important;
  padding: 0 !important;
}

.buy-payment-total span {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #cbbfbf !important;
}

.Ticket-payment-btn {
  min-width: 192px;
  height: 36px;
  background: #0ffa6173;
  border: 2px solid #003087;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  text-transform: capitalize;
  color: #c2ffd7 !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0 !important;
  cursor: pointer;
}

.ticket-payments {
  text-align: center;
  padding-bottom: 20px;
}

#app-event-img {
  display: none;
}

.remove-inactive-img {
  background: transparent !important;
  height: 36px;
  width: 36px;
  padding: 0;
  border: none !important;
}

#billing-address-modal .modal-content,
#ticketPolicyModal .modal-content {
  background-color: unset !important;
  border: none !important;
  border-radius: unset !important;
  align-items: center;
}

#billing-address-modal .modal-header,
#ticketPolicyModal .modal-header {
  padding: 0 !important;
}

#billing-address-modal .modal-header .close,
#ticketPolicyModal .modal-header .close {
  padding: 14px 16px 0px 0px;
  /* padding: 16px; */
  margin: -1rem -1rem -1rem auto;
  color: #ffffff !important;
  opacity: unset !important;
  text-shadow: unset !important;
}

#billing-address-modal .modal-header .close span,
#ticketPolicyModal .modal-header .close span {
  font-size: 27px;
  color: #ffffff;
}

.customer-billing {
  padding: 20px 16px;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  background: linear-gradient(0deg, #000000 0%, #181818 100%);
  z-index: 1;
}

.purchase-policy {
  padding: 20px 16px;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
  background: linear-gradient(0deg, #000000 0%, #181818 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.customer-billing-section {
  height: 640px;
  padding: 23px 20px;
  overflow-y: scroll;
}

#billing-address-modal {
  max-width: 600px !important;
}

.modal {
  padding: 0 !important;
}

#ticketPolicyModal {
  max-width: 70%;
}

#billing-address-modal #address-modal,
#ticketPolicyModal #address-modal {
  display: flex;
  flex-direction: column;
  height: 700px;
  max-width: 600px !important;
  background: linear-gradient(0deg, #000000 0%, #181818 100%);
  border: 1px solid #616161;
  border-radius: 18px;
  /* padding: 23px 20px; */
  /* overflow-y: scroll; */
  /* position: relative; */
  overflow: hidden;
}

#ticketPolicyModal #address-modal {
  max-width: unset !important;
}

#address-modal1 {
  display: flex;
  flex-direction: column;
  /* height: 700px; */
  max-width: 524px !important;
  background: linear-gradient(0deg, #000000 0%, #181818 100%);
  border: 1px solid #616161;
  border-radius: 18px;
  padding: 23px 20px;
  /* overflow-y: scroll; */
  /* position: relative; */
  overflow: hidden;
}

.customer-billing h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.customer-billing p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin: 0 !important;
  padding-top: 8px;
}

.new-billing-add-info p {
  margin: 0 !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #bdbdbd;
}

.privacy-policy-buy h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin: 0 !important;
  padding-top: 8px;
}

.privacy-policy-buy p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #bdbdbd;
}

#address-modal ::-webkit-scrollbar {
  width: 6px;
  background: #616161;
}

#address-modal ::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 3px;
}

.purchase-policy label {
  margin: 0 !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.purchase-policy .form-check-input {
  margin-top: 0 !important;
}

.purchasePolicyModal-buttonBar>button {
  min-width: 162px;
  height: 42px;
  background: #0ffa6173;
  border: 2px solid #003087;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 34px;
  text-transform: capitalize;
  color: #ffffff !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0 !important;
  cursor: pointer;
  padding: 0 7px !important;
  margin: 0 10px;
}

.purchasePolicyModal-buttonBar>.accept-purchase:disabled {
  background: #616161;
  border: 2px solid #616161;
}

.purchasePolicyModal-buttonBar>.decline-purchase {
  background: linear-gradient(0deg, #000000 0%, #181818 100%);
}

.customer-billing-section .form-check-input {
  margin-top: 0;
}

.customer-billing-section label.form-check-label {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff !important;
}

#edu-modal-set .modal-content {
  background: linear-gradient(0deg, #000000 0%, #181818 100%);
  border: 1px solid #616161;
}

#edu-modal-set .close {
  color: #fff;
  text-shadow: unset;
  opacity: unset;
}

#set-list-footer .privacy-footer {
  padding-right: 80px !important;
}

#set-payment-status {
  width: 482px;
  background: linear-gradient(0deg, #000000 0%, #181818 100%);
  box-shadow: 0px -8px 50px rgb(73 192 69 / 33%);
  border-radius: 35px;
}

.card-details-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#address-modal1 .modal-header {
  margin-bottom: 24px;
}

.payment-form-field {
  width: 484px !important;
  height: 40px !important;
  background: #252525 !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #ffffff !important;
  border: none !important;
}

.payment-form-field1 {
  width: 232px !important;
  height: 40px !important;
  background: #252525 !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #ffffff !important;
  border: none !important;
}

#address-modal1 input::placeholder {
  color: #939393 !important;
}

#address-modal1 input::placeholder {
  color: #939393 !important;
}

.payment-form-label {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding-bottom: 7px;
  padding-left: 12px;
}

.add-new-address-btn {
  text-align: center;
  margin-top: 20px;
}

.add-address-btn {
  width: 188px;
  height: 36px;
  background: linear-gradient(180deg, #101010 0%, #000000 100%);
  border: 1px solid #616161;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.add-address-btn.active {
  background: #0ffa6173;
  border: 2px solid #003087;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.customer-billing1 h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

#set-card-img {
  position: relative;
}

.card-image {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

#payment-error-msg {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ff0000;
}

.app-bgimage {
  /* background-image: url("../images/ZAZA Fest BG.svg"); */
  background-size: 1920px auto;
  background-position: top;
  background-repeat: repeat;
  z-index: -10;
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 0;
  opacity: 0.5;
}

.app-bgimage.lineup-bg-video {
  background-size: contain, contain, 1920px auto;
  background-position: bottom, top, top;
  background-repeat: no-repeat, no-repeat, repeat;
}

.header-lineup {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 47px;
  height: 225px;
}

.header-lineup-text {
  display: flex;
  align-items: center;
  padding-top: 37px;
  flex: 1;
}

.header-portal-logo {
  text-align: center;
  flex: 1;
  margin-top: 27px;
}

.header-lineup-text p {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.86);
  margin-bottom: 0;
}

.header-lineup-text p a {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.86);
  margin-bottom: 0;
}

.header-lineup-text h4 {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.86);
  padding-left: 0px;
  padding-right: 42px;
}

.header-tickets-img {
  padding-top: 27px;
}

.tickets-icon {
  margin-bottom: 12px;
  margin-right: 35px;
  user-select: none;
  cursor: pointer;
}

.tickets-btn {
  box-sizing: border-box;
  height: 55px;
  width: 205px;
  border: 3px solid #ffffff;
  background-color: #003087;
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 1.21px;
  line-height: 35px;
  text-align: center;
  border-radius: 21px;
  cursor: pointer;
}

.event-sale {
  padding-top: 49px;
}

.event-sale h5 {
  color: #ffffff;
  font-family: "Impact";
  font-size: 40px;
  letter-spacing: 1.16px;
  line-height: 39px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);
}

.event-sale span {
  color: #ffffff;
  font-family: "Impact";
  font-size: 32px;
  letter-spacing: 0.92px;
  line-height: 39px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);
}

.event-sale h4 {
  margin-top: 28px;
  color: #003087;
  font-family: "Inter-Regular";
  font-size: 70px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 77px;
  text-align: center;
  height: 97px;
  text-shadow: 0 12px 11px rgba(0, 0, 0, 0.5);
}

.event-sale-bgcontent {
  background-image: url("../images/Blobs-1920.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 712px;
    height: auto; */
  /* background-size: 100vw 630px; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.event-sale-content {
  display: flex;
  align-items: flex-start;
  padding-top: 52px;
  justify-content: center;
}

.event-sale-content1 {
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  justify-content: center;
}

.event-left-bgcontent {
  width: 663px;
}

/* .event-up-bgcontent {
    width: 663px;
} */

.event-up-bgcontent h4 {
  color: #003087;
  font-family: "Impact";
  font-size: 38px;
  letter-spacing: 0.89px;
  line-height: 46px;
  text-align: center;
  text-shadow: 0 12px 11px #000000;
  text-transform: uppercase;
}

.event-up-bgcontent.event-up-announce h4 {
  color: #003087;
  font-family: "Impact";
  font-size: 30px;
  letter-spacing: 0.89px;
  line-height: 46px;
  text-align: center;
  text-shadow: 0 12px 11px #000000;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.event-left-bgcontent p {
  color: #ffffff;
  font-family: "Impact";
  font-size: 90px;
  letter-spacing: 2.08px;
  line-height: 112px;
  text-align: center;
  text-shadow: 0 12px 11px #000000;
  margin-bottom: 0;
  text-transform: uppercase;
  padding-top: 4px;
}

#event-sale-content1 .event-left-bgcontent p {
  opacity: 0.67;
  color: #ffffff;
  font-family: "Impact";
  font-size: 100px;
  letter-spacing: 2.97px;
  line-height: 104px;
  text-align: center;
  text-shadow: 0 12px 11px #000000;
  padding-top: 4px;
}

#event-sale-content1 .event-left-bgcontent {
  width: 689px;
}

#event-sale-content .event-up-bgcontent {
  width: 689px;
}

#event-sale-content {
  padding-top: 0px;
}

.event-left-bgcontent h1 {
  color: #ffffff;
  font-family: "Impact";
  font-size: 42px;
  letter-spacing: 1.25px;
  line-height: 61px;
  text-align: center;
  text-shadow: 0 12px 11px #000000;
  padding-top: 12px;
  height: 102px;
}

#event-sale-content1 .centerline-set {
  box-sizing: border-box;
  height: 393px;
  width: 2px;
  border-left: 4px solid #ffffff;
  /* margin-top: -16px; */
}

.gettickets-btn {
  box-sizing: border-box;
  height: 73px;
  width: 275px;
  border: 3px solid #ffffff;
  background-color: #003087;
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 1.21px;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
}

.bottom-btns {
  text-align: center;
  margin-top: 0px;
  position: relative;
}

.robot-icon {
  margin-right: 33px;
}

.bottom-img {
  position: absolute;
  right: 0;
  /* top: -230px; */
  bottom: 0;
  z-index: -10;
}

.app-banner {
  text-align: left;
  margin: 0px;
}

.portalApp-live {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 98px;
}

.portalApp-live-one {
  margin-top: -140px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.portalApp-live-two {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* margin-top: -100px; */
  margin-left: 35px;
  padding-bottom: 133px;
}

.portalApp-live-content h4 {
  color: #003087;
  font-family: "Inter-Regular";
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 47px;
}

.portalApp-live-content span {
  color: #003087;
  font-family: "Inter-Regular";
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 47px;
}

.portalApp-live-content p {
  color: #eeeeee;
  font-family: "Inter-Regular";
  font-size: 28px;
  letter-spacing: 0;
  line-height: 47px;
  margin-bottom: 0;
}

.app1-icon {
  height: 822px;
  width: 441.88px;
}

.portalApp-live-content {
  width: 786px;
}

.portalApp-live-img {
  padding-right: 78px;
}

.portalApp-live-one .portalApp-live-img {
  padding-right: 0px;
}

.portalApp-live-one .portalApp-live-content {
  padding-right: 42px;
  text-align: right;
  width: 819px;
}

.portalApp-live-two .portalApp-live-img {
  padding-right: 91.15px;
}

.portalApp-live-two .portalApp-live-content {
  width: 793px;
}

.footer-portal {
  background: #000000;
  padding: 35px 92px 101px 92px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  /* border-top: 1px solid; */
}

.portalApp-footer-img p {
  color: #fff;
  font-family: "Inter-Regular";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  padding-top: 17px;
  margin-bottom: 0;
}

.app2-icon {
  height: 707px;
  width: 619px;
}

.app3-icon-web {
  height: 693px;
  width: 592px;
}

.app3-icon-mob {
  display: none;
}

.event-text-mob {
  display: none;
}

/* -----------about page ------*/

.header-app-logo {
  margin-top: -16px;
}

.portal-about {
  padding-top: 134px;
}

.app-about {
  height: auto;
}

.footer-portalApp-about {
  padding-top: 0px;
  padding-bottom: 20px;
  text-align: center;
}

.about-rights {
  padding-top: 10px;
}

.portal-location {
  padding-top: 97px;
  text-align: center;
}

.location-icon {
  margin-top: 23px;
  margin-bottom: 330px;
}

.portal-loc-address p {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 28px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  margin-bottom: 0;
}

.portal-loc-address span {
  color: #003087;
  font-family: "Inter-Regular";
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
}

.portal-loc-address {
  width: 287px;
  margin: 0 auto;
  padding-top: 45px;
}

.portal-header-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

/* -----------line-up page ------*/

.portal-lineup {
  background: #000000;
}

.center-lineup-content {
  padding-bottom: 300px;
  padding-top: 51px;
}

.events-line {
  box-sizing: border-box;
  height: 472px;
  width: 2px;
  margin-left: 44px;
  border: 4px solid #ffffff;
  margin-right: 23px;
  margin-top: 162px;
}

.event-sale-content-lineup {
  display: flex;
}

/* -----------Tickets page ------*/

.tickets-address {
  padding-top: 79px;
  flex: 1 1;
  text-align: center;
}

.tickets-address h4 {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 38px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 42px;
  text-align: center;
  padding-bottom: 31px;
}

.tickets-loc-address p {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.92px;
  line-height: 35px;
  text-align: center;
  margin: 0;
}

.tickets-loc-address {
  width: 446px;
  margin: 0 auto;
  padding-top: 100px;
}

#tickets-details {
  width: 656px;
  margin: 0 auto;
}

#tickets-details .card {
  box-sizing: border-box;
  border: 3px solid #003087 !important;
  border-radius: 8px !important;
  background-color: #ffffff !important;
  margin-bottom: 23px;
}

#tickets-details .card-header {
  padding: 21.5px 29.5px 23.32px 18px;
  height: 72.82px !important;
  background-color: #ffffff !important;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

#tickets-details .tickets-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* #tickets-details .tickets-toggle img {
    height: 18px;
} */

#tickets-details button {
  width: 100%;
  padding: 0 !important;
  border: none !important;
  background: unset !important;
  cursor: pointer;
}

.tickets-toggle p {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  padding-left: 10px;
  margin: 0;
}

#tickets-details .tickets-toggle h4 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  text-align: right;
  padding: 0;
  flex: 1;
}

#tickets-details h6 {
  color: #003087;
  font-family: "Inter-Regular";
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 35px;
  padding-bottom: 18.5px;
  text-align: left;
}

#tickets-details .tickets-amount span {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  text-align: right;
}

#tickets-details .tickets-amount h6 {
  padding-bottom: 17px;
}

#tickets-details .tickets-amount {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

#tickets-details .card-body {
  padding: 0px;
}

#tickets-details .tickets-description p {
  margin: 0;
  color: #000;
  font-family: "Avenir";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 25px;
  text-align: left;
  padding-bottom: 0;
}

#tickets-details .tickets-description p b {
  font-weight: 700;
}

#tickets-details .tickets-description span {
  margin: 0;
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
}

#tickets-details .tickets-dates {
  padding-left: 49px;
}

#tickets-details .tickets-description {
  display: flex;
  align-items: flex-start;
  padding: 0px 29.5px 23px 49px;
}

#tickets-details .tickets-dates p {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 22px !important;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px !important;
  text-align: center;
  margin: 0;
  padding-bottom: 7px;
}

#tickets-details .addtocart {
  height: 42px;
  width: 154px;
  border-radius: 8px;
  background-color: #003087 !important;
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-top: 17px;
  border: unset;
  cursor: pointer;
}

#tickets-details .tickets-addremove .ticket-addfield {
  height: 28px;
  width: 30px;
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 5px;
}

#tickets-details .tickets-addremove {
  display: flex;
  align-items: center;
  justify-content: center;
}

#tickets-details .tickets-addremove .form-group {
  margin-bottom: 0 !important;
}

#tickets-details .tickets-toggle .side-arrow-down .collapse.show {
  transform: rotate(270deg);
}

#addtocart-mob {
  display: none;
}

#tickets-details .tickets-addanother .ticket-addfield {
  height: 28px;
  width: 30px;
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 5px;
}

#tickets-details .tickets-addanother .form-group {
  margin-bottom: 0rem !important;
}

#tickets-details .tickets-addanother {
  display: flex;
  align-items: center;
}

#tickets-details .tickets-another-description {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0px 19.5px 23px 49px;
}

#tickets-details .tickets-adddscription {
  display: flex;
  align-items: center;
}

#tickets-details .tickets-another-description h4 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 30px;
  text-align: left;
  padding: 0;
}

#tickets-details .tickets-addanother {
  display: flex;
  align-items: center;
}

#tickets-details #addcart {
  margin-top: 0;
  margin-left: 38px;
}

#tickets-details .tickets-adddscription p {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: 0;
  padding-right: 17px;
}

#tickets-details #tickets-toggle {
  justify-content: unset;
}

#tickets-details #add-mobcart {
  display: none;
}

#add-cart-modal {
  max-width: 688px !important;
}

#addtocart-modal {
  box-sizing: border-box;
  min-height: 482px;
  border: 2px solid #3DACFF;
  border-radius: 8px;
  background-color: #000000;
}

#addtocart-modal button.close {
  height: 21.8px;
  width: 17.88px;
  border-radius: 1.5px;
  margin-top: 12.8px !important;
  margin-right: 22.73px !important;
  padding: 0 !important;
  color: #002069 !important;
  text-shadow: unset;
  opacity: unset;
}

#addtocart-modal .modal-content {
  background-color: unset;
}

#addtocart-modal .modal-header {
  padding: 0 !important;
  margin: 0 !important;
}

#addtocart-modal button.close small,
.text_small,
span {
  font-size: 35px;
}

#addtocart-modal .add-new {
  color: #fff;
  font-family: "Avenir";
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  padding-right: 7px;
  text-align: right;
  cursor: pointer;
}

#addtocart-modal .addtocart-information h4 {
  color: #343434;
  font-family: "Inter-Regular";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 37px;
  text-align: center;
  padding-top: 3px;
  font-weight: 700;
}

#addtocart-modal .addtocart-information p {
  color: #fff;
  font-family: "Avenir";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 37px;
  text-align: center;
  margin: 0;
}

#addtocart-modal .addtocart-information {
  text-align: center;
}

#addtocart-modal .tickets-festival {
  background-color: #f8fafb;
  display: flex;
  align-items: flex-start;
  width: 561px;
  justify-content: flex-start;
  margin: 10px 0 0px 0;
  padding: 30px 44px 29px 22.5px;
}

#addtocart-modal .applogoticktet {
  height: 104px;
  width: 107px;
  user-select: none;
}

#addtocart-modal .tickets-festival p {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin: 0;
  width: 289px;
  /* padding-top: 13px; */
}

#addtocart-modal .date-sch {
  padding-left: 49.5px;
}

#addtocart-modal .tickets-festival h4 {
  color: #192d5a;
  font-family: "Avenir";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.29px;
  line-height: 22px;
  padding-top: 32px;
}

#addtocart-modal .tickets-fest-add {
  display: flex;
  align-items: center;
  border: 1px solid #979797;
  border-radius: 7px;
  height: 28.5px;
}

#addtocart-modal .tickets-fest-add svg {
  user-select: none;
  cursor: pointer;
}

#addtocart-modal .tickets-fest-add .form-group.form-group {
  margin: 0 !important;
}

#addtocart-modal .tickets-fest-add .ticket-addfield {
  height: 28px;
  width: 30px;
  color: #192d5a;
  font-family: "Avenir";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.29px;
  line-height: 22px;
  text-align: center;
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 5px;
}

#addtocart-modal .portal-qty {
  display: flex;
  align-items: center;
  padding-top: 5px;
}

#addtocart-modal .portal-qty h6 {
  padding-left: 33px;
  color: #454545;
  font-family: "Avenir";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

#addtocart-modal .view-cartmodal1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
}

#addtocart-modal .view-cartmodal1 p {
  color: #343434;
  font-family: "Inter-Regular";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 34px;
  text-align: center;
  padding-right: 37px;
  border-right: 1px solid #979797;
  margin: 0;
  cursor: pointer;
}

.portal-modalclose img {
  cursor: pointer;
  height: 24px;
}

#addtocart-modal .view-cartmodal1 .Checkout {
  height: 42px;
  width: 154px;
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  border: none;
  margin-left: 59px;
  border-radius: 8px;
  background-color: #003087;
  cursor: pointer;
}

#addtocart-modal .view-cartmodal1 .Checkout:disabled {
  opacity: 0.5;
}

.view-cartmodal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
}

#addtocart-modal .view-cartmodal p {
  color: #343434;
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 34px;
  border-right: 1px solid #979797;
  padding-right: 43px;
  margin: 0;
}

#addtocart-modal .view-cartmodal .Checkout {
  height: 42px;
  width: 154px;
  border-radius: 8px;
  background-color: #003087;
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-left: 43px;
  border: none;
  cursor: pointer;
}

#add-cart-modal .modal-content {
  background: unset;
}

#addtocart-modal .addtocart-information .hand-img {
  margin-top: -20px;
}

.todays-portal-home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
}

.portalApp-footer-img .APP.small {
  height: 40px;
  width: 180px;
}

/* .footer-portalApp-socialmedia .insta-icon {
    margin: 0 25px;
} */

.footer-portalApp-menu p {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 34px;
  margin: 0;
}

.portalApp-footer-img {
  text-align: center;
}

#event-lineup-content {
  padding-top: 51px;
}

.events-line1 {
  margin-left: 44px;
  margin-right: 23px;
}

.applogonew {
  height: 205px;
  width: 435px;
}

/* ----shopping cart---- */

#add-cart-modal .tickets-add {
  width: 516px;
  padding-top: 5px;
  margin: 0 auto;
}

#add-cart-modal .tickets-add h5 {
  color: #1b2125;
  font-family: "Avenir";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 33px;
}

.smokebg {
  border-top: 4px solid #003087;
  background: black;
  border-bottom: 4px solid #003087;
  margin-bottom: 79px;
  background-image: url("../images/Artistpic-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

#mob-left-bg p {
  line-height: 94px;
}

#mob-left-bg1 p {
  line-height: 94px;
}

#robot-icon {
  display: none;
}

.portal-sponsers-text {
  color: #fff;
  padding: 0;
  font-family: "Avenir";
  padding-left: 0;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.49202778935432434px;
  font-weight: 300;
}

.sponsor-container {
  padding: 1rem;
}

.portal-about .d-btn {
  height: 42px;
  width: 154px;
  border: 2px solid #ffffff !important;
  border-radius: 8px !important;
  background-color: #003087 !important;
  color: #ffffff !important;
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  margin-top: 90px;
}

.portal-about #donate-now {
  margin-left: 67px;
  line-height: 38px;
}

.portal-about .set-gradient-border {
  border: 8px solid #003087 !important;
  height: 1px !important;
  padding-top: 0px !important;
  background: #003087 !important;
}

.portal-about #set-gradient-border {
  border: 8px solid #003087 !important;
  height: 1px !important;
  padding-top: 0px !important;
  background: #003087 !important;
}

.portal-about .education-block-img {
  height: 415px !important;
}

.portal-about .education-block {
  height: 407px;
  padding-top: 0px;
  margin-top: 21px;
}

.portal-about .WEI-txt {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.9) !important;
  width: 1186px !important;
  padding-top: 5px;
}

.portal-about h1.slider-text.text-uppercase {
  font-family: "Inter-Regular";
  color: #ffffff !important;
  font-size: 55px;
  font-weight: 900;
  line-height: 50px;
  letter-spacing: 0.01em;
  text-align: center;
  margin: 0 !important;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.9) !important;
  padding-top: 128px;
}

.app-banner img.img-fluid.d-influ-img {
  height: 238px;
  width: 238px;
  border: 1px solid #003087cc;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #00000080;
  object-fit: cover;
}

.app-banner .d-movie-title {
  color: #fff;
  font-size: 28px !important;
  font-weight: 900 !important;
  letter-spacing: 0 !important;
  line-height: 35px !important;
  font-family: "Avenir";
  height: 42px;
}

.app-banner .d-view-text {
  color: #003087;
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 22px;
  text-align: right;
}

.app-banner .d-icon {
  height: 30px !important;
  width: 17px !important;
  color: #fff;
}

.app-banner #favorites-contens .swiper-button i {
  font-size: 29px;
}

.app-banner .d-brand-img {
  height: 127px;
  width: 186.99px;
  border: 1px solid #1e293b;
  border-radius: 10px;
  /* background-color: #fff8ef; */
  object-fit: cover;
}

.app-banner .main-content.set-main-brand {
  padding-top: 51px !important;
  padding-bottom: 84px !important;
}

.app-banner .main-content {
  padding-top: 51px;
}

.app-banner .set-main-brand .swiper-wrapper,
#influencer-list .swiper-wrapper {
  padding: 0px !important;
}

.app-confirmation {
  width: 897px;
  margin: 0 auto;
  padding: 56px 0;
  text-align: center;
}

.app-confirmation p {
  color: #1b2125;
  font-family: "Inter-Regular";
  font-size: 36px;
  letter-spacing: 0;
  line-height: 39px;
  text-align: center;
  padding-bottom: 48px;
  margin: 0;
  border-bottom: 1px solid #979797;
}

.app-confirmation .locked-in {
  color: #1b2125;
  font-family: "Inter-Regular";
  font-size: 36px;
  letter-spacing: 0;
  line-height: 39px;
  font-weight: 700;
}

.app-confirmation h3 {
  color: #1b2125;
  font-family: "Inter-Regular";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  padding: 32px 0 22px 0;
}

.app-confirmation h4 {
  color: #1b2125;
  font-family: "Inter-Regular";
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding-bottom: 81px;
}

.app-confirmation span {
  color: #1b2125;
  font-family: "Inter-Regular";
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}

.app-confirmation h5 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding-bottom: 95px;
}

.app-confirmation h2 {
  font-family: "Inter-Regular";
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-bottom: 63px;
}

.app-confirmation p.myaccount {
  width: 568px;
  color: #1b2125;
  font-family: "Inter-Regular";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 37px;
  text-align: center;
  margin: 0 auto;
  padding: 0;
  border: none;
  padding-bottom: 98px;
}

.app-confirmation .add-tickets1 {
  height: 219px;
  width: 547px;
  border: 1px solid #979797;
  background-color: #d8d8d8;
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-top: 20px;
}

.app-confirmation .add-tickets2 {
  height: 219px;
  width: 547px;
  border: 1px solid #979797;
  background-color: #d8d8d8;
  margin-top: 31px;
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.app-confirmation h6 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.69px;
  line-height: 26px;
  text-align: center;
  padding-top: 35px;
}

.app-confirmation .location-icon-confirm {
  margin-top: 23px;
}

.app-confirmation .confirm-border {
  width: 897px;
  border-bottom: 1px solid #979797;
  margin: 79px auto;
  border-top: 0;
}

.portal-accountinfo {
  box-sizing: border-box;
  /* height: 835px; */
  width: 1216px;
  /* border: 2px solid #003087;
  border-radius: 8px; */
  background-color: #ffffff;
  margin: 95px auto;
}

.portal-accountinfo a.nav-item.nav-link.active {
  height: 60px;
  border-radius: unset;
  background-color: #003087;
  width: 50%;
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 54px;
  text-align: center;
  border: unset !important;
  padding: 0 !important;
  margin: 0;
}

.portal-accountinfo a.nav-item.nav-link {
  height: 60px;
  border-radius: unset;
  background-color: #eaeaea;
  width: 50%;
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 54px;
  text-align: center;
  border: unset !important;
  padding: 0 !important;
}

.portal-accountinfo a:focus {
  color: #000000 !important;
}

.portal-accountinfo a:hover {
  color: #000000 !important;
}

.portal-accountinfo .nav-tabs {
  border: unset !important;
  margin-bottom: 0px !important;
}

.Your-Tickets {
  width: 800px;
  padding-top: 28px;
  margin: 0 auto;
  text-align: center;
}

.Your-Tickets .Add-Wallet {
  /* opacity: 0.3; */
  height: 54px;
  width: 278px;
  border: 2px solid #003087;
  border-radius: 13px;
  background-color: #ffffff;
  box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.25);
  color: #003087;
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 28px;
  cursor: pointer;
}

.Your-Tickets .Add-Wallet:disabled {
  box-sizing: border-box;
  height: 54px;
  width: 278px;
  border: 2px solid #636363;
  border-radius: 13px;
  background-color: #e3e3e3;
  box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.25);
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
}

.your-ticktesimg {
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.Your-Tickets h3 {
  color: #1b2125;
  font-family: "Avenir";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  padding-bottom: 28px;
}

.Your-Tickets h4 {
  color: #1b2125;
  font-family: "Avenir";
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  padding-bottom: 28px;
}

.Your-Tickets h5 {
  color: #1b2125;
  font-family: "Avenir";
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}

.Your-Tickets span {
  color: #1b2125;
  font-family: "Avenir";
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}

.Your-Tickets .add-tickets2 {
  height: 219px;
  width: 547px;
  border: 1px solid #979797;
  background-color: #d8d8d8;
  margin-top: 31px;
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.Your-Tickets .add-tickets1 {
  height: 219px;
  width: 547px;
  border: 1px solid #979797;
  background-color: #d8d8d8;
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-top: 20px;
}

.account-profileinfo {
  width: 1095px;
  padding-top: 31px;
  margin: 0 auto;
}

.account-profileinfo-field {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #979797;
  padding-bottom: 18px;
  padding-top: 38px;
}

.account-profileinfo h5 {
  color: #1b2125;
  font-family: "Avenir";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: bold;
  padding-bottom: 11px;
  margin-top: -31px;
}

.account-profileinfo-left p {
  color: #1b2125;
  font-family: "Avenir";
  font-size: 22px;
  letter-spacing: 0;
  line-height: 30px;
  margin: 0;
  padding-bottom: 12px;
}

.account-profileinfo-left {
  width: 547px;
}

.account-profileinfo-right .account-username {
  color: #1b2125;
  font-family: "Avenir";
  font-size: 22px;
  letter-spacing: 0;
  line-height: 30px;
  margin: 0;
  background: #fff;
  border: unset;
  padding: 0;
  height: auto;
  padding: 0;
  font-weight: bold;
}

.account-profileinfo input::placeholder {
  color: #1b2125 !important;
}

.account-profileinfo-right {
  width: 547px;
}

.account-profileinfo-right .form-group {
  margin-bottom: 0 !important;
}

.account-profileinfo .Edit-Profile {
  box-sizing: border-box;
  height: 46px;
  width: 141px;
  font-family: "Avenir";
  border-radius: 8px;
  border: 1px solid #3DACFF;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  /* margin-bottom: 95px; */
  margin-bottom: 31px;
  margin-top: 62px;
  background: unset;
  cursor: pointer;
  color: #000;
}

.account-profileinfo .save-cancelbtn .save-Profile {
  box-sizing: border-box;
  height: 46.7px;
  width: 141.7px;
  font-family: "Avenir";
  border-radius: 8px;
  border: 1px solid #3DACFF;
  color: #000;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  background: unset;
  cursor: pointer;
}

.account-profileinfo .save-cancelbtn {
  display: flex;
  align-items: center;
  margin-top: 62px;
  margin-bottom: 95px;
}

.account-profileinfo .save-cancelbtn .cancel-Profile {
  color: #1b2125;
  font-family: "Avenir";
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  width: 140.25px;
  margin-left: 30px;
  border: unset;
  background: unset;
  cursor: pointer;
}

.portal-confirmation {
  background-color: white;
  width: 100%;
}

.header-portal-logo-checkout {
  flex: 1;
  text-align: center;
}

/* ------- Checkout page ------- */

.checkout-white-bg {
  background-color: #000000;
  flex: 1 1;
  margin-top: 0px;
}

.portal-checkout {
  width: 1218px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 100px;
}

.portal-checkout .checkout-returncart {
  display: flex;
  align-items: center;
}

.portal-checkout .checkout-returncart p {
  color: #fff;
  font-family: "Avenir";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0;
  padding-left: 10px;
}

.portal-checkout h4 {
  color: #fff;
  font-family: "Avenir";
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0.71px;
  line-height: 33px;
  text-align: center;
  text-transform: uppercase;
}

.create-return {
  display: flex;
  padding-top: 26px;
}

.create-return-left {
  width: 555px;
  text-align: center;
}

.create-return-right {
  width: 555px;
}

.create-return h5 {
  color: #fff;
  font-family: "Avenir";
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0.71px;
  line-height: 33px;
  text-align: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #FFFFFF;
}

.create-return .divide-line {
  height: 465px;
  width: 1px;
  border-left: 1px solid #FFFFFF;
  margin: 52px 52px;
  margin: 52px 52px 37px 52px;
}

.create-return .Create-Account {
  margin-top: 50px;
  height: 54px;
  width: 352px;
  border-radius: 13px;
  background-color: #3DACFF;
  box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.25);
  color: #000000;
  font-family: "Avenir-medium";
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  border: unset;
  cursor: pointer;
}

.create-return .create-return-form {
  padding-top: 25px;
  width: 346px;
  margin: 0 auto;
}

.create-return .create-return-form .create-signin {
  box-sizing: border-box;
  height: 54px;
  width: 346px;
  border: 1px solid #dde3e8;
  border-radius: 3px;
  background-color: #ffffff;
  color: #1b2125 !important;
  font-family: "Inter-Regular";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

.create-return .create-return-form .create-signin:focus {
  border: 1px solid #71b6ef;
}

.create-return .create-return-form .label {
  color: #fff;
  font-family: "Avenir-medium";
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 16px;
  padding-bottom: 6px;
  text-transform: uppercase;
}

.create-return .create-return-form .form-group {
  margin: 0 !important;
  padding-top: 14px;
}

.create-return .Sign-In {
  margin-top: 27px;
  height: 54px;
  width: 352px;
  border-radius: 13px;
  background-color: #3DACFF;
  box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.25);
  color: #000000;
  font-family: "Avenir-medium";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  border: unset;
  cursor: pointer;
}

.create-return .create-return-right h6 {
  padding-top: 50px;
  color: #fff;
  font-family: "Avenir-medium";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 10px;
  border-bottom: 1px solid #fff;
  margin: 0 auto;
  width: 150px;
  text-align: center;
}

.applogonew-checkout {
  height: 113px;
  width: 117px;
  margin-top: 10px;
  margin-bottom: 24px;
}

.header-lineup-checkout .header-tickets-img {
  position: absolute;
  padding-top: 19px;
  top: 0;
  right: 0;
}

.header-lineup-checkout.header-lineup-checkout {
  position: relative;
  height: 137px;
}

.footer-portalApp-checkout {
  background-color: #000;
  padding: 36px 0;
  border-top: 1px solid;
  display: none;
}

.footer-portalApp-checkout .portalApp-footer-img p {
  margin: 0;
  color: #fff;
  font-family: "Inter-Regular";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  padding-top: 0;
}

/* ---------- account-profileinfo ---------------- */

.portal-accountcreation {
  width: 1231px;
  margin: 71px auto;
  display: flex;
}

.portal-accountcreation-left {
  width: 717px;
  margin-right: 110px;
  padding-top: 16px;
}

.portal-accountcreation-right {
  width: 404px;
  background: #F8FAFB;
  border-radius: 8px;
  padding: 17px 38px 61px 35px;
  height: 609px;
}

.portal-accountcreation-left .nav-tabs {
  border-bottom: 1px solid #e4eaee;
  margin-bottom: 0px;
}

.portal-accountcreation-left a.nav-item.nav-link.active {
  height: 49px;
  border-radius: unset;
  background-color: unset;
  color: #fff;
  font-family: "Avenir-medium";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin: 0;
  border-bottom: 4px solid #fff !important;
  padding-bottom: 33px;
  width: 358px;
  text-transform: uppercase;
}

.portal-accountcreation-left a.nav-item.nav-link {
  height: 49px;
  border-radius: unset;
  background-color: unset;
  color: #fff;
  font-family: "Avenir-medium";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding-bottom: 33px;
  border: unset;
  width: 358px;
  text-transform: uppercase;
}

.portal-accountcreation-left a.nav-item.nav-link p {
  margin: 0;
}

.portal-accountcreation-left a.nav-item.nav-link span {
  color: #fff;
  font-family: "Avenir-medium";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  padding-right: 6px;
}

.portal-accountcreation-left a.nav-item.nav-link:focus {
  /* color: #000000 !important; */
}

.portal-accountcreation-left a.nav-item.nav-link:hover {
  /* color: #000000 !important; */
}

.portal-accountcreation-ticket p {
  color: #fff;
  font-family: "Avenir-medium";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 26px;
  padding-bottom: 28px;
  border-bottom: 1px solid #c8d2da;
}

.portal-accountcreation-right h6 {
  padding-top: 34px;
  color: #fff;
  font-family: "Avenir-medium";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.accountcreate-ticket {
  padding-top: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accountcreate-ticket h3 {
  color: #fff;
  font-family: "Avenir-medium";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.accountcreate-ticket h4 {
  color: #fff;
  font-family: "Avenir-medium";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}

.checkout-info-ticket {
  padding-bottom: 20px;
  /* border-bottom: 1px solid #c8d2da; */
}

.accountcreate-ticket-set {
  padding-top: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accountcreate-ticket-set h3 {
  color: #fff;
  font-family: "Avenir-medium";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.accountcreate-ticket-set h4 {
  color: #fff;
  font-family: "Avenir-medium";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
  text-decoration: line-through;
  opacity: 0.5;
}

.accountcreate-promo {
  padding-top: 50px;
}

.portal-accountcreation-right .accountcreate-subtotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 17px;
}

.portal-accountcreation-right .accountcreate-subtotal h1 {
  color: #585858;
  font-family: "Avenir";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  padding-right: 5px;
}

.portal-accountcreation-right .accountcreate-subtotal h2 {
  color: #585858;
  font-family: "Avenir-medium";
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
}

.portal-accountcreation-right .accountcreate-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  border-top: 2px solid #c8d2da;
}

.portal-accountcreation-right .accountcreate-total h1 {
  color: #1B2125;
  font-family: "Avenir";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.portal-accountcreation-right .accountcreate-total h2 {
  color: #1B2125;
  font-family: "Avenir-medium";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
}

.portal-accountcreation-left .create-acoount-content {
  padding-top: 21px;
}

.portal-accountcreation-left .create-acoount-content p {
  color: #fff;
  font-family: "Avenir";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  margin: 0;
}

.portal-accountcreation-left .createacoount-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
}

.portal-accountcreation-left .createacoount-form .label {
  color: #fff;
  font-family: "Avenir-medium";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  padding-bottom: 6px;
  text-transform: uppercase;
}

.portal-accountcreation-left .createacoount-form .create-signin {
  box-sizing: border-box;
  height: 54px;
  width: 716px;
  border: 1px solid #dde3e8;
  border-radius: 3px;
  background-color: #ffffff;
  color: #1e293b;
  font-family: "Avenir";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

.portal-accountcreation-left .createacoount-form .create-signin:focus {
  border: 1px solid #1e293b;
}

.portal-accountcreation-left .createacoount-form .create-input {
  box-sizing: border-box;
  height: 54px;
  width: 716px;
  border: 1px solid #dde3e8;
  border-radius: 3px;
  background-color: #ffffff;
  color: #1b2125;
  font-family: "Avenir";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

.portal-accountcreation-left .createacoount-form .create-input:focus {
  border: 1px solid #1e293b;
}

.portal-accountcreation-left .form-group {
  margin-bottom: 0 !important;
}

#portal-acceptterms {
  padding-top: 3px;
}

.portal-accountcreation-left .createacoount-form #accept-terms1 {
  color: #fff;
  font-family: "Roboto-Regular";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 19px;
  margin: 0;
  width: 716px;
  padding-top: 0px;
}

.portal-accountcreation-left .createacoount-form #terms-account #accept-terms {
  padding-top: 7px;
}

.portal-accountcreation-left .createacoount-form #accept-terms span {
  color: #134dc5;
  font-family: "Inter-Regular";
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 19px !important;
  margin: 0;
}

.portal-accountcreation-left .createacoount-form #accept-terms a {
  color: #134dc5;
  font-family: "Inter-Regular";
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 19px !important;
  margin: 0;
}

.portal-accountcreation-left .checkout-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 10px;
}

.portal-accountcreation-left .checkout-returncart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.portal-accountcreation-left .checkout-returncart #back-cart {
  padding-left: 10px;
  margin: 0;
  color: #fff;
  font-family: "Avenir";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

.portal-accountcreation-left .checkout-returncart #back-cart-billing {
  padding-left: 10px;
  margin: 0;
  color: #788995;
  font-family: "Avenir";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

.portal-accountcreation-left .checkout-returncart #back-cart-mobile {
  padding-left: 10px;
  margin: 0;
  color: #fff;
  font-family: "Avenir";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  /* display: none; */
}

.portal-accountcreation-left .checkout-btn .Create-Account {
  height: 54px;
  width: 250px;
  border-radius: 13px;
  background-color: #3DACFF;
  box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.25);
  color: #000000;
  font-family: "Avenir-medium";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  border: unset;
  cursor: pointer;
}

.portal-accountcreation-left .progress-accountcretaion {
  height: 6px;
  width: 716px;
  border-radius: 4px;
  background-color: #B5B3B3;
  margin-bottom: 3px;
}

.progress-accountcretaion.weak .progress-bar {
  background-color: #dc3545 !important;
}

.progress-accountcretaion.medium .progress-bar {
  background-color: #fd7e14 !important;
}

.progress-accountcretaion.strong .progress-bar {
  background-color: #28a745 !important;
}

.event-banner-text {
  font-family: "FUTURA1986";
  display: inline;
  opacity: 1;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.event-banner-text span {
  color: #3DACFF;
  font-family: "FUTURA1986";
  display: inline;
  opacity: 1;
  font-size: 48px;
  font-weight: 400;
}

.event-name-banner {
  background-color: #4D4D4D;
  padding: 6px;
}

div.event-name-banner {
  text-align: center;
}

.event-checkout-page img {
  height: 222px;
  width: 370px;
  border-radius: 4px;
}

.artist-info {
  margin-top: 1rem;
}

.artist-info p {
  margin-bottom: 0;
}

.artist-name,
.concert-location {
  font-family: "Avenir-medium";
  font-weight: 500;
  color: #1B2125;
  font-size: 20px;
}

.concert-location {
  font-size: 18px;
}

.include-replay {
  font-size: 18px;
  font-weight: 400;
  font-family: "Avenir";
  color: #1B2125;
  display: inline;
  padding-left: 2px;
}

/*------------ purchase-policy-modal -------- */

#purchase-policy-modal {
  max-width: 792px !important;
}

#purchase-policy-modal .modal-content {
  background: unset;
}

#purchasepolicy-modal {
  box-sizing: border-box;
  height: 621px;
  border: 3px solid #003087;
  border-radius: 8px;
  background-color: #ffffff;
}

#purchasepolicy-modal .modal-header {
  padding: 0 !important;
  margin: 0 !important;
}

#purchasepolicy-modal .add-new {
  color: #343434;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  padding-right: 12px;
  text-align: right;
}

#purchasepolicy-modal button.close {
  height: 21.8px;
  width: 17.88px;
  border-radius: 1.5px;
  margin-top: 12.8px !important;
  margin-right: 22.73px !important;
  padding: 0 !important;
  color: #002069 !important;
  text-shadow: unset;
  opacity: unset;
}

#purchasepolicy-modal button.close small,
.text_small,
span {
  font-size: 35px;
}

#purchasepolicy-modal .purchase-policy-content {
  box-sizing: border-box;
  height: 484px;
  width: 748px;
  border: 1px solid #979797;
  overflow-y: scroll;
  margin: 13.5px auto 19.2px auto;
  padding: 36.5px 10.5px 0 29px;
}

#purchasepolicy-modal .purchase-policy-content h4 {
  color: #343434;
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 1.5rem;
}

#purchasepolicy-modal .purchase-policy-content p {
  color: #343434;
  font-family: "Inter-Regular";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
}

#purchasepolicy-modal .purchasepolicy-cancel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 54px;
  padding-right: 20px;
}

#purchasepolicy-modal .purchasepolicy-cancel p {
  color: #343434;
  font-family: "Inter-Regular";
  font-size: 18px;
  /* font-weight: bold; */
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
  padding-right: 23px;
}

#purchasepolicy-modal .purchasepolicy-accept {
  display: flex;
  justify-content: center;
  align-items: center;
}

#purchasepolicy-modal .purchasepolicy-cancel h5 {
  color: #343434;
  font-family: "Inter-Regular";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  padding-right: 23px;
}

#purchasepolicy-modal .purchasepolicy-accept .terms-btn {
  height: 42px;
  width: 154px;
  border-radius: 8px;
  background-color: #003087;
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  border: none;
  cursor: pointer;
}

/*------ Customer Information ---- */

.portal-accountcreation-left .createacoount-form .create-address {
  box-sizing: border-box;
  height: 54px;
  width: 714px;
  border: 1px solid #dde3e8;
  border-radius: 3px;
  background-color: #ffffff;
  color: #1b2125;
  font-family: "Avenir";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

.portal-accountcreation-left .createacoount-form .create-address:focus {
  border: 1px solid #71b6ef;
}

.portal-accountcreation-left #Cust-Info .checkout-btn {
  padding-top: 57px;
}

.portal-accountcreation-left #Cust-Info #select-set {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../images/select-triangle.svg") !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 17px) 24px !important;
  background-size: 15px !important;
}

.portal-accountcreation-left #Cust-Info .createacoount-form {
  padding-top: 50px;
}

.portal-accountcreation-left #Cust-Info p {
  padding-bottom: 11px;
}

.portal-accountcreation-left #Cust-Info .checkout-returncart p {
  padding-bottom: 0px;
}

.portal-accountcreation-left .createacoount-form .create-zipcode {
  box-sizing: border-box;
  height: 54px;
  width: 274px;
  border: 1px solid #dde3e8;
  border-radius: 3px;
  background-color: #ffffff;
  color: #1b2125;
  font-family: "Avenir";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

.portal-accountcreation-left .createacoount-form #zipcode {
  display: flex;
  align-items: center;
}

.portal-accountcreation-left .createacoount-form #zipcode .find-zipcode {
  color: #0095f8;
  font-family: "Avenir";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  border-right: 1px solid #dde3e8;
  border-bottom: 1px solid #dde3e8;
  border-top: 1px solid #dde3e8;
  height: 54px;
  width: 69px;
  text-align: center;
  padding-top: 18px;
}

.accountcreate-promo .card-header>button.inactive>.tickets-toggle>svg {
  animation: accIconUp1 0.5s;
  animation-fill-mode: forwards;
}

.accountcreate-promo .card-header>button.active>.tickets-toggle>svg {
  animation: accIconDown1 0.5s;
  animation-fill-mode: forwards;
}

@keyframes accIconDown1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes accIconUp1 {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.accountcreate-promo .tickets-toggle p {
  margin: 0;
  color: #1b2125;
  font-family: "Avenir";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 0px;
}

.accountcreate-promo .card-body {
  padding: 10px;
  margin: 0;
  color: #1b2125;
  font-family: "Avenir";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.accountcreate-promo button.btn.btn-link {
  padding: 0;
  background: unset !important;
  width: 100%;
}

.accountcreate-promo .card-header {
  height: 51px;
  padding: 0 !important;
  border-top: 1px solid #c8d2da;
  border-bottom: 1px solid #c8d2da;
}

.accountcreate-promo .card {
  margin-bottom: 24px;
}

.accountcreate-promo .card-header .tickets-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 13px;
}

/*------------- payment-selection ------------- */

#payment-selection {
  padding-top: 33px;
}

#payment-selection .Create-Account {
  height: 54px;
  width: 301px;
  font-size: 16px;
  line-height: 22px;
}

.portal-accountcreation-left #payment-selection p {
  font-size: 24px;
}

#payment-selection .checkout-btn {
  padding-top: 50px;
}

#payment-selection .credit-card-info {
  box-sizing: border-box;
  height: 367px;
  width: 714px;
  border: 1px solid #dde3e8;
  border-radius: 3px;
  background-color: #ffffff;
  padding: 35px 40px 38px 40px;
  margin-top: 38px;
}

#payment-selection .credit-card-details {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

#payment-selection #apple-pay {
  display: none;
}

#payment-selection .credit-card {
  display: flex;
  align-items: center;
}

#payment-selection .credit-card-images {
  display: flex;
  align-items: center;
}

#payment-selection .credit-card-images svg {
  user-select: none;
}

#payment-selection .credit-card-images .cards-image {
  margin: 0 8px;
}

#payment-selection .credit-card-images .cards-image1 {
  margin-top: 1px;
}

#payment-selection .credit-card input#check-api-radio {
  height: 18px;
  width: 18px;
  margin: 0;
}

#payment-selection .credit-card .form-check {
  padding-left: 0;
}

#payment-selection .credit-cardinfos {
  padding-top: 56px;
}

#payment-selection .credit-card .radio-label p {
  color: #1b2125;
  font-family: "Avenir";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 7px;
}

#payment-selection .credit-card .form-check-label {
  margin-left: 30px;
}

#payment-selection .credit-card .radio-label span {
  color: #585858;
  font-family: "Avenir";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
}

#payment-selection .credit-cardinfos .cardname-cvv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 33px;
}

#payment-selection .credit-cardinfos .label {
  color: #585858;
  font-family: "Avenir";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  padding-bottom: 5px;
}

#payment-selection .credit-cardinfos .create-name {
  box-sizing: border-box;
  height: 54px;
  width: 306px;
  border: 1px solid #dde3e8;
  border-radius: 3px;
  background-color: #ffffff;
  color: #788995;
  font-family: "Avenir";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

#payment-selection .credit-cardinfos .date {
  box-sizing: border-box;
  height: 54px;
  width: 142px;
  border: 1px solid #dde3e8;
  border-radius: 3px;
  background-color: #ffffff;
  color: #788995;
  font-family: "Avenir";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  margin-right: 22px;
}

#payment-selection .credit-cardinfos .cvvcode {
  box-sizing: border-box;
  height: 54px;
  width: 142px;
  border: 1px solid #dde3e8;
  border-radius: 3px;
  background-color: #ffffff;
  color: #788995;
  font-family: "Avenir";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

#payment-selection .credit-cardinfos .create-name:focus {
  border: 1px solid #71b6ef;
}

#payment-selection .credit-cardinfos .date:focus {
  border: 1px solid #71b6ef;
}

#payment-selection .credit-cardinfos .cvvcode:focus {
  border: 1px solid #71b6ef;
}

#payment-selection .credit-cardinfos .cardname-cvv .label1 {
  color: #d4d9dd;
  font-family: "Avenir";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  padding-bottom: 5px;
}

#payment-selection .credit-cardinfos .create-card {
  box-sizing: border-box;
  height: 54px;
  width: 634px;
  border: 2px solid #dde3e8;
  border-radius: 3px;
  background-color: #ffffff;
  color: #788995;
  font-family: "Avenir";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

#payment-selection .credit-cardinfos .create-card:focus {
  border: 2px solid #71b6ef;
}

#payment-selection .paypal-method {
  box-sizing: border-box;
  height: 152px;
  width: 714px;
  border: 1px solid #dde3e8;
  border-radius: 3px;
  background-color: #ffffff;
  padding: 35px 40px 36px 40px;
  margin-top: 35px;
}

#payment-selection .credit-cardinfos .cardnum-image {
  right: 20px;
  position: absolute;
  bottom: 18px;
  user-select: none;
}

#payment-selection .credit-cardinfos .cvv-img {
  right: 20px;
  position: absolute;
  bottom: 16px;
}

#payment-selection .credit-cardinfos #card-img {
  position: relative;
}

#payment-selection .credit-cardinfos #cvv-img {
  position: relative;
}

.portal-accountcreation-left #Cust-Info h1 {
  display: none;
}

#mob-stateview .mob-state {
  display: none;
}

#payment-selection .credit-cardinfos .cardname-cvv .expiry-date {
  display: flex;
  margin-left: 22px;
}

#addtocart-modal .portal-accountcreation-right {
  display: block;
  width: 90%;
  margin: 28px auto 54px auto;
  padding: 15px;
}

#add-cart-modal #tickets-add {
  width: 90%;
}

#addtocart-modal .accountcreate-promo {
  padding-top: 0px;
}

#addtocart-modal #tickets-festival {
  width: 100%;
  margin: 0;
  padding: 15px 16px 24px 13px;
  border: 1px solid #979797;
  border-radius: 5px;
}

#addtocart-modal .Proceed-Checkout-btn {
  height: 54px;
  width: 329px;
  border-radius: 13px;
  background-color: #003087;
  box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.25);
  color: #ffffff;
  font-family: "Avenir";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  border: unset;
  cursor: pointer;
}

#addtocart-modal .Proceed-Checkout-btn:disabled {
  opacity: 0.5;
}

#addtocart-modal .Proceed-Checkout {
  text-align: center;
  margin-bottom: 61.5px;
}

.header-myaccount {
  /* text-align: right; */
  display: flex;
  /* height: 39px; */
  background-color: #000;
  align-items: center;
  justify-content: space-between;
  padding: 15px 34px;
  /* border-bottom: 1px solid; */
}

.portals-logo {
  width: 200px;
  height: 38px;
}

.header-myaccount button#dropdown-basic {
  width: 200px;
  padding: 0 !important;
  /* height: 39px; */
  background-color: #000;
  border: none !important;
  border-radius: none !important;
  /* margin-top: 10px; */
}

.header-space {
  width: 200px;
}

.header-myaccount button#dropdown-basic p {
  margin: 0 !important;
  color: #fff;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  text-shadow: unset;
}

.header-myaccount .dropdown-toggle::after {
  border-top: 0.5em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
  position: absolute;
  left: 16px;
  top: 6px;
}

.header-myaccount .dropdown-menu.show {
  max-height: 187px;
  width: 174px;
  border: unset;
  border-radius: 4px;
  background-color: #fff;
  text-shadow: unset;
  padding: 8px 0;
  margin: 0px 0 0 auto !important;
  /* right: 0px !important; */
  /* transform: translate3d(-1.5px, 1px, 0px) !important; */
  top: 12px !important;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

div#web-dropdown #dropdown-basic p {
  color: #1e293b;
}

#web-dropdown .dropdown-toggle::after {
  color: #1e293b;
}

#web-dropdown button#dropdown-basic {
  margin-top: 0px !important;
}

.header-myaccount .dropdown-item {
  color: #1e293b !important;
  font-family: "Inter-Regular";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  text-shadow: unset;
  padding: 7px 5px;
  margin-top: 5px;
}

.header-myaccount .dropdown-item:focus,
.dropdown-item:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: #1e293b !important;
}

#togglemenu-mobile {
  display: none;
}

#add-cart-modal1 {
  max-width: 795px !important;
}

.portal-accountcreation-left #merchant-account {
  padding-left: 39.5px;
  padding-top: 0px;
  padding-right: 41.5px;
}

.portal-accountcreation-left #merchant-register-btn {
  padding-top: 40px;
  padding-bottom: 27px;
  text-align: center;
  justify-content: center;
}

#add-cart-modal1 .createacoount-form.Password-Strength {
  padding-top: 10px;
}

.portal-accountcreation-left #merchant-register-btn .Create-Account {
  width: 404px;
}

#add-cart-modal1 .modal-content {
  background: unset;
  border: unset;
}

#add-cart-modal1 .addtocart-information {
  width: 100%;
  color: #1b2125;
  font-family: "Avenir";
  font-size: 32px;
  letter-spacing: 0;
  line-height: 44px;
  text-align: center;
}

#add-cart-modal1 .addtocart-information .hand-img {
  margin-top: 10px;
}

#add-cart-modal1 .GET-TICKETS {
  box-sizing: border-box;
  height: 73px;
  width: 275px;
  border: 3px solid #ffffff;
  background-color: #003087;
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 1.21px;
  line-height: 35px;
  text-align: center;
  margin-top: 31.5px;
  margin-bottom: 106px;
}

#add-cart-modal1 .Close {
  box-sizing: border-box;
  height: 55px;
  width: 167px;
  text-align: center;
  margin-bottom: 41px;
  margin-top: 70px;
  border-radius: 13px;
  background-color: #3DACFF;
  box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.25);
  color: #000000;
  font-family: "Avenir-medium";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  border: unset;
  text-transform: uppercase;
  cursor: pointer;
}

#addtocart-modal1 {
  box-sizing: border-box;
  border: 3px solid #003087;
  border-radius: 8px;
  background-color: #ffffff;
  min-height: 618px;
  box-shadow: 0 9px 13px 0 rgba(0, 0, 0, 0.5);
}

#addtocart-modal1 .set-btns-tickets {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

#addtocart-modal1 .addtocart-information p {
  height: 125px;
}

#order-quantity {
  height: 19px;
  min-width: 19px;
  border-radius: 120px;
  background-color: #003087;
  color: #ffffff;
  font-family: "Avenir Next";
  font-size: 14.4px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21.8px;
  text-align: center;
  margin: 0 !important;
  top: 31px;
  right: 47px;
  padding: 0 2px !important;
  width: fit-content !important;
  cursor: pointer;
}

.header-tickets-img {
  text-align: end;
  flex: 1;
  /* position: relative; */
}

.app-header .header-toggle-drawer {
  display: none;
  background: #000 !important;
}

.artists-guesttext p {
  color: #ffffff;
  font-family: "Impact";
  font-size: 32px;
  letter-spacing: 0.95px;
  line-height: 71px;
  text-align: center;
  margin-top: 34px;
  text-shadow: 0 12px 11px #000000;
  width: 90%;
  margin: 34px auto 25px auto;
  height: 119px;
}

#set-mob-drawer .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
  width: 414px;
  background: #0f172a;
}

.applogo-mobile {
  margin-bottom: 52px;
  height: 77px;
  width: 80px;
}

.app-header .badge {
  padding: 0 !important;
  position: relative;
}

.appbgimage-opacity {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.soon-text {
  width: 996px;
  color: #ffffff;
  font-family: "Impact";
  font-size: 42px;
  letter-spacing: 1.25px;
  line-height: 61px;
  text-align: center;
  text-shadow: 0 12px 11px #000000;
  padding-top: 17px;
  margin: 0 auto;
  padding-bottom: 45px;
}

.app-header a {
  color: #ffffff !important;
}

.footer-portal a {
  color: #ffffff !important;
}

.footer-portal .footer-portalApp-socialmedia p {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: end;
  margin: 0;
  padding-top: 11px;
}

.footer-portal .footer-portalApp-socialmedia {
  flex: 1;
  text-align: end;
}

.footer-portal .footer--menu {
  display: flex;
  /* flex: 1; */
}

.footer-portal #footer-portalApp-menu {
  padding-left: 39px;
}

.footer-portal .portalApp-footer-img {
  /* position: absolute;
    text-align: center;
    left: 0;
    right: 0; */
  flex: 1;
}

.header-toggle-drawer .applogo-web {
  margin: 0 auto;
  /* position: absolute;
  top: 12px;
  left: 0;
  right: 0; */
}

.header-portal-logo .applogo-web {
  margin: 0 auto;
  /* position: absolute;
  left: 0;
  right: 0; */
}

/* #gettickets-btn {
    margin-top: -46px;
} */

/* .portal-accountcreation-left {
  width: 100%;
  padding-top: 0;
} */

.footer-portalApp-socialmedia .insta-icon {
  margin-right: 27px;
}

.portal-location .location-image {
  height: 617px;
  width: 689.46px;
}

.set-bobbleimage .bobbleimage {
  position: absolute;
  width: 100%;
  z-index: -10;
  padding-top: 44px;
}

.set-bobbleimage {
  position: relative;
}

.mob-bobble-images .bobbleimage-mobile {
  display: none;
}

.mob-bobble-images .bobbleimage-mobile1 {
  display: none;
}

#event-sale-content1 {
  margin-top: 68px;
}

#mob-left-bg1 {
  border-right: 4px solid #ffffff;
  width: 689px;
}

.portal-faq {
  width: 813px;
  margin: 0 auto;
  padding-top: 24px;
}

.portal-faq h4 {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0.43px;
  line-height: 26px;
  padding-bottom: 16px;
  padding-left: 25px;
}

.portal-faq .faq-questions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.portal-faq .faq-questions p {
  color: #000000;
  font-family: "Avenir";
  font-size: 16px;
  letter-spacing: 0.37px;
  line-height: 22px;
  margin: 0;
  text-align: left;
}

.portal-faq .card-header>button.inactive>.faq-questions>img {
  animation: accIconUp2 0.5s;
  animation-fill-mode: forwards;
}

.portal-faq .card-header>button.inactive>.faq-questions>svg {
  animation: accIconUp2 0.5s;
  animation-fill-mode: forwards;
}

.portal-faq .card-header>button.active>.faq-questions>svg {
  animation: accIconDown2 0.5s;
  animation-fill-mode: forwards;
}

@keyframes accIconDown2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes accIconUp2 {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.portal-faq button.btn.btn-link {
  padding: 0;
  background: unset !important;
  width: 100%;
}

.portal-faq .card-header {
  height: 55px;
  border-radius: 6px !important;
  background-color: #dde2ff;
  padding: 0 30px 0 27.38px;
  border-bottom: unset !important;
}

.portal-faq .card {
  border-radius: 6px !important;
  margin-bottom: 16px;
}

.portal-faq .card {
  border-radius: 6px !important;
  background-color: #dde2ff;
}

.portal-faq .card-body {
  color: #000000;
  font-family: "Avenir";
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.34px;
  line-height: 20px;
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  background-color: #dde2ff;
  padding: 14.27px 30px 24px 27.38px;
}

.portal-faq button.faq-toggle {
  border: none;
  width: 100%;
  background: transparent;
  height: 100%;
  padding: 0;
  cursor: pointer;
}

/* ----------------Contact------------- */

.app-conatctus {
  min-height: 436px;
  width: 564px;
  padding: 21px 32px 31px 27px;
  margin: 50px auto;
  border-radius: 18px;
  background-color: transparent;
  box-shadow: 0 2px 23px 10px rgba(255, 255, 255, 0.32);
  border: 2px solid #3DACFF;
}

.app-conatctus p {
  color: #fff;
  font-family: "Avenir-medium";
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0.43px;
  line-height: 26px;
  margin: 0;
  padding-bottom: 20px;
}

.app-conatctus .conatctname {
  box-sizing: border-box;
  height: 37.8px;
  /* width: 505.8px; */
  border: unset !important;
  background: #ffffff;
  color: #898989;
  font-family: "Avenir";
  font-size: 14.4px;
  font-weight: 300;
  letter-spacing: 0.26px;
  line-height: 19px;
  padding: 0 14.4px !important;
}

.app-conatctus .message-inbox {
  box-sizing: border-box;
  height: 122.8px;
  /* width: 505.8px; */
  border: unset !important;
  background: #ffffff;
  color: #898989;
  font-family: "Avenir";
  font-size: 14.4px;
  font-weight: 300;
  letter-spacing: 0.26px;
  line-height: 19px;
  padding: 8.5px 14.4px !important;
}

.app-conatctus .form-group {
  margin-bottom: 16px !important;
}

.app-conatctus .contactdata-send {
  height: 35px;
  width: 174px;
  border-radius: 4.8px;
  background-color: #3DACFF;
  color: #000;
  font-family: "Avenir-medium";
  font-size: 14.4px;
  font-weight: 900;
  letter-spacing: 0.26px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  border: unset !important;
  cursor: pointer;
}

.app-conatctus .send-btn {
  text-align: center;
}

.portal-conatct .conatct-box {
  height: 53px;
  width: 670px;
  opacity: 0.81;
  border-radius: 6.4px 6.4px 0 0;
  background-color: #231c1c;
}

.app-conatctus #select-set {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../images/contactdown.svg") !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 12px) 14px !important;
  background-size: 15px !important;
}

/* Chrome, Safari, Edge, Opera */
.payment-input::-webkit-outer-spin-button,
.payment-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.payment-input[type="number"] {
  -moz-appearance: textfield;
}

.set-main-brand .swiper-wrapper,
#influencer-list .swiper-wrapper {
  padding: 15px 0;
}

.set-main-brand .set-view-all-text,
#influencer-list .set-view-all-text {
  margin-bottom: 0 !important;
}

.main-content.set-main-brand {
  padding-top: 24px !important;
  padding-bottom: 21px !important;
}

.purchaseCheck {
  display: flex;
  align-items: center;
  margin-right: 23px;
}

.purchaseCheck>input {
  padding: 0;
  margin: 0;
  z-index: 100;
  opacity: 0;
  height: 15px;
  width: 15px;
  margin-left: -20px;
}

.purchaseCheck>label {
  display: flex;
  align-items: center;
  color: #343434;
  font-family: "Inter-Regular";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
  padding-top: 3px;
}

.purchaseCheck>input+label::before {
  content: " ";
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: #003087;
  border-radius: 2px;
  box-shadow: none;
  z-index: 10;
  background-color: #ffffff;
  margin-left: -20px;
}

.purchaseCheck>input:checked+label::before {
  background-color: #003087;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+");
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: center center;
}

.addOnTag {
  font-size: 12px !important;
  line-height: 15px !important;
  width: fit-content;
  padding: 3px 10px !important;
  background-color: #003087;
  color: #ffffff;
  border-radius: 5px;
}

.ordersAccordion .card-header>button.inactive .side-arrow-down,
#tickets-details .card-header>button.inactive>.tickets-toggle>img {
  animation: accIconUp 0.5s;
  animation-fill-mode: forwards;
}

.ordersAccordion .card-header>button.active .side-arrow-down,
#tickets-details .card-header>button.active>.tickets-toggle>img {
  animation: accIconDown 0.5s;
  animation-fill-mode: forwards;
}

@keyframes accIconDown {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(90deg);
  }
}

@keyframes accIconUp {
  0% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.ordersAccordion .card {
  box-sizing: border-box;
  border: 3px solid #003087 !important;
  border-radius: 8px !important;
  background-color: #ffffff !important;
  margin-bottom: 23px;
}

.ordersAccordion h4 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  padding-left: 10px;
  margin: 0;
}

.ordersAccordion .card-header {
  padding: 21.5px 29.5px 23.32px 18px;
  height: 72.82px !important;
  background-color: #ffffff !important;
  border: none;
}

.ordersAccordion .ticketContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 10px 10px;
  background-color: #efefef;
  border: 2px #003087 solid;
  border-radius: 10px;
  overflow: hidden;
}

.ticketContainer .ticketDetails {
  flex: 1;
  text-align: start;
  padding: 10px 20px;
}

.ticketContainer .ticketDetails>p {
  margin: 10px 0;
  color: #000000;
}

.ticketContainer .barcodeImage {
  height: 150px;
  width: 150px;
}

/* ------Privacy-policy------- */

.portal-privacypolicy {
  padding-top: 63px;
  width: 1025px;
  margin: 0 auto;
}

.portal-privacypolicy h6 {
  height: 78px;
  color: #3DACFF;
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 47px;
  text-align: center;
}

.portal-privacypolicy .contact-privacy {
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 0px;
  padding-bottom: 20px !important;
  border-bottom: unset;
}

.portal-privacypolicy span,
h4 {
  color: #3DACFF;
  font-family: "Inter-Regular";
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.portal-privacypolicy span,
h5 {
  color: #3DACFF;
  font-family: "Inter-Regular";
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.portal-privacypolicy .header-titile {
  color: #3DACFF;
  font-weight: bold;
  font-size: 16px;
}

.portal-privacypolicy .accept-condi {
  color: #3DACFF;
  font-weight: bold;
  font-size: 16px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.portal-privacypolicy .heder-margin {
  margin-bottom: 2rem;
}

.portal-privacypolicy h4 {
  margin-top: 40px !important;
}

.portal-privacypolicy b {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.portal-privacypolicy p {
  color: #eeeeee;
  font-family: "Inter-Regular";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: unset;
}

.portal-privacypolicy .list-contact-set {
  color: #eeeeee;
  font-family: "Inter-Regular";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: unset;
}

.portal-privacypolicy .privacy-contact {
  text-align: center;
  padding-top: 12px;
}

.portal-modalclose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 14px;
  margin-right: 16px;
  margin-bottom: 10px;
}

a.navbar-mob-logo {
  flex: 1;
  text-align: center;
}

.header-toggle-icon {
  flex: 1;
}

.btn-tickets-home button {
  border-radius: 22px;
  width: 322px;
  height: 67px;
}

.home-artist-carousel .control-arrow,
.home-artist-carousel .carousel-status,
.home-artist-carousel .control-dots {
  display: none !important;
}

.home-artist-carousel {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 50px;
  padding: 0px 30px;
}

.home-artist-carousel .hac-item {
  font-size: 40px;
  font-family: "OswaldBold", sans-serif;
  color: #ffffff;
}

.dropdown-menu.dropdown-menu-right {
  display: block !important;
}

.bottomDrawer {
  display: none !important;
}

.home-image {
  margin: 0 auto;
  text-align: center;
  margin-top: -264px;
}

.app-header {
  z-index: 99;
}

img.About-image {
  height: 469px;
  width: 463.9px;
  opacity: 0.6;
}

.portalApp-about {
  width: 970px;
  margin: 0 auto;
  text-align: center;
}

.portalApp-about p {
  margin: 0;
  color: #eeeeee;
  font-family: "Inter-Regular";
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 47px;
  text-align: center;
  padding-top: 39px;
}

.portalApp-about span {
  color: #003087;
  font-family: "Inter-Regular";
  font-size: 28px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 47px;
  text-align: center;
}

.tickets-addremove svg {
  user-select: none;
  cursor: pointer;
}

.your-ticktesimg .bg-image {
  height: 575px;
  width: 278px;
}

.your-ticktesimg .ticket-description {
  position: absolute;
  top: 32px;
  width: 278px;
}

.addtowallet-items {
  position: relative;
  width: 278px;
  padding-bottom: 41px;
}

.your-ticktesimg .ticket-description .ticket-applogo {
  height: 94.58px;
  width: 98.08px;
  user-select: none;
}

.your-ticktesimg .ticket-description h5 {
  color: #ffffff;
  font-family: "Avenir";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  font-weight: unset !important;
  margin: 17px 0;
}

.your-ticktesimg .ticket-description span {
  color: #ffffff;
  font-family: "Avenir";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  font-weight: 600;
}

.ticket-event-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 22px;
  margin-right: 14px;
  border-bottom: 0.75px solid #ffffff;
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.your-ticktesimg .ticket-description .ticket-event-details p {
  color: #ffffff;
  font-family: "Avenir";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin: 0;
  text-align: left;
  /* flex: 1; */
  font-weight: 600;
}

.your-ticktesimg .ticket-description .ticket-event-details span {
  color: #ffffff;
  font-family: "Avenir";
  font-size: 17.25px;
  letter-spacing: 0;
  line-height: 20.25px;
  font-weight: unset;
  font-weight: 300;
}

.your-ticktesimg .ticket-description .ticket-event-details .check-ticket-ga {
  padding-left: 4px;
  width: 43px;
}

.your-ticktesimg .ticket-description .Scanner-image {
  height: 172.65px;
  width: 172.21px;
  margin-bottom: 30px;
  border-radius: 17.25px;
}

.artists-description {
  padding-top: 54px;
}

.artists-description.totalTickets {
  padding-top: 140px;
  width: fit-content;
  margin: 0 auto;
}

.artists-description p {
  color: #ffffff;
  font-family: "Impact";
  font-size: 32px;
  letter-spacing: 0.95px;
  line-height: 49px;
  text-align: center;
  margin: 0;
  padding: 0 10px;
}

.footer-audience {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -10;
}

#footerbg-mobile {
  display: none;
}

.chekckout-privacypolicy {
  display: flex;
  align-items: center;
}

#togglemenu-mobile .line {
  margin: 30px auto 20px auto;
  width: 249px;
  border-top: 1px solid #ffffff;
}

.portal-privacypolicy .add-linkcolor {
  color: #003087 !important;
  font-family: "Inter-Regular";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: unset;
}

.upload-logo-image .form-group {
  text-align: center;
  margin: 6px !important;
}

.upload-logo-image {
  box-sizing: border-box;
  /* width: 505.8px; */
  border: unset !important;
  background: #ffffff;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

#upload-logo {
  color: #898989;
  font-family: "Avenir";
  font-size: 14.4px;
  font-weight: 300;
  letter-spacing: 0.26px;
  line-height: 19px;
  height: unset;
  background: #ffffff;
  border: unset;
}

.upload-logo-image img {
  height: 120px;
  width: 120px;
  margin-bottom: 16px;
}

.upload-logo-image input[type="file"]::file-selector-button {
  background-color: #003087;
  border: unset !important;
  color: #ffffff;
  font-family: "Avenir";
  font-size: 14.4px;
  font-weight: 900;
  letter-spacing: 0.26px;
  line-height: 19px;
  text-align: center;
  height: 35px;
  width: 120px;
  border-radius: 4.8px;
}

/* .upload-logo-image input[type=file]::file-selector-button:hover {
  background-color: #003087;
  border: unset !important;
  color: #ffffff;
  font-family: "Avenir";
  font-size: 14.4px;
  font-weight: 900;
  letter-spacing: 0.26px;
  line-height: 19px;
  text-align: center;
} */

.conatctus-confirmation {
  text-align: center;
  margin: 298px 0 400px 0;
}

.conatctus-confirmation img {
  height: 71px;
  width: 62px;
}

.conatctus-confirmation p {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 28px;
  letter-spacing: 0.5px;
  line-height: 30px;
  text-align: center;
  padding-top: 19px;
}

.account-profileinfo .profile-picture {
  text-align: center;
}

.account-profileinfo .profile-picture h4 {
  padding-top: 11.5px;
  color: #000000;
  font-family: "Avenir";
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 20px;
}

.account-profileinfo .profile-img {
  box-sizing: border-box;
  height: 126px;
  width: 126px;
  /* border: 1px solid #979797; */
  background-color: #d8d8d8;
  border-radius: 100%;
  margin: 0 auto;
}

.account-profileinfo .profile-img img {
  height: 126px;
  width: 126px;
  border-radius: 100%;
  border: 1px solid #979797;
}

.account-profileinfo .profile-img label.btn {
  color: #000000;
  font-family: Avenir;
  font-size: 42px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.account-profileinfo .profile-picture .input-field {
  display: none;
}

.portal-conatct .upload-logo-image p {
  font-family: "Avenir";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.43px;
  line-height: 35px;
  margin-bottom: 0px;
  color: #898989;
  padding-left: 10px;
  padding-bottom: 0;
}

.portal-conatct .upload-logo-image label.btn {
  padding: 0 12px;
  height: 35px;
  min-width: 174px !important;
  border-radius: 4.8px !important;
  background-color: #3DACFF;
  color: #000;
  font-family: "Avenir-medium";
  font-size: 13.4px;
  font-weight: 500;
  letter-spacing: 0.26px;
  line-height: 35px;
  text-align: center;
  /* text-transform: uppercase; */
  border: unset !important;
}

.portal-conatct .upload-logo-image .input-field {
  display: none;
}

.portal-conatct .upload-logo-image .set-brandlogo-txt {
  display: flex;
}

.portal-conatct .product-label label {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 14.4px;
  font-weight: 500;
  letter-spacing: 0.26px;
  line-height: 24px;
  flex: 1;
  margin: 5px 0;
}

.sponsorProduct-radio {
  display: flex;
  align-items: center;
  margin: 2px 0;
  padding-left: 10px;
}

.sponsorProduct-radio>input {
  display: inline;
  position: relative;
  width: 18px;
  height: 18px;
  margin: 0 10px;
}

#add-cart-modal1 .sponsor-paymentdetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 23px;
  padding-top: 18px;
}

#add-cart-modal1 .sponsor-paymentdetails h5 {
  color: #fff;
  font-family: "Avenir-medium";
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}

#add-cart-modal1 .sponsor-paymentdetails .portal-modalclose {
  padding-top: 0px;
}

#add-cart-modal1 .product-amount {
  margin-left: 23px;
  padding-top: 20px;
}

#add-cart-modal1 .product-amount p {
  color: #fff;
  font-family: "Avenir";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}

#add-cart-modal1 .product-amount span {
  font-weight: 700;
  font-size: 18px;
}

.account-profileinfo .profiledetails-edit {
  width: 30px;
  height: 30px;
  top: 92px;
  right: 4px;
  position: absolute;
  cursor: pointer;
}

.account-profileinfo .account-name {
  color: #1b2125;
  font-family: "Avenir";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  background: unset;
  border: none;
}

section#home {
  overflow: hidden;
  width: 100%;
}

#home-slider .slick-bg img {
  width: 100% !important;
  height: 700px;
  border-top: 2px solid #003087;
  border-bottom: 2px solid #003087;
}

#home-slider .slick-bg {
  height: 700px !important;
}

#home .swiper-button-next {
  color: #003087;
}

#home .swiper-button-prev {
  color: #003087;
}

#tickets-details.sponsorProducts {
  width: auto;
}

#tickets-details.sponsorProducts .tickets-toggle {
  width: 100%;
  position: relative;
}

#tickets-details.sponsorProducts .card {
  margin: 5px 0;
  border: unset !important;
  border-radius: 2px !important;
}

#tickets-details.sponsorProducts .card-header {
  padding: 8px 10px;
  height: auto !important;
  display: flex;
  align-items: center;
  padding-right: 35px;
}

#tickets-details.sponsorProducts .card-body {
  padding: 0px;
}

#tickets-details.sponsorProducts .tickets-description {
  display: flex;
  align-items: flex-start;
  padding: 0px 29.5px 23px 33px;
}

#tickets-details.sponsorProducts .card-header p {
  color: #000;
  font-family: "Avenir";
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  padding-left: 10px;
  margin: 0;
  padding-bottom: 0;
}

#tickets-details.sponsorProducts .card-header h4 {
  color: #000;
  font-family: "Avenir";
  text-align: right;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0;
}

#tickets-details.sponsorProducts .card-header .side-arrow-down {
  height: 15px;
  width: 15px;
}

#tickets-details.sponsorProducts .card-header .sponsorProduct-radio {
  position: absolute;
  right: 15px;
  padding: 0;
}

#tickets-details.sponsorProducts .card-header .sponsorProduct-radio input {
  margin: 0;
  accent-color: #0f172a;
}

#tickets-details.sponsorProducts .card .card-header,
#tickets-details.sponsorProducts .card .card-body {
  border-radius: 0 !important;
}

#forgotpassword .create-return-right {
  width: auto;
}

#forgotpassword {
  display: unset;
}

#view-ticketssold {
  font-family: "Impact";
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0.83px;
  line-height: 30px;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  padding: 0;
}

#sign-in {
  color: #003087;
  font-family: "Inter-Regular";
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0.83px;
  line-height: 30px;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  padding-top: 5px;
}

#forgotpassword .create-return-form {
  padding-top: 41px;
}

#forgotpassword #accept-terms {
  color: #fff;
  font-family: "Roboto-Regular";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 19px;
  margin: 0;
  padding-top: 2px;
}

#payment-selection form {
  padding: 0;
}

#portal-artistlist #d-influ-slider {
  padding: 15px 0;
}

.site-message {
  background-color: #390e65;
}

.site-message p {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.35px;
  line-height: 26px;
  text-align: center;
  margin: 0;
  padding-top: 15px;
  padding-bottom: 14px;
}

.reservenow-text {
  text-align: center;
  padding-top: 27px;
}

.reservenow-text p {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.35px;
  line-height: 26px;
  text-align: center;
  margin: 0;
}

#tickets-details .ticketreserve-acc {
  padding-top: 20px;
}

#tickets-details .ticketreserve-acc .card {
  box-sizing: border-box;
  border: unset !important;
  margin-bottom: 0px;
  background-color: #e1e1e1 !important;
}

#tickets-details .ticketreserve-acc .card-header {
  padding: 8px 16px 10px 16px;
  height: 69.82px !important;
  background-color: #e1e1e1 !important;
}

.ordersAccordion .card-header>button.inactive .pay-later,
#tickets-details .ticketreserve-acc .card-header>button.inactive>.tickets-toggle>.pay-later1 {
  animation: unset !important;
  animation-fill-mode: forwards;
}

.ordersAccordion .card-header>button.active .pay-later,
#tickets-details .ticketreserve-acc .card-header>button.active>.tickets-toggle>.pay-later1 {
  animation: unset !important;
  animation-fill-mode: forwards;
}

#tickets-details .ticketreserve-acc .tickets-toggle p {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 22px;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 20px;
  margin: 0;
  font-weight: 300;
}

#tickets-details .ticketreserve-acc .tickets-toggle span {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 28px;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: 700;
}

#tickets-details .ticketreserve-acc .tickets-toggle .ticket-reserve {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 700;
}

#tickets-details .ticketreserve-acc .ticketreserve-description {
  padding: 6px 28px 21.5px 87px;
}

#tickets-details .ticketreserve-description h2 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 700;
  text-align: left;
  padding-bottom: 0 !important;
}

#tickets-details .ticketreserve-description h5 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 700;
  padding-top: 7px;
  text-align: left;
}

#tickets-details .ticketreserve-description p {
  margin: 0;
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 4px;
  text-align: left;
}

#tickets-details #ticketreserve-acc {
  padding-top: 0;
}

#tickets-details #ticketreserve-acc .pay-later {
  height: 38px;
  width: 38px;
}

#tickets-details #ticketreserve-acc .tickets-toggle span {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 21px;
  letter-spacing: 0;
  line-height: 25px;
  font-weight: 700;
}

#tickets-details #ticketreserve-acc .tickets-toggle p {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 16.5px;
  letter-spacing: 0;
  line-height: 18px;
  padding-left: 15px;
  margin: 0;
  font-weight: 300;
  text-align: left;
}

#tickets-details #ticketreserve-acc .tickets-toggle .ticket-reserve {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
  font-weight: 700;
}

#tickets-details #ticketreserve-acc .tickets-toggle .pay-laterimg {
  display: flex;
  align-items: center;
  padding-left: 31px;
}

#tickets-details #ticketreserve-acc .card-header {
  padding: 0;
  height: 54px !important;
  background-color: #e1e1e1 !important;
}

#tickets-details #ticketreserve-acc .show-moretickets {
  align-items: center;
  width: 173px;
  display: flex;
  justify-content: center;
  border-radius: 0 0 8px 0;
  background-color: #eeeeee;
  height: 54px;
}

#tickets-details #ticketreserve-acc .tickets-toggle .show-moretickets p {
  color: #003087;
  font-family: "Inter-Regular";
  font-size: 16.5px;
  letter-spacing: 0;
  line-height: 18px;
  padding-right: 10px;
  margin: 0;
}

#tickets-details #ticketreserve-acc .card {
  box-sizing: border-box;
  border: unset !important;
  margin-bottom: 0px;
  border-radius: 0 0 8px 8px !important;
  background-color: #e1e1e1 !important;
}

#ticketreserve-acc .card-header>button.inactive>.tickets-toggle>.show-moretickets>.show-more {
  animation: accIconUp4 0.5s;
  animation-fill-mode: forwards;
}

#ticketreserve-acc .card-header>button.active>.tickets-toggle>.show-moretickets>.show-more {
  animation: accIconDown4 0.5s;
  animation-fill-mode: forwards;
}

@keyframes accIconDown4 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes accIconUp4 {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

#tickets-details #ticketreserve-acc .card-body {
  padding: 0;
  background: #eeeeee !important;
  display: flex;
  padding-top: 15px;
  padding-bottom: 20px;
  border-radius: 0 0 8px 8px !important;
}

#tickets-details #ticketreserve-acc .ticketreserve-description {
  width: 403px;
  padding: 0px 12px 0px 26px;
}

#tickets-details #ticketreserve-acc .ticketreserve-description p {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  padding-top: 5px;
  margin: 0;
}

#tickets-details #ticketreserve-acc .ticketreserve-description p>span {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 21px;
}

#tickets-details #ticketreserve-acc .ticketreserve-description p>b {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 21px;
}

#tickets-details #ticketreserve-acc .ticketreserve-description h5 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 21px;
  padding-top: 0;
}

#tickets-details #ticketreserve-acc .ticket-paylater p {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 17px;
  margin: 0;
}

.ticket-paylater {
  padding-left: 27px;
  padding-top: 0px;
  border-left: 1px solid #979797;
}

#tickets-details .paylater-radio {
  padding-top: 16px;
  display: flex;
  align-items: center;
}

#tickets-details #ticketreserve-acc .ticket-paylater .paylater-radio p {
  margin: 0;
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: 10px;
  font-weight: 400;
}

#tickets-details #ticketreserve-acc .ticket-paylater .paylater-radio span {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 700;
}

#tickets-details .paylater-radio .text-radio {
  padding-left: 0px;
  line-height: 10px;
}

#tickets-details .text-radio>input {
  display: inline;
  position: relative;
  width: 18px;
  height: 18px;
  margin: 0px;
}

#tickets-details .paylater-radio .text-radio input {
  margin: 0;
  accent-color: #0095f8 !important;
}

.reserve-pricetext {
  color: #0095f8;
  font-family: "Inter-Regular";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;
  padding-bottom: 7px;
}

#tickets-details-addcart .paylater-radio .text-radio {
  padding-left: 0px;
  line-height: 10px;
}

#tickets-details-addcart .ticketreserve-acc {
  padding-top: 20px;
}

#tickets-details-addcart .ticketreserve-acc .card {
  box-sizing: border-box;
  border: unset !important;
  margin-bottom: 0px;
  background-color: #e1e1e1 !important;
}

#tickets-details-addcart .ticketreserve-acc .card-header {
  padding: 8px 16px 10px 16px;
  height: 69.82px !important;
  background-color: #e1e1e1 !important;
}

.ordersAccordion .card-header>button.inactive .pay-later,
#tickets-details-addcart .ticketreserve-acc .card-header>button.inactive>.tickets-toggle>.pay-later1 {
  animation: unset !important;
  animation-fill-mode: forwards;
}

.ordersAccordion .card-header>button.active .pay-later,
#tickets-details-addcart .ticketreserve-acc .card-header>button.active>.tickets-toggle>.pay-later1 {
  animation: unset !important;
  animation-fill-mode: forwards;
}

#tickets-details-addcart .ticketreserve-acc .tickets-toggle p {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 22px;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 20px;
  margin: 0;
  font-weight: 300;
}

#tickets-details-addcart .ticketreserve-acc .tickets-toggle span {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 28px;
  letter-spacing: 0;
  line-height: 30px;
  font-weight: 700;
}

#tickets-details-addcart .ticketreserve-acc .tickets-toggle .ticket-reserve {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 700;
}

#tickets-details-addcart.ticketreserve-acc .card-body {
  padding: 6px 28px 21.5px 87px;
}

#tickets-details-addcart.ticketreserve-description h2 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 700;
  text-align: left;
  padding-bottom: 0 !important;
}

#tickets-details-addcart .ticketreserve-description h5 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 700;
  padding-top: 7px;
  text-align: left;
}

#tickets-details-addcart .ticketreserve-description p {
  margin: 0;
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 4px;
  text-align: left;
}

#tickets-details-addcart #ticketreserve-acc {
  padding-top: 0;
}

#tickets-details-addcart #ticketreserve-acc .pay-later {
  height: 38px;
  width: 38px;
}

#tickets-details-addcart #ticketreserve-acc .tickets-toggle span {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 21px;
  letter-spacing: 0;
  line-height: 25px;
  font-weight: 700;
}

#tickets-details-addcart #ticketreserve-acc .tickets-toggle p {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 16.5px;
  letter-spacing: 0;
  line-height: 18px;
  padding-left: 15px;
  margin: 0;
  font-weight: 300;
}

#tickets-details-addcart #ticketreserve-acc .tickets-toggle .ticket-reserve {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
  font-weight: 700;
}

#tickets-details-addcart #ticketreserve-acc .tickets-toggle .pay-laterimg {
  display: flex;
  align-items: center;
  padding-left: 31px;
}

#tickets-details-addcart #ticketreserve-acc .card-header {
  padding: 0;
  height: 54px !important;
  background-color: #e1e1e1 !important;
}

#tickets-details-addcart #ticketreserve-acc .show-moretickets {
  align-items: center;
  width: 173px;
  display: flex;
  justify-content: center;
  border-radius: 0 0 8px 0;
  background-color: #eeeeee;
  height: 54px;
}

#tickets-details-addcart #ticketreserve-acc .show-moretickets p {
  color: #003087;
  font-family: "Inter-Regular";
  font-size: 16.5px;
  letter-spacing: 0;
  line-height: 18px;
  padding-right: 10px;
  margin: 0;
}

#tickets-details-addcart #ticketreserve-acc .card {
  box-sizing: border-box;
  border: unset !important;
  margin-bottom: 0px;
  border-radius: unset !important;
  background-color: #e1e1e1 !important;
}

#tickets-details-addcart #ticketreserve-acc .card-body {
  padding: 0;
  background: #eeeeee !important;
  display: flex;
  padding-top: 15px;
  padding-bottom: 20px;
}

#tickets-details-addcart #ticketreserve-acc .ticketreserve-description {
  width: 403px;
  padding: 0px 12px 0px 26px;
}

#tickets-details-addcart #ticketreserve-acc .ticketreserve-description p {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  padding-top: 5px;
  margin: 0;
}

#tickets-details-addcart #ticketreserve-acc .ticketreserve-description p>span {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 21px;
}

#tickets-details-addcart #ticketreserve-acc .ticketreserve-description p>b {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 21px;
}

#tickets-details-addcart #ticketreserve-acc .ticketreserve-description h5 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 21px;
  padding-top: 0;
}

#tickets-details-addcart #ticketreserve-acc .ticket-paylater p {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 17px;
  margin: 0;
}

#tickets-details-addcart .ticket-paylater {
  padding-left: 27px;
  padding-top: 0px;
  border-left: 1px solid #979797;
}

#tickets-details-addcart .paylater-radio {
  padding-top: 16px;
  display: flex;
  align-items: center;
}

#tickets-details-addcart #ticketreserve-acc .ticket-paylater .paylater-radio p {
  margin: 0;
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: 10px;
  font-weight: 400;
}

#tickets-details-addcart #ticketreserve-acc .ticket-paylater .paylater-radio span {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 700;
}

#tickets-details-addcart.paylater-radio .text-radio {
  padding-left: 0px;
  line-height: 10px;
}

#tickets-details-addcart .text-radio>input {
  display: inline;
  position: relative;
  width: 18px;
  height: 18px;
  margin: 0px;
}

#tickets-details-addcart .paylater-radio .text-radio input {
  margin: 0;
  accent-color: #0095f8 !important;
}

#tickets-details-addcart .tickets-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#tickets-details-addcart button {
  width: 100%;
  padding: 0 !important;
  border: none !important;
  background: unset !important;
}

#accountcreate-ticket {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 17px;
}

#accountcreate-ticket h3 {
  color: #0095f8;
  font-family: "Inter-Regular";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
}

#accountcreate-ticket h4 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}

#accountcreate-ticket1 {
  display: flex;
  padding-top: 46px;
}

#accountcreate-ticket1 h3 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}

#accountcreate-ticket1 h4 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: 16px;
  font-weight: 400;
}

.addciright-logo {
  text-align: left;
  padding: 7px 0px 15px 67px;
  background-color: #000000;
  border-top: 1px solid #979797;
}

.addciright-logo p {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: left;
  margin-bottom: 0;
  display: none;
}

#togglemenu-mobile .line1 {
  margin: 25px auto;
  width: 249px;
  border-top: 1px solid #ffffff;
}

/* .modal:has(#add-cart-modal)::-webkit-scrollbar {
  width: 0 !important
} */

.modal::-webkit-scrollbar {
  width: 0 !important;
}

.modal {
  scrollbar-width: none;
}

#add-cart-modal .reserve-cartitem {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 22px;
}

#add-cart-modal .reserve-cartitem h5 {
  color: #0095f8;
  font-family: "Inter-Regular";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
}

#add-cart-modal .reserve-cartitem h5>span {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;
}

#add-cart-modal .reserve-cartitem h6 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 17px;
  text-align: left;
  margin-top: 15px;
}

#add-cart-modal .reserve-cartitem h6>span {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  text-align: left;
  margin-top: 15px;
}

.line-text-decoration {
  text-decoration: line-through;
  opacity: 0.5;
}

.stripe-payment-info {
  display: none;
}

html:has(.MuiDrawer-modal)::-webkit-scrollbar {
  width: 0 !important;
}

html:has(.MuiDrawer-modal) #root {
  height: 100vh !important;
  overflow: hidden !important;
}

/* html>.MuiDrawer-modal::-webkit-scrollbar {
  scrollbar-width: none;
}

html>.MuiDrawer-modal #root {
  height: 100vh !important;
  overflow: hidden !important;
  scrollbar-width: none;
}

.MuiDrawer-modal {
  scrollbar-width: none;
} */

#ticketcard-badge {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #003087;
  color: #ffffff;
  font-family: "Avenir";
  font-size: 14.4px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21.8px;
  text-align: center;
  margin: 0 !important;
  width: fit-content !important;
  padding: 3px 5px !important;
  height: 26px;
  min-width: 19px;
  border-radius: 4px !important;
}

#faqdown-icon {
  width: 15px !important;
  height: 10px !important;
  min-width: 15px;
  margin-left: 8px;
}

#menuIconwhite {
  cursor: pointer;
}

#checkout-billinginfo {
  justify-content: space-between;
}

#account-info-tabs .tab-content {
  height: 866px;
  overflow-y: scroll;
  scrollbar-color: #9c9c9c #d8d8d8;
  scrollbar-width: 15px;
}

#account-info-tabs ::-webkit-scrollbar {
  width: 15px;
}

/* Track */
#account-info-tabs ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d8d8d8;
  border-radius: 15px;
}

/* Handle */
#account-info-tabs ::-webkit-scrollbar-thumb {
  background: #9c9c9c;
  border-radius: 8px;
}

#account-info-tabs ::-moz-scrollbar-thumb {
  border-radius: 8px;
}

.Your-Tickets .ticket-send {
  color: #000000;
  font-family: "Avenir";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding-top: 6px;
}

.Your-Tickets .Toaccept-invitation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6px;
}

.Your-Tickets .Toaccept-invitation p {
  color: #000000;
  font-family: "Avenir";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin: 0;
}

.Your-Tickets .Toaccept-invitation h3 {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin: 0 5px;
  padding: 0;
}

.Your-Tickets .Toaccept-invitation h4 {
  color: #000000;
  font-family: "Avenir";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.Your-Tickets .Toaccept-invitation-cancel {
  color: #003087;
  font-family: "Avenir";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin: 0 auto;
  padding: 0;
  cursor: pointer;
  width: 76px;
}

.selectTicket-transfer {
  box-sizing: border-box;
  width: 1216px;
  border: 2px solid #003087;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 95px auto;
}

.ticket-transfer-main {
  max-height: 864px;
  overflow-y: scroll;
}

.selectTicket-transfer ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.selectTicket-transfer ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d8d8d8;
  border-radius: 8px;
}

/* Handle */
.selectTicket-transfer ::-webkit-scrollbar-thumb {
  background: #9c9c9c;
  border-radius: 8px;
}

.selectTicket-transfer ::-moz-scrollbar-thumb {
  border-radius: 8px;
}

.backto-ticktes {
  /* display: flex;
  align-items: center; */
  height: 58px;
  background-color: #003087;
}

.backtomy-ticktes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
}

.backtomy-ticktes p {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  margin: 0;
  padding-left: 10px;
}

.backtomy-ticktes .back-btn {
  margin-left: 10px;
  width: 31.38px;
  cursor: pointer;
}

.backto-ticktes h2 {
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-top: -32px;
}

.send-ticket-information {
  margin: 60px auto 0px auto;
  width: 584px;
}

.send-ticket-info {
  padding: 15px 95px 6px 0px;
  border-bottom: 1px solid #979797;
  display: flex;
  align-items: center;
}

.send-ticket-info p {
  margin: 0;
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
}

.send-ticket-info p>span {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 13px;
}

.ticket-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  margin-left: -11px;
}

.send-ticket-info h4 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 13px;
  padding-top: 8px;
}

.ticket-section h2 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}

.ticket-section h2>span {
  color: #003087;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}

.ticket-section h3 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}

.ticket-section h3>span {
  color: #848484;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}

.send-ticket-form {
  padding-top: 15px;
  padding-bottom: 18px;
}

.send-ticket-form p {
  color: #1b2125;
  font-family: "Inter-Regular";
  font-size: 22px;
  letter-spacing: 0;
  line-height: 25px;
  margin: 0;
}

.send-ticket-form .label {
  color: #5f5f5f;
  font-family: "Avenir";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  padding-top: 13px;
  text-transform: uppercase;
}

.send-ticket-form .form-group {
  margin-bottom: 0 !important;
}

.send-ticket-form .create-input {
  height: 41px;
  width: 581px;
  border: 1px solid #dde3e8;
  border-radius: 3px;
  background-color: #ffffff;
  color: #5f5f5f;
  font-family: "Avenir";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}

.send-ticket-form .PhoneInputInput {
  height: 41px;
  width: 581px;
  border: 1px solid #dde3e8;
  border-radius: 3px;
  background-color: #ffffff;
  color: #5f5f5f;
  font-family: "Avenir";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}

.PhoneInputCountry {
  margin-left: 10px;
  margin-right: 10px;
}

.send-ticket-form #create-message {
  height: 111px;
}

.send-tickets-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 18px;
  padding-bottom: 48px;
}

.send-tickets-btns .Send-Ticket {
  height: 46px;
  width: 283px;
  border-radius: 10px;
  background-color: #003087;
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  border: unset;
  text-transform: uppercase;
  cursor: pointer;
}

.send-tickets-btns .CANCEL {
  box-sizing: border-box;
  height: 47px;
  width: 284px;
  border: 1px solid #003087;
  border-radius: 10px;
  color: #003087;
  font-family: "Inter-Regular";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  background: unset;
  cursor: pointer;
}

.back-bottomtext {
  display: flex;
  align-items: center;
  margin-left: 22px;
  margin-bottom: 65px;
  margin-top: -84px;
}

.back-bottomtext .back-black-btn {
  width: 22.15px;
  cursor: pointer;
}

.back-bottomtext p {
  margin: 0;
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  padding-left: 8px;
}

.backto-ticktes .closeicon-white {
  display: none;
}

.accessticket-banner {
  padding: 78px 93px 97px 0;
  border-right: 1px solid #979797;
  margin-right: 100px;
}

.accessticket-banner h6 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.52px;
  line-height: 25px;
  text-align: center;
  width: 346px;
  margin: 0 auto;
  padding-top: 27px;
}

.accessticket {
  display: flex;
  padding-top: 83px;
  width: 973px;
  margin: 0 auto;
  padding-bottom: 100px;
}

.access-ticket {
  padding-top: 45px;
}

.access-ticket h1 {
  color: #1b2125;
  font-family: "Inter-Regular";
  font-size: 26px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 18px;
  text-align: center;
}

.access-ticket h2 {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.52px;
  line-height: 25px;
  text-align: center;
  margin-bottom: 14px;
  margin-top: 26px;
}

.access-ticket .Create-Account {
  height: 54px;
  width: 352px;
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  border: unset;
  background: #003087;
  border-radius: 13px;
  margin-bottom: 15px;
}

.access-ticket .label {
  color: #5f5f5f;
  font-family: "Avenir";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  padding-top: 14px;
  padding-bottom: 6px;
  text-transform: uppercase;
}

.access-ticket .create-signin {
  box-sizing: border-box;
  height: 54px;
  width: 346px;
  border: 1px solid #dde3e8;
  border-radius: 3px;
  background-color: #ffffff;
  color: #5f5f5f;
  font-family: "Avenir";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}

.access-ticket .form-group {
  margin-bottom: 0 !important;
}

.access-ticket h6 {
  color: #5f5f5f;
  font-family: "Avenir";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  border-bottom: 1px solid #5f5f5f;
  padding-top: 18px;
  margin: 0 auto;
  width: 155px;
}

.accessticket-banner-content h5 {
  color: #1b2125;
  font-family: "Inter-Regular";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  margin: 0;
  text-align: center;
  padding: 0 8px;
}

.ticket-banner-img {
  position: relative;
}

.accessticket-banner-content {
  position: absolute;
  top: 23px;
  left: 0;
  right: 0;
}

.accessticket-banner-content .send-ticket-info {
  padding: 0;
  border-bottom: unset;
  padding-top: 27px;
  text-align: left;
  padding-left: 176px;
  padding-right: 10px;
  display: block !important;
}

.accessticket-banner-content .ticket-section {
  justify-content: left;
  margin-left: 0;
}

.our-sponsors {
  padding-top: 114px;
  width: 1300px;
  margin: 0 auto;
}

.brand-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.our-sponsors h1 {
  color: #003087;
  font-family: "Inter-Regular";
  font-size: 32px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 47px;
  text-align: center;
  padding-bottom: 22px;
}

.brand-logo-box {
  box-sizing: border-box;
  height: 128px;
  width: 185px;
  border: 2px solid #003087;
  border-radius: 26px;
  background-color: #ffffff;
  padding: 11px 0;
  text-align: center;
  margin: 0 37px 47px 37px;
}

.brand-logo-box .brand-logoimg {
  height: 74px;
  width: 78px;
}

.our-sponsors .support-of {
  padding-bottom: 35px;
  padding-top: 6px;
}

.support-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 1045px;
  margin: 0 auto;
}

.support-logo-box {
  box-sizing: border-box;
  height: 137px;
  width: 187px;
  border: 2px solid #003087;
  border-radius: 63px;
  background-color: #ffffff;
  padding-top: 47px;
  padding-bottom: 40px;
  text-align: center;
  margin: 0 37px 47px 37px;
}

.support-logoimg {
  height: 50px;
  width: 103.53px;
}

.sponsor-tooltip {
  background-image: url("../../assets/images/Bubble.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 133 / 139;
  height: 139px;
  width: 133px;
  margin-left: -62px;
  margin-bottom: -24px;
}

.sponsor-tooltip-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 11px 29px 11px;
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  margin: 0;
}

#addtocart-modal-account {
  box-sizing: border-box;
  min-height: 200px;
  border: 1px solid #3DACFF;
  border-radius: 8px;
  background-color: #000000;
  width: 792px;
  padding: 0;
  margin: 0;
}

#addtocart-modal-account .add-new {
  color: #fff;
  cursor: pointer;
  font-family: "Avenir";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  padding-right: 8px;
  text-align: right;
}

#addtocart-modal-account .create-acoount-content {
  margin-top: -12px;
  padding-top: 0;
  padding-left: 38px;
  padding-right: 38px;
}

#addtocart-modal-account .checkout-btn {
  justify-content: center;
  align-items: center;
  padding-bottom: 27px;
}

#addtocart-modal-account .checkout-btn .Create-Account {
  width: 404px;
}

.ticket-transfer-quantity {
  max-height: 262px;
  overflow-y: scroll;
}

.selectTicket-transfer .ticket-transfer-main .ticket-transfer-quantity ::-webkit-scrollbar {
  width: 10px !important;
}

.selectTicket-transfer .ticket-transfer-main .ticket-transfer-quantity ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d8d8d8;
  border-radius: 8px !important;
}

.send-ticket-info .text-radio {
  padding-left: 0px;
  line-height: 10px;
  margin-right: 31px;
  margin-bottom: 17px;
}

.send-ticket-info .text-radio input {
  margin: 0;
  accent-color: #003087 !important;
}

/* .send-ticket-info .text-radio>input {
  display: inline;
  position: relative;
  height: 26px;
  width: 26px;
  margin: 0px;
} */

#send-ticket-info .text-radio {
  padding-left: 0px;
  line-height: 10px;
  margin-right: 31px;
  margin-bottom: 17px;
}

#send-ticket-info .text-radio input {
  margin: 0;
  accent-color: #003087 !important;
}

/* #send-ticket-info .text-radio>input {
  display: inline;
  position: relative;
  height: 26px;
  width: 26px;
  margin: 0px;
} */

.selection-generaltickets {
  width: 100%;
}

.brand-section p {
  margin: 0;
  color: #343434;
  cursor: pointer;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 17px;
  padding: 0 5px;
  text-align: center;
  margin-top: 10px;
}

.access-ticketnew .accessticket {
  flex-direction: column;
  align-items: center;
}

.access-ticketnew .accessticket-banner {
  padding: 0;
  border: unset;
  margin: 0;
}

.access-ticketnew .access-ticket .Create-Account {
  width: 263px;
  margin: 0 15px;
}

.access-ticketnew .access-ticket {
  display: flex;
  padding-top: 50px;
  align-items: center;
}

.access-ticketnew #accept {
  background-color: #28a745;
}

.access-ticketnew #decline {
  background-color: #ff0000;
}

.brand-section .brand-logo-box:focus,
.brand-logo-box:hover {
  background-color: #ffffff !important;
}

.alertbox {
  min-height: auto;
  width: 272px;
  border-radius: 8px;
  background-color: #eeeeee;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: flex-start;
  margin: 1px auto;
}

.alertbox-message {
  border-left: 14px solid #ff621b;
  border-radius: 8px 0 0 8px;
  max-height: 100%;
  min-height: auto;
}

.alertbox-close .alert-close {
  cursor: pointer;
}

.alertbox-message p {
  margin: 0;
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  width: 222px;
  padding: 20px;
}

#alertbox-warning {
  border-left: 14px solid #ff621b;
}

#alertbox-error {
  border-left: 14px solid #d9534f;
}

#alertbox-success {
  border-left: 14px solid #00aa3b;
}

.alertbox-main {
  position: absolute;
  z-index: 99;
  right: 0.55px;
  top: 0;
}

.text-radio {
  display: flex;
  align-items: center;
  margin-right: 23px;
}

.text-radio>input {
  padding: 0;
  margin: 0;
  z-index: 100;
  opacity: 0;
  width: 27px;
  height: 27px;
  margin-left: -20px;
}

/* .text-radio>label {
  display: flex;
  align-items: center;
  color: #343434;
  font-family: "Inter-Regular";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
  padding-top: 3px;
} */

.text-radio>input+label::before {
  content: " ";
  display: inline-block;
  width: 27px;
  height: 27px;
  margin-right: 0px;
  border-width: 1px;
  border-style: solid;
  border-color: #003087;
  border-radius: 5px;
  box-shadow: none;
  z-index: 10;
  background-color: #ffffff;
  margin-left: 0px;
}

.text-radio>input:checked+label::before {
  background-color: #003087;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+");
  background-repeat: no-repeat;
  background-size: 22px 22px;
  background-position: center center;
}

.payment-info-container {
  width: 85%;
  margin: 40px auto;
}

.paymentinfo-form label {
  color: #5f5f5f;
  font-family: "Avenir";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  padding-top: 13px;
  text-transform: uppercase;
}

.paymentinfo-form .form-group {
  margin-bottom: 0 !important;
}

.paymentinfo-form .create-input {
  box-sizing: border-box;
  height: 54px;
  width: 100%;
  border: 1px solid #dde3e8;
  border-radius: 3px;
  background-color: #ffffff;
  color: #1b2125;
  font-family: "Avenir";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

.paymentinfo-form .payment-expiry {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentinfo-form .save-btn {
  text-align: center;
}

.paymentinfo-form .save {
  margin: 30px auto;
  height: 48px;
  width: 100%;
  border-radius: 13px;
  background-color: #003087;
  box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.25);
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  border: unset;
  cursor: pointer;
}

.paymentinfo-form .pay-expiry {
  padding-left: 0;
}

.paymentinfo-form .pay-cvc {
  padding: 0;
}

.show-details {
  margin: 10px 0;
  border: 2px solid #dde3e8 !important;
  border-radius: 5px !important;
  padding: 8px 10px;
  display: flex;
  align-items: center;
}

.show-details .showdetails-radio>input {
  display: inline;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 10px;
}

.showdetails-radio.form-check {
  padding: 0px;
}

.show-details .card-imgs {
  width: 34px;
  height: 24px;
}

.show-details .showdetails-radio input {
  margin: 0;
  accent-color: #003087;
  margin-top: 6px;
  margin-right: 13px;
}

.show-details .cardnumber {
  color: #000000;
  font-family: "Inter-Regular";
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 13px;
  margin: 0;
}

.payment-info-container .card-text {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  margin-top: 40px;
}

.select-cardtype button#dropdown-basic {
  background-color: unset !important;
  border: none !important;
  border-radius: none !important;
  margin-top: 0px;
  padding: 0;
}

.select-cardtype button#dropdown-basic p {
  margin: 0 !important;
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.86);
}

.select-cardtype .dropdown-toggle::after {
  border-top: 0.5em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
  position: absolute;
  left: 16px;
  top: 6px;
}

.select-cardtype .dropdown-menu.show {
  max-height: 187px;
  width: 110px;
  border: 1px solid #979797;
  border-radius: 5px;
  background-color: #000000;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 0 8px;
  margin: 0px 0 0 auto !important;
  right: 0px !important;
  transform: translate3d(3.5px, 0px, 0px) !important;
  top: 30px !important;
}

#dropdown-basic .setcardtypeimg {
  width: 28px;
  height: 28px;
}

#web-dropdown button#dropdown-basic {
  margin-top: 0px !important;
  background: #fff;
}

.select-cardtype .dropdown-item {
  color: #ffffff !important;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.86);
  padding: 0;
  margin-top: 22px;
}

.select-cardtype.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: #1e293b !important;
}

#web-dropdown p {
  margin: 13px 0 !important;
  color: #ffffff;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.86);
}

.select-cardtype {
  flex: 1;
  text-align: end;
}

#cardheader-text {
  color: #000000;
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding: 15px;
  background-color: #ffffff !important;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

#checkout-cardpay {
  box-sizing: border-box;
  border: 1px solid #DDE3E8 !important;
  border-radius: 3px !important;
  background-color: #ffffff !important;
  margin-bottom: 23px;
  padding-top: 30px;
}

.p-FieldLabel {
  display: block;
  color: #000000;
  font-size: 12px !important;
  font-family: "Avenir-medium";
  font-weight: 600;
}

#add-cart-modal1 .p-FieldLabel {
  color: #fff;
}

/* #checkout-cardpay button {
  width: 100%;
  padding: 0 !important;
  border: none !important;
  background: unset !important;
  cursor: pointer;
} */

#checkout-cardpay .paymentinfo-form .save {
  background-color: #003087 !important;
  border: unset !important;
  margin-bottom: 10px !important;
}

#cardpay-acc {
  margin-top: 13px;
}

.stripe-checkoutbtn .stripeButton {
  background-color: #3DACFF !important;
  border: unset !important;
  margin-bottom: 10px !important;
  /* padding: 12px 56px !important; */
  color: #000;
  border-radius: 13px;
  width: 250px;
  height: 54px;
  padding: 0 !important;
}

.stripe-checkoutbtn {
  width: 100%;
  text-align: end;
  display: flex;
  justify-content: flex-end;
}

#cardpay-acc .portal-accountcreation-left .create-acoount-content p {
  font-size: 20px !important;
  line-height: 24px !important;
}

#cardpay-acc .show-details .cardnumber {
  font-size: 20px !important;
  line-height: 24px !important;
}

#checkout-cardpay .card-body {
  padding-top: 0 !important;
}

.addnewcard-modal .create-acoount-content {
  margin-top: -12px;
  padding-top: 0;
  padding-left: 38px;
  padding-right: 38px;
}

.addnewcard-modal .create-acoount-content p {
  color: #1b2125;
  font-family: "Inter-Regular";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 26px;
  margin: 0;
}

.addnewcard-modal .paymentinfo-form {
  padding: 21px;
}

.addnewcard-modal {
  min-height: fit-content !important;
}

.addnewcard-modal .paymentinfo-form .save {
  margin-top: 44px;
  margin-bottom: 10px;
}

.addcard-image {
  width: 18px;
}

/* ----------- new home page ----------- */

.newhome-img {
  text-align: center;
  position: relative;
}

.new-home {
  margin-top: -227px;
}

#myCanvas {
  position: absolute;
  background: transparent;
  width: 100%;
  height: 100%;
}

.bg-canvas {
  position: relative;
}

.location-img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin-top: 290px;
}

.location-img h4 {
  color: #ffffff;
  font-family: "FUTURA1986";
  font-size: 90px;
  letter-spacing: 2.68px;
  line-height: 96px;
  text-align: center;
  text-shadow: 0 12px 11px #000000;
  text-transform: uppercase;
}

.newhome-concert h4 {
  font-family: "FUTURA1986";
  color: #ffffff;
  font-size: 80px;
  letter-spacing: 2.38px;
  line-height: 84px;
  text-align: center;
  text-shadow: 0 12px 11px #000000;
  text-transform: uppercase;
  padding-top: 49px;
}

.newhome-concert h5 {
  font-family: "FUTURA1986";
  color: #ffffff;
  font-size: 90px;
  letter-spacing: 2.38px;
  line-height: 84px;
  text-align: center;
  text-shadow: 0 12px 11px #000000;
  text-transform: uppercase;
  padding-top: 74px;
}

.newhome-concert h6 {
  font-family: "FUTURA1986";
  color: #ffffff;
  font-size: 50px;
  letter-spacing: 2.38px;
  line-height: 84px;
  text-align: center;
  text-shadow: 0 12px 11px #000000;
  text-transform: uppercase;
  padding-top: 32px;
  padding-bottom: 45px;
}

.newhome-concert {
  position: relative;
  text-align: center;
}

.concert-img {
  position: absolute;
  top: 0px;
  width: 100%;
}

#blue-banner {
  display: none;
}

.concert-img h4 {
  font-family: "FUTURA1986";
  color: #ffffff;
  font-size: 80px;
  letter-spacing: 2.38px;
  line-height: 84px;
  text-align: center;
  text-shadow: 0 12px 11px #000000;
  text-transform: uppercase;
  padding-top: 49px;
}

#concert-img h4 {
  display: none;
}

.blue-banner {
  width: 100%;
}

.app-square-newlogo {
  text-align: center;
  flex: 1 1;
  margin-top: 69px;
}

.close-icon {
  fill: #fff;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.portal-footer-last {
  width: 147px;
}

.time-header {
  font-family: "Inter-Regular";
  padding-top: 20px;
}

.brand-listslider {
  display: flex;
}

.set-staticbrand .brand-image {
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.set-staticbrand {
  position: relative;
  margin-right: 20px;
  height: 127px;
  min-width: 186.99px;
  border: 1px solid #1e293b;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-color: transparent;
  /* Initial background color */
}

.set-staticbrand:hover::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: -15px;
  right: 0;
  background: rgb(23 38 34 / 44%);
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 9;
  border-radius: 10px;
  transform: translateX(15px);
  min-width: 100%;
}

.set-staticbrand img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.set-staticbrand:hover {
  z-index: 99;
  transform: scale3d(1.1, 1.1, 1) z(0, 0, 0) perspective(500px);
  transform-origin: 60% 60%;
  transition: all 0.6s ease 0s;
  -webkit-transition: all 0.6s ease 0s;
  -moz-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.9);
  position: relative;
  overflow: visible;
  border-radius: 10px;
}

.set-staticbrand .overlay-text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 2;
  /* Ensure text is above the image */
}

.set-staticbrand:hover .overlay-text {
  opacity: 1;
  /* Text appears on hover */
}

#set-staticbrand {
  margin-left: 20px;
}

.banner-timerset {
  height: calc(100vh - 78px);
}

#home-streamiframe {
  height: calc(100vh - 78px);
}

.footer-iconsset {
  display: flex;
  margin-top: 44px;
  gap: 25px;
  justify-content: center;
}

.portals-network-container {
  width: 1237px;
  margin: 0 auto;
  padding-bottom: 191px;
  padding-top: 24px;
}

.portal-description {
  margin-bottom: 67px;
}

.portal-description p {
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  font-family: "Avenir";
  margin: 0;
}

.todays-portal-dashboard {
  background-color: #000226;
}

.app-banner .main-content.set-main-brand {
  padding: 24px 0 !important;
}

.types-ofportals {
  display: flex;
  gap: 91px;
  padding-bottom: 75px;
}

.portals-network {
  background-image: url("../../assets/images/Portal/world-background.png");
  background-repeat: no-repeat;
  background-size: 100% 750px;
  background-position: bottom;
}

.mobile-portallogo {
  display: none;
  margin-bottom: 37px;
  text-align: center;
}

.mobile-brand {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-portallogo svg {
  width: 237px;
  height: 46px;
}

.countdown-container {
  background: #000000;
  padding-top: 23px;
  padding-bottom: 51px;
}

#favorites-contens .swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  display: none;
}

#favorites-contens .swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  display: none;
}

#home-ordermodal {
  max-width: 690px;
}

.order-mainsection {
  background-color: black;
  border: 1px solid #3DACFF;
  border-radius: 8px;
  padding-top: 56px;
  text-align: center;
  height: 603px;
}

.order-mainsection .order-concert {
  font-style: italic;
  font-size: 28px;
  font-weight: 600;
  color: #3DACFF;
  font-family: "Avenir-medium";
  margin: 0;
}

.concert-name {
  font-size: 28px;
  font-weight: 600;
  color: #F4F4F4;
  font-family: "Avenir-medium";
  text-transform: uppercase;
  margin: 0;
  margin-top: 40px;
}

.cocert-data {
  font-size: 22px;
  font-weight: 400;
  color: #F4F4F4;
  font-family: "Avenir";
  margin: 0;
}

.live-concert {
  width: 367px;
  height: 102px;
  border-radius: 9px;
  background-color: #F1F1F1;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: space-between;
  padding-left: 17px;
  padding-right: 30px;
  margin-top: 40px;
}

.live-stream {
  font-size: 20px;
  font-weight: 500;
  color: #1B2125;
  font-family: "Avenir-medium";
  margin: 0;
}

.concert-replay {
  font-size: 18px;
  font-weight: 400;
  color: #1B2125;
  font-family: "Avenir";
  margin: 0;
}

.order-stream {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #454545;
  font-family: "Avenir-medium";
  margin: 0;
}

.watch-later {
  font-size: 18px;
  font-weight: 500;
  color: #F4F4F4;
  font-family: "Avenir-medium";
  letter-spacing: 0.8999999761581421px;
  padding-top: 11px;
  margin: 0;
}

.ordernow-btn {
  height: 54px;
  width: 329px;
  border-radius: 13px;
  background-color: #3DACFF;
  box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.25);
  color: #000000;
  font-family: "Avenir-medium";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  border: unset;
  cursor: pointer;
  margin-top: 52px;
}

.banner-signinbtn {
  /* width: 200px; */
  text-align: right;
}

.banner-signinbtn .bannersign-btn {
  color: #3DACFF;
  background: transparent;
  font-size: 14px;
  font-family: "Avenir";
  border-radius: 8px;
  border: 1px solid #3DACFF;
  height: 31px;
  width: 71px;
  text-align: center;
  cursor: pointer;
}

#portals-ticketcard {
  width: 680px;
  border: none;
  margin-top: 130px;
  text-align: center;
}

#portals-ticketcard .event-details {
  font-size: 22px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 35px;
  font-family: "Avenir-medium";
}

#portals-ticketcard .event-date {
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 50px;
  font-family: "Avenir";
}

#portals-ticketcard .ticket-info {
  background-color: #F1F1F1;
  border-radius: 9px;
  padding: 21px 30px 21px 17px;
  text-align: center;
  margin-bottom: 12px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  width: 367px;
  height: 102px;
}

#portals-ticketcard .ticket-type {
  font-size: 20px;
  color: #1B2125;
  font-weight: 500;
  margin: 0px;
  font-family: "Avenir-medium";
}

#portals-ticketcard .ticket-subtext {
  font-size: 20px;
  color: #1B2125;
  font-weight: 400;
  margin: 0px;
  font-family: "Avenir";
}

#portals-ticketcard .ticket-details {
  align-items: start;
}

#portals-ticketcard .ticket-price {
  font-size: 18px;
  line-height: 24px;
  color: #454545;
  font-weight: 600;
  margin: 0px;
  font-family: "Avenir-medium";
}

#portals-ticketcard .replay-info {
  font-size: 18px;
  font-family: "Avenir-medium";
  letter-spacing: 0.8999999761581421px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 21px;
}

#portals-ticketcard .order-button {
  height: 54px;
  width: 329px;
  border-radius: 13px;
  background-color: #3DACFF;
  box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.25);
  color: #000000;
  font-family: "Avenir-medium";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  border: unset;
  cursor: pointer;
}

#termsAndConditions {
  margin-top: 0.1rem;
}

.progress {
  height: 6px;
  border-radius: 4px;
  background-color: #B5B3B3;
}

.stripe-sponsor-modal .payment-img {
  margin: 0 auto;
  margin-top: 30px;
  width: 80px;
}

.stripe-sponsor-modal .link-text {
  font-size: 20px;
}

.portal-contactinfo {
  width: 1228px;
  margin: 37px auto 70px auto;
  display: flex;
  justify-content: space-between;
}

.contact-form-section {
  width: 376px;
  margin-top: 64px;
}

.contact-form-section h2 {
  text-transform: uppercase;
  letter-spacing: 0.5022783875465393px;
  color: #FFFFFF;
  font-size: 28px;
  font-family: "Roboto-Regular";
  margin-bottom: 20px;
}

.contact-form-section form {
  padding: 0;
  min-width: 376px;
}

.contact-field {
  background-color: #FFFFFF !important;
  border: 0.8px solid #979797 !important;
  height: 36.79px !important;
  color: #000 !important;
  padding-left: 19.2px !important;
  font-family: "Avenir";
  font-size: 14.399999618530273px;
  font-weight: 300;
  letter-spacing: 0.2583145797252655px;
  margin-bottom: 16px;
}

#contact-select-field {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../images/Portal/arrow.png") !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 13px) 12px !important;
  background-size: 15px !important;
  line-height: 20px;
  color: #898989 !important;
}

.contact-field::placeholder {
  color: #898989;
  font-family: "Avenir";
  font-size: 14.399999618530273px;
  font-weight: 300;
  letter-spacing: 0.2583145797252655px;
}

.contact-message {
  height: 122.35px !important;
  margin-bottom: 16px;
  background-color: #FFFFFF !important;
  border: 0.8px solid #979797 !important;
  color: #000 !important;
  padding-left: 19.2px !important;
  font-size: 14.399999618530273px;
  font-weight: 300;
  letter-spacing: 0.2583145797252655px;
  line-height: 39px;
}

.contact-imgsection {
  color: #898989;
  font-family: "Avenir";
  font-size: 14.399999618530273px;
  font-weight: 300;
  letter-spacing: 0.2583145797252655px;
}

.conatct-portal-bottom-img {
  width: 100%;
}

.contact-imgsection {
  width: 572px;
}

.contact-send-btn {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.send-btn1 {
  cursor: pointer;
  width: 88px;
  height: 35px;
  font-size: 14.399999618530273px;
  font-weight: 600;
  letter-spacing: 0.2583145797252655px;
  text-transform: uppercase;
  color: #fff;
  border: unset;
  border-radius: 4.8px;
  background-color: #3DACFF;
  font-family: "Avenir-medium";
  margin-left: 25px
}

.contact-submit-calculate {
  display: flex;
  align-items: center;
}

.contact-submit-calculate p {
  font-size: 14.399999618530273px;
  font-weight: 400;
  letter-spacing: 0.2583145797252655px;
  color: #000;
  font-family: "Avenir";
  margin: 0 !important;
}

.number-addfield {
  border-radius: 3px !important;
  width: 34px !important;
  height: 34px !important;
  margin-left: 17px;
  background: transparent !important;
  border: 0.5px solid #000 !important;
  font-size: 14.399999618530273px !important;
  font-weight: 400;
  letter-spacing: 0.2583145797252655px;
  color: #000 !important;
  font-family: "Avenir" !important;
  padding: 0 4px !important;
  text-align: center;
}

#top-swiper .banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "FUTURA1986";
  font-size: 65px;
  font-weight: 400;
  letter-spacing: 2.66px;
  text-align: center;
}

.mux-player {
  --play-button: none;
  --center-controls: none;
  --center-player-button: none;
  pointer-events: none;
  height: calc(100vh - 78px);
  --rendition-selectmenu:none;
  --playback-rate-button:none;

}
mux-player::part(control-bar bottom) {
  opacity: 1 !important; 
}

/*----------------- Responsive----------- */

@media only screen and (max-width: 2000px) {
  #view-all-podcast {
    flex: 0 0 11.11%;
    max-width: 11.11%;
  }
}

@media only screen and (max-width: 1800px) {
  #view-all-podcast {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  #view-all-movie1 {
    flex: 0 0 16.85%;
    max-width: 16.66285%;
  }
}

@media only screen and (max-width: 1640px) {
  /* .d-trending-info {
        padding: 60px 0;
    } */

  .d-title-txt-one {
    font-size: 30px;
    padding-bottom: 12px;
  }

  .d-txt-one {
    height: 77px !important;
    font-size: 15px;
    line-height: 25px;
  }

  .tranding-block2 {
    background-size: contain !important;
    background-position: center;
    background-repeat: no-repeat;
  }

  .d-set-featured-video,
  .d-set-featured-channel-photo {
    padding-top: 160px;
  }

  .ratings span {
    font-size: 19px;
  }

  .show-movie-tab p {
    width: 73%;
  }

  .comment-para p {
    width: 80%;
  }

  .comment-review-right {
    height: 1405px;
  }

  .comment-scrolbar {
    height: 1294px;
  }

  .podcast-text {
    font-size: 68px;
    line-height: 82px;
  }

  .show-Listen-btn {
    width: 239px;
  }

  .show-Subscribe-btn {
    width: 239px;
  }

  .podcast-sec-right1 {
    height: 500px;
    width: 500px;
  }

  #set-watchlist-row .col-1-5 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  #episode-watchlist {
    margin-top: 2px;
  }

  #view-all-movie {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  #viewall-images {
    width: 100%;
  }

  #watchlist-img-set {
    width: 100%;
  }

  #view-all-podcast {
    flex: 0 0 14.2%;
    max-width: 14.2%;
  }

  .watchlist-container .epi-desc.p-3 {
    padding: 8px 16px !important;
    width: 100%;
  }

  #view-all-movie1 {
    flex: 0 0 19.666667%;
    max-width: 19.666667%;
  }
}

@media only screen and (max-width: 1400px) {
  .d-ratings-and-season {
    padding-bottom: 0;
  }

  .ratings span {
    font-size: 17px;
    line-height: 22px;
    padding-top: 7px;
  }

  .d-season {
    font-size: 17px;
    line-height: 22px;
    padding-top: 28px;
  }

  .comment-review-right {
    height: 1480px;
  }

  .comment-scrolbar {
    height: 1368px;
  }

  #watchlist-episodename {
    margin-bottom: 12px !important;
  }

  #episode-watchlist {
    margin-top: 4px;
  }

  #watchlist-season {
    font-size: 16px;
  }

  #view-all-podcast {
    flex: 0 0 16.2%;
    max-width: 16.2%;
  }

  .portalApp-live-one {
    margin-top: -100px;
  }

  .portalApp-live-img {
    padding-right: 44px;
  }

  .portalApp-live-content {
    width: 800px;
  }

  .portalApp-live-one .portalApp-live-content {
    width: 740px;
  }

  .portalApp-live-two .portalApp-live-img {
    padding-right: 48px;
  }

  .portalApp-live-two .portalApp-live-content {
    width: 689px;
  }
}

@media only screen and (max-width: 1366px) {
  .para-window {
    width: 90% !important;
  }

  .ratings span {
    font-size: 11px;
    line-height: 18px;
  }

  .d-season {
    font-size: 11px;
    line-height: 18px;
    padding-top: 27px;
  }

  .d-set-featured-video,
  .d-set-featured-channel-photo {
    padding-top: 200px;
  }

  .ratings span {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 1360px) {
  .comment-sec-new {
    padding-left: 12px;
  }

  .comment-para p {
    width: 100%;
  }

  #comment-para p {
    width: 100%;
  }

  .comment-para {
    padding-left: 0px;
    margin-top: 10px;
  }

  .review-report1 {
    padding-top: 10px;
  }

  .comment-new-sec {
    padding-top: 10px;
  }

  .comment-review-right {
    height: 1364px;
  }

  .comment-scrolbar {
    height: 1252px;
  }

  .podcast-text {
    font-size: 55px;
    line-height: 64px;
  }

  .set-btns-now {
    margin-right: 22px;
  }

  #view-all-podcast {
    flex: 0 0 16.2%;
    max-width: 16.2%;
  }

  .ticket-add {
    width: 87%;
  }

  .buy-ticket-card {
    width: 70%;
  }

  .buy-ticket-payment {
    width: 30%;
  }

  .buy-ticket-make {
    width: 90%;
  }

  .portalApp-live-one {
    margin-top: 0px;
  }

  .portalApp-live-two {
    margin-top: 30px;
  }

  .portalApp-live-img {
    width: 50%;
    padding-right: 0;
  }

  .portalApp-live-content {
    padding-right: 24px;
    width: 50%;
  }

  .portalApp-live-one .portalApp-live-content {
    width: 50%;
    padding-left: 24px;
  }

  .app2-icon {
    height: auto;
    width: auto;
  }

  .app1-icon {
    height: auto;
    width: auto;
  }

  .app3-icon-web {
    height: auto;
    width: auto;
  }

  .portalApp-live-two .portalApp-live-content {
    width: 50%;
  }

  .portal-accountinfo {
    width: 90%;
  }

  .account-profileinfo {
    width: 90%;
  }

  .portal-checkout {
    width: 90%;
  }

  .create-return-right {
    width: 100%;
  }

  .create-return-left {
    width: 100%;
  }

  .event-left-bgcontent {
    width: 500px;
  }

  .event-up-bgcontent {
    width: 500px;
  }

  #event-sale-content .event-up-bgcontent {
    width: 500px;
  }

  #event-sale-content1 .event-left-bgcontent {
    width: 500px;
  }

  .portal-accountcreation {
    width: 90%;
  }

  .portal-accountcreation-right {
    width: 30%;
    margin-left: 4%;
    padding: 16px 20px 0;
  }

  .portal-accountcreation-left {
    width: 65%;
    margin-right: 0;
  }

  .portal-accountcreation-left .form-group {
    width: 100%;
  }

  .portal-accountcreation-left #address-form {
    width: 97%;
  }

  .portal-accountcreation-left .createacoount-form .create-address {
    width: 100%;
  }

  .portal-accountcreation-left a.nav-item.nav-link {
    width: 50%;
  }

  .portal-accountcreation-left a.nav-item.nav-link.active {
    width: 50%;
  }

  .portal-accountcreation-left .createacoount-form #accept-terms1 {
    width: 100%;
  }

  /* .portal-accountcreation-left .createacoount-form .create-input {
    width: 94%;
  }

  .portal-accountcreation-left .createacoount-form .create-signin {
    width: 94%;
  } */

  .home-image {
    margin-top: -204px;
  }

  /* 
  .footer-portal {
    padding: 500px 18px 23px 18px;
  } */

  .footer-portalApp-menu p {
    font-size: 15px;
    line-height: 27px;
  }

  .portal-accountcreation-left .createacoount-form {
    justify-content: unset;
  }

  .selectTicket-transfer {
    width: 95%;
    margin: 40px auto;
  }

  .our-sponsors {
    width: 99%;
  }

  .support-section {
    width: 99%;
  }

  .footer-portal {
    padding: 35px 40px 101px 40px;
  }

  .portals-network-container {
    width: 90%;
  }

  .types-ofportals {
    gap: 20px;
    justify-content: center;
  }

  .types-ofportals img {
    width: 350px;
  }

  .portals-network {
    background-size: contain;
  }

  .portal-contactinfo {
    width: 90%;
  }
}

@media only screen and (max-width: 1200px) {
  .d-set-next {
    right: -23px !important;
  }

  .d-set-prev {
    left: -23px !important;
  }

  #tech-section {
    width: 90%;
    margin: 0 auto;
  }

  .tech-video {
    height: 285px;
    border-radius: 30px;
    width: auto !important;
  }

  #slider-ratting {
    margin-right: 6px !important;
  }

  .d-season {
    font-size: 12px;
    line-height: 18px;
    padding-top: 16px;
  }

  .ratings span {
    font-size: 12px;
    line-height: 18px;
  }

  .d-ratings-and-season {
    display: block !important;
    padding-bottom: 10px;
  }

  .d-season1-series {
    font-size: 50px;
  }

  .d-set-featured-video,
  .d-set-featured-channel-photo {
    padding-top: 228px;
  }

  .world-edu-video {
    object-fit: cover !important;
    width: 100% !important;
    height: 438px !important;
  }

  .world-edu-event {
    height: auto !important;
    width: 100% !important;
  }

  .show-movie-tabs {
    padding: 40px 50px;
  }

  .view-movie-details {
    left: 40px;
    top: 30px;
  }

  .m-set-prev {
    left: -39px !important;
  }

  .m-set-next {
    right: -39px !important;
  }

  .sm-set-prev {
    left: -39px !important;
  }

  .sm-set-next {
    right: -39px !important;
  }

  .p-set-prev {
    left: -39px !important;
  }

  .p-set-next {
    right: -16.5% !important;
  }

  .movie-bgimg {
    height: 504px;
  }

  #view-series-details {
    top: 12px;
  }

  .main-content.extra-tabs-set {
    width: 88%;
  }

  video.movie-bgvideo {
    height: 504px;
  }

  .world-event-stock {
    width: 97%;
  }

  .set-section-stock {
    padding-right: 12px;
  }

  .set-section-stock1 {
    padding-right: 12px;
  }

  .show-podcast-tabs {
    padding: 40px 50px;
  }

  .podcast-footer {
    padding: 40px 50px;
  }

  .podcast-episodes {
    padding-top: 15px;
  }

  .set-btns-now {
    padding-top: 40px;
    display: block;
  }

  .podcast-text {
    font-size: 40px;
    line-height: 50px;
  }

  .podcast-para {
    font-size: 20px;
    line-height: 30px;
  }

  .show-Subscribe-btn {
    margin-left: 0;
    margin-top: 19px;
  }

  .b-set-prev {
    left: -23px !important;
  }

  .b-set-next {
    right: -23px !important;
  }

  #set-watchlist-row .col-1-5 {
    flex: 0 0 33.33% !important;
    max-width: 33.33% !important;
  }

  .watchlist-container {
    padding: 40px 50px;
  }

  #view-all-movie {
    flex: 0 0 19.666667%;
    max-width: 19.666667%;
  }

  #view-all-podcast {
    flex: 0 0 19.666667%;
    max-width: 19.666667%;
  }

  #view-all-movie1 {
    flex: 0 0 24.666667%;
    max-width: 24.666667%;
  }

  .video-mobile {
    padding: 0 12px !important;
  }

  .footer-podscast {
    height: 437px;
  }

  .event-left-bgcontent p {
    font-size: 50px;
    line-height: 59px;
  }

  .bottom-img {
    top: auto;
    position: unset;
    text-align: right;
  }

  /* .portalApp-live {
        margin-top: 40px;
    } */

  .header-lineup-text h4 {
    padding-left: 36px;
    padding-right: 38px;
  }

  .header-lineup {
    padding: 0 25px;
  }

  .tickets-icon {
    margin-right: 24px;
  }

  .applogonew {
    width: 227px;
  }

  .event-sale-bgcontent {
    background-size: 100vw 454px;
  }

  .bottom-getticktes {
    padding-bottom: 0px;
  }

  .robot-icon {
    margin-right: 0px;
  }

  #mob-left-bg p {
    line-height: 59px;
  }

  #mob-left-bg1 p {
    line-height: 59px;
  }

  #robot-icon {
    /* text-align: end; */
    display: initial;
  }

  .robot-icon {
    display: none;
  }

  .portal-about .WEI-txt {
    width: 80% !important;
  }

  .d-set-next {
    right: -41px !important;
  }

  .d-set-prev {
    left: -45px !important;
  }

  .b-set-next {
    right: -41px !important;
  }

  .b-set-prev {
    left: -45px !important;
  }

  /* .portal-accountcreation-left a.nav-item.nav-link.active {
    width: 30%;
  }

  .portal-accountcreation-left a.nav-item.nav-link {
    width: 30%;
  } */

  .portal-accountcreation-left .createacoount-form .create-signin {
    width: 100%;
  }

  .portal-accountcreation-left .createacoount-form .create-input {
    width: 100%;
  }

  .portal-accountcreation-left .progress-accountcretaion {
    width: 100%;
  }

  .portal-accountcreation-left .createacoount-form p {
    width: 50%;
  }

  .portal-accountcreation-left .createacoount-form #zipcode .find-zipcode {
    width: 23%;
  }

  .portal-accountcreation-left .createacoount-form .create-zipcode {
    width: 70%;
  }

  #payment-selection .credit-card-info {
    width: 100%;
  }

  #payment-selection .paypal-method {
    width: 100%;
  }

  #payment-selection .credit-cardinfos #card-img {
    width: 100%;
  }

  #payment-selection .credit-cardinfos .create-card {
    width: 100%;
  }

  #payment-selection .credit-cardinfos .cvvcode {
    width: 97%;
  }

  #payment-selection .credit-cardinfos .date {
    width: 97%;
  }

  #payment-selection .credit-cardinfos .create-name {
    width: 97%;
  }

  #add-cart-modal1 #addtocart-modal {
    width: 97%;
    margin: 0 auto;
  }

  #add-cart-modal1 form {
    width: 100%;
    padding: 20px;
  }

  .soon-text {
    width: 90%;
    font-size: 40px;
    line-height: 47px;
  }

  #event-sale-content1 .event-left-bgcontent p {
    font-size: 50px;
    line-height: 59px;
  }

  .app1-icon {
    height: 519px;
    width: 279px;
  }

  .portal-accountcreation-left .createacoount-form {
    justify-content: unset;
  }

  #order-quantity {
    top: 31px;
    right: 37px;
  }

  #addtocart-modal-account {
    width: 100%;
  }

  .location-img h4 {
    font-size: 70px;
    line-height: 85px;
  }
}

@media only screen and (max-width: 1140px) {
  .comment-review-right {
    height: 1439px;
  }

  .comment-scrolbar {
    height: 1326px;
  }

  .portal-privacypolicy {
    width: 90%;
  }
}

@media only screen and (max-width: 1100px) {
  .channel-registration {
    width: 90%;
    padding-left: 50px;
    padding-right: 50px;
  }

  .set-domain-name {
    width: 100%;
  }

  .network-timeline {
    width: 95%;
  }

  .basic-channel-field {
    width: 100% !important;
  }

  .basic-channel-field1 {
    width: 100% !important;
  }

  .select-channel-subtype {
    width: 100% !important;
  }

  .select-channel-type {
    width: 100% !important;
  }

  .movie-tab-item {
    padding-right: 33px !important;
  }

  .details-action-content p {
    padding-right: 80px;
  }

  .detail-tab-content {
    padding-left: 31px;
  }

  .details-of-movie p {
    width: 272px;
    padding-left: 12px;
  }

  .show-movie-tab {
    padding-left: 31px;
  }

  .back-bottomtext {
    margin-top: 17px;
  }

  .accessticket {
    width: 90%;
  }

  .accessticket-banner {
    padding: 78px 43px 34px 0;
    margin-right: 43px;
  }
}

@media only screen and (max-width: 1000px) {
  .comment-review-right {
    height: 1614px;
  }

  .comment-scrolbar {
    height: 1501px;
  }

  #set-privacy-policy {
    width: 94%;
  }

  .event-sale-bgcontent {
    background-size: cover;
  }

  .event-left-bgcontent {
    width: 400px;
  }

  .event-up-bgcontent {
    width: 400px;
  }

  #event-sale-content .event-up-bgcontent {
    width: 400px;
  }

  #event-sale-content1 .event-left-bgcontent {
    width: 400px;
  }

  #home .swiper-button i {
    display: block !important;
  }

  .sponsor-tooltip {
    margin-left: 0;
    margin-bottom: -10px;
    height: 117px;
    width: 114px;
  }

  .sponsor-tooltip-content {
    padding: 10px 6px 29px 4px;
    font-size: 12px;
    line-height: 15px;
  }
}

@media only screen and (max-width: 991px) {

  .event-banner-text {
    font-size: 38px;
  }

  .event-banner-text span {
    font-size: 38px;
  }

  .event-name-banner {
    padding: 1rem 10px;
  }

  .stripe-payment-info {
    display: block;
    margin-top: 22px;
    padding: 0 10px;
  }

  .stripe-payment-subtotal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .stripe-payment-subtotal h1 {
    color: #585858;
    font-family: "Avenir";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .portal-accountcreation-left #Cust-Info .stripe-payment-subtotal h1 {
    color: #585858;
    font-family: "Avenir";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    padding-bottom: 0px;
    display: unset !important;
  }

  .stripe-payment-subtotal h2 {
    color: #585858;
    font-family: "Avenir";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: right;
  }

  .stripe-payment-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid #c8d2da;
    padding-top: 7px;
  }

  .stripe-payment-total h1 {
    color: #1b2125;
    font-family: "Avenir";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    display: unset !important;
  }

  .stripe-payment-total h2 {
    color: #1b2125;
    font-family: "Avenir";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: right;
  }

  #ticketPolicyModal {
    max-width: 90% !important;
  }

  .edu-initi {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    padding-top: 4px;
    margin-bottom: 8px !important;
  }

  /* #home-slider .slick-bg {
    height: auto !important;
  } */

  .d-video-banner {
    position: unset;
  }

  .tech-video {
    height: 185px;
    width: 310px !important;
    margin: 0 auto;
  }

  .tech-borders {
    text-align: right;
  }

  .d-season {
    font-size: 12px;
    line-height: 18px;
    padding-top: 10px !important;
  }

  .ratings span {
    font-size: 12px;
    line-height: 18px;
  }

  #slider-ratting {
    margin-right: 5px !important;
  }

  .events-title {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin-bottom: 0;
  }

  .d-block-description {
    top: -84px;
  }

  .trending-content {
    padding: 0px 35px;
  }

  .d-season1-series {
    font-size: 68px;
  }

  .d-set-featured-video,
  .d-set-featured-channel-photo {
    padding-top: 249px;
  }

  .network-timeline {
    width: 94%;
  }

  .set-domain-name {
    width: 100%;
  }

  .movie-tab-item {
    font-size: 21px;
    line-height: 28px;
    padding-right: 30px !important;
  }

  .details-of-movie h4 {
    width: 90px;
  }

  .details-of-movie p {
    width: 199px;
    padding-left: 12px;
  }

  .details-subtitle h4 {
    font-size: 16px;
    line-height: 29px;
  }

  .details-subtitle p {
    font-size: 16px;
    line-height: 29px;
  }

  .details-action-content p {
    padding-right: 40px;
  }

  .show-subtitle p {
    font-size: 16px;
    line-height: 29px;
  }

  .show-subtitle h4 {
    font-size: 16px;
    line-height: 29px;
  }

  .show-movie-tab p {
    width: 86%;
  }

  .set-progress-bg {
    width: 136px !important;
  }

  .write-review-btn {
    width: 100%;
  }

  .review-avg-rating {
    padding-left: 13px;
  }

  .sorted-top-review p {
    font-size: 16px;
  }

  .review-tab-description {
    margin-top: 21px;
    padding: 18px;
  }

  .verified-ratings {
    display: block;
  }

  .verified-ratings p {
    padding-left: 0;
    padding-top: 5px;
  }

  .view-tab p {
    padding-left: 0;
    padding-top: 5px;
  }

  .view-tab {
    display: block;
  }

  .movie-bgimg {
    height: auto;
  }

  .view-movie-details {
    margin-left: 40px;
    position: unset;
    margin-top: 10px;
  }

  .show-movie-details {
    padding-top: 60px;
  }

  .comment-review-right {
    display: none;
  }

  .comment-review-left {
    margin-right: 0px;
  }

  .review-rating-calc p {
    padding-left: 8px;
  }

  #series-season {
    padding-right: 30px;
  }

  #pills-season-tab {
    padding-right: 10px !important;
  }

  .movie-tab-item span {
    font-size: 21px;
    line-height: 28px;
  }

  .main-content.extra-tabs-set {
    width: 80%;
  }

  .remaining-photo {
    width: 17%;
  }

  .p-set-next {
    right: -29.5% !important;
  }

  video.movie-bgvideo {
    height: auto;
  }

  .iq-main-slider {
    padding-top: 77px !important;
  }

  footer .footer-standard .widget ul li {
    padding-right: 60px;
  }

  #footer-container {
    width: 90% !important;
  }

  header .navbar ul li a,
  header .navbar ul li.menu-item a {
    text-align: center !important;
  }

  .set-profile-info h6 {
    font-size: 14px;
    line-height: 18px;
  }

  .show-podcast-details {
    padding-top: 60px;
  }

  .podcast-sec {
    flex-direction: column-reverse;
  }

  .podcast-text {
    font-size: 35px;
    line-height: 40px;
    width: 100%;
  }

  .podcast-para {
    font-size: 20px;
    line-height: 30px;
    width: 100%;
    padding-top: 15px;
  }

  .podcast-center-right {
    margin-left: 50px;
  }

  .set-btns-now {
    padding-top: 35px;
  }

  .podcast-sec-left {
    padding-top: 30px;
  }

  .podcast-footer-list1 img {
    width: 75px;
    height: auto;
  }

  .podcast-sec-left {
    width: 100%;
  }

  .podcast-sec-right {
    text-align: left;
    width: 100%;
  }

  .show-Listen-btn {
    width: 321px;
  }

  .show-Subscribe-btn {
    width: 321px;
    margin-left: 17px;
    margin-top: 0;
  }

  .set-btns-now {
    display: flex;
  }

  .set-play-img {
    display: none;
  }

  .set-btns-now {
    margin-right: 0px;
  }

  .podcast-sec-right1 {
    height: 500px;
    width: 500px;
    border-radius: 12px;
  }

  #header-container {
    padding: 0 45px;
  }

  /* #d-header {
        height: auto !important;
    } */

  .main-header {
    padding: 0 !important;
  }

  .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    background: #003087 !important;
  }

  .footer-podscast {
    height: 460px;
  }

  #viewall-row {
    padding: 0 !important;
  }

  #view-all-movie {
    flex: 0 0 24.666667%;
    max-width: 24.666667%;
  }

  #view-all-podcast {
    flex: 0 0 20%;
    max-width: 20%;
  }

  #view-all-movie1 {
    flex: 0 0 32.666667%;
    max-width: 32.666667%;
  }

  div#right-sidetoggle {
    display: flex !important;
  }

  .buy-ticket-payment {
    width: 40%;
  }

  .buy-ticket-card {
    width: 60%;
    padding: 20px 10px;
  }

  .portalApp-live {
    flex-direction: column;
    padding-left: 0;
  }

  .portalApp-live-content {
    padding-right: 23px;
    width: 100%;
    padding-left: 27px;
  }

  .portalApp-live-img {
    text-align: center;
    width: 100%;
  }

  .portalApp-live-one {
    flex-direction: column-reverse;
  }

  .portalApp-live-one .portalApp-live-content {
    padding-right: 23px;
    width: 100%;
    padding-left: 27px;
    text-align: left;
  }

  .portalApp-live-two .portalApp-live-img {
    padding-right: 0px;
  }

  .portalApp-live-two .portalApp-live-content {
    width: 100%;
  }

  .portalApp-live-two {
    margin-top: 30px;
    flex-direction: column;
  }

  .footer-portalApp-about {
    padding-top: 50px;
    padding-bottom: 25px;
  }

  .events-line {
    display: none;
  }

  .footer-portalApp-menu {
    display: none;
  }

  /* .footer-portal {
    padding: 348px 14px 6px 22px;
    justify-content: center;
  } */

  /* .portalApp-footer-img p {
    font-size: 10px;
    line-height: 11px;
    padding-top: 14px;
    text-align: left;
  } */

  .portal-about .r-mb-23 {
    margin-bottom: 0px !important;
  }

  .create-return {
    flex-direction: column;
    padding-top: 30px;
  }

  .create-return .divide-line {
    display: none;
  }

  .portal-checkout .checkout-returncart {
    display: none;
  }

  .portal-checkout {
    padding-top: 35px;
    padding-bottom: 151px;
  }

  .checkout-white-bg {
    margin-top: 0px;
  }

  .portal-checkout h4 {
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 6px;
    border-bottom: 1px solid #3a3a3a;
  }

  .create-return h5 {
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 0px;
    border-bottom: unset;
  }

  .create-return .Create-Account {
    margin-top: 30px;
  }

  .create-return-right {
    margin-top: 44px;
    border-top: 1px solid #3a3a3a;
    padding-top: 27px;
  }

  .create-return .create-return-form {
    padding-top: 23px;
  }

  .create-return .create-return-right h6 {
    padding-top: 42px;
    width: 155px;
  }

  .portal-accountcreation-right {
    display: none;
  }

  #addtocart-modal .portal-accountcreation-right {
    display: block;
  }

  .portal-accountcreation-left {
    width: 100%;
  }

  .tickets-btn {
    display: none;
  }

  #togglemenu-mobile {
    display: block;
    text-align: center;
    padding: 35px 0;
    background: #000;
  }

  #togglemenu-web .header-lineup-text {
    display: none;
  }

  .header-myaccount {
    display: none;
  }

  .applogonew {
    height: 94px;
    width: 137px;
  }

  .header-lineup-text p {
    font-size: 24px;
    line-height: 26px;
    text-transform: uppercase;
    padding-right: 24px;
    padding-left: 25px;
    padding-top: 15px;
    font-weight: 300;
  }

  .portal-accountcreation {
    margin: 45px auto;
  }

  .space-header-manu {
    padding-top: 45px;
  }

  .header-lineup-text h4 {
    font-size: 20px;
    line-height: 26px;
    text-transform: uppercase;
    padding-right: 24px;
    padding-left: 25px;
    padding-top: 15px;
    font-weight: 300;
  }

  .app-header .header-toggle-drawer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 22px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 13px;
    height: 69px;
    /* border-bottom: 1px solid; */
  }

  .artists-guesttext p {
    font-size: 32px;
    line-height: 40px;
    height: auto;
  }

  .smokebg {
    padding-bottom: 50px;
  }

  .header-toggle-drawer .applogo-web {
    height: 77px !important;
    width: 79px !important;
  }

  .header-lineup .applogo-web {
    display: none;
  }

  .header-lineup .header-tickets-img {
    display: none;
  }

  .tickets-icon {
    margin-right: 5px;
    height: 46px;
    width: 45px;
    margin-bottom: 0px;
  }

  .header-tickets-img {
    padding-top: 0px;
  }

  .header-lineup {
    display: none;
    padding: 0px !important;
  }

  img.drawerclose-icon {
    height: 34.38px;
    width: 34.38px;
    position: absolute;
    left: 20px;
    cursor: pointer;
  }

  #order-quantity {
    height: 16px;
    min-width: 16px;
    font-size: 12px;
    line-height: 16px;
    top: -20px;
    left: -48px;
  }

  .app-header {
    background: #0f172a;
  }

  .footer-portal .portalApp-footer-img {
    position: unset;
  }

  .portal-about h1.slider-text.text-uppercase {
    padding-top: 0px;
  }

  .portalApp-live-two {
    padding-bottom: 0px;
    margin-left: 0px;
  }

  .app3-icon-web {
    display: none;
  }

  .app3-icon-mob {
    display: block;
    height: 519px;
    width: 279px;
    margin: 0 auto 32px auto;
  }

  .portal-location .location-image {
    height: 322.33px;
    width: 360.19px;
  }

  #robot-icon {
    display: none;
  }

  /* .bottom-getticktes {
    padding-bottom: 98px;
  } */

  .app-bgimage.lineup-bg-video {
    background-position: bottom, 0 102px, top;
  }

  .portal-faq {
    width: 90%;
  }

  .app-confirmation {
    width: 90%;
  }

  .app-confirmation .confirm-border {
    width: 90%;
  }

  .home-image {
    margin-top: -75px;
  }

  .portalApp-about {
    width: 90%;
  }

  .portalApp-about span {
    font-size: 22px;
    line-height: 36px;
  }

  .portalApp-about p {
    font-size: 22px;
    line-height: 36px;
    padding-top: 64px;
  }

  .portal-about {
    padding-top: 64px;
  }

  .Your-Tickets {
    width: 90%;
  }

  #forgotpassword .create-return-right {
    border: none;
    margin-top: 0px;
  }

  div#set-header-line {
    border-top: unset;
    margin-bottom: 0px;
    padding-top: 0;
  }

  #iq-main-slider {
    padding-top: 0px !important;
  }

  .bottom-getticktes.btn-tickets-home {
    padding-bottom: 30px;
  }

  .site-message p {
    font-size: 20px;
    line-height: 25px;
    padding: 10px;
  }

  .reservenow-text {
    padding-top: 0px;
  }

  .reservenow-text p {
    font-size: 22px;
    padding: 0 10px;
  }

  .addciright-logo p {
    display: block;
    width: 170px;
  }

  .addciright-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 14px 22px;
  }

  /* .portalApp-footer-img {
    text-align: left;
  } */

  .footer-portal .footer-portalApp-socialmedia p {
    font-size: 16px;
    line-height: 17px;
    padding-top: 3px;
  }

  .insta-footer {
    margin-right: 17px !important;
  }

  .accessticket {
    width: 90%;
    flex-direction: column;
    align-items: center;
    padding-top: 21px;
    padding-bottom: 42px;
  }

  .accessticket-banner {
    padding: 0;
    margin-right: 0;
    border: unset;
  }

  .access-ticketnew .access-ticket {
    flex-direction: column;
  }

  .access-ticketnew .access-ticket {
    padding-top: 20px;
  }

  .access-ticketnew .access-ticket .Create-Account {
    margin: 10px;
  }

  .access-ticketnew .accessticket {
    padding: 40px 0;
  }

  .new-home {
    margin-top: 19px;
  }

  .location-img h4 {
    font-size: 45px;
    line-height: 56px;
    padding: 0 10px;
  }

  .concert-img h4 {
    font-size: 45px;
    line-height: 57px;
    letter-spacing: 1.34px;
  }

  .newhome-concert h5 {
    font-size: 45px;
    line-height: 57px;
    letter-spacing: 1.34px;
    padding-top: 13px;
  }

  .newhome-concert h6 {
    font-size: 32px;
    line-height: 57px;
    letter-spacing: 0.95px;
    padding-top: 10px;
  }

  .portals-logo {
    margin: 0px 0 0px 0;
    height: 26px;
    width: 206px;
  }

  .width-logospace {
    width: 28px;
  }

  .header-toggle-icon {
    flex: 1 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .footer-portal {
    padding: 35px 20px 101px 20px;
  }

  #payment-selection {
    padding-top: 13px;
  }

  .portal-accountcreation-left #payment-selection p {
    font-size: 22px;
  }

  .order-mainsection {
    width: 98%;
    margin: 0 auto;
  }

  .portals-network {
    background-image: none;
  }

  .footer-portal {
    padding: 0px 0px 101px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .portals-network-container {
    padding-bottom: 0px;
  }

  .footer-portal .footer--menu {
    display: flex;
    width: 100%;
    text-align: center;
    margin-bottom: 69px;
    justify-content: center;
    background-image: url("../../assets/images/Portal/mobile-bgimg.png");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height: 292px;
  }

  .footer-zazafestival-menu {
    margin-top: 77px;
  }

  .portal-sponsers-text {
    margin-bottom: 20px;
  }

  .conatct-portal-bottom-img {
    display: none;
  }

}

@media only screen and (max-width: 840px) {
  /* .d-trending-info {
        display: none;
    } */

  .trending-button {
    display: block !important;
  }

  .series-btn {
    font-size: 15px;
    font-weight: 700;
    line-height: 33px;
    height: 47px;
    width: 141px;
    /* margin-top: 90% !important; */
  }

  /* .d-season1-series {
    .series-btn1 {
        font-size: 15px;
        font-weight: 700;
        line-height: 33px;
        height: 47px;
        width: 141px;
    }

    .d-season1-series {
        display: none !important;
    } */
  .d-season1-series {
    font-size: 55px;
  }

  .d-tvchannel-img {
    width: 346px !important;
    height: 230px;
  }

  #view-all-podcast {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .event-left-bgcontent {
    width: 300px;
  }

  .event-up-bgcontent {
    width: 300px;
  }

  #event-sale-content .event-up-bgcontent {
    width: 300px;
  }

  #event-sale-content1 .event-left-bgcontent {
    width: 300px;
  }

  .our-sponsors {
    padding-top: 67px;
  }

  .our-sponsors h1 {
    font-size: 24px;
    padding-bottom: 23px;
  }

  .brand-logo-box {
    height: 76px;
    width: 110px;
    padding: 5px 0;
    margin: 0 8.5px 17px 8.5px;
  }

  .brand-section p {
    font-size: 12px;
    line-height: 14px;
    margin-top: 5px;
  }

  .brand-logo-box .brand-logoimg {
    height: 42px;
    width: 44px;
  }

  .our-sponsors .support-of {
    padding-bottom: 15px;
    padding-top: 17px;
  }

  .support-logo-box {
    height: 82px;
    width: 111px;
    padding-top: 27px;
    padding-bottom: 22px;
    margin: 0 8px 15px 8px;
  }

  .support-logoimg {
    height: 29px;
    width: 61px;
  }

  #portals-ticketcard {
    width: 100%;
  }

  #portals-ticketcard .ticket-info {
    height: auto;
  }

  .portal-contactinfo {
    width: 90%;
    flex-direction: column-reverse;
    align-items: center;
  }

  .contact-form-section {
    width: 100%;
  }

  .contact-form-section form {
    min-width: 100%;
  }

  .contact-imgsection {
    width: auto;
  }
}

@media only screen and (max-width: 767px) {
  .d-appbgimg {
    background-size: 70% !important;
  }

  .d-title-feature {
    font-size: 40px;
    line-height: 50px;
  }

  .parallax-window {
    padding: 24px 0 32px 0;
  }

  .exclusive-event {
    height: 410px !important;
  }

  .event-video {
    height: 410px !important;
  }

  #d-appbgimg {
    background-image: none !important;
  }

  /* div#d-container\ main-content {
        padding-left: 0;
        padding-right: 0;
    } */

  .app-technologies {
    padding-bottom: 62px;
    padding-top: 18px;
  }

  .detail-tab-content {
    padding-left: 31px;
  }

  .main-content {
    padding-top: 28px !important;
  }

  #set-brand-con {
    display: block !important;
  }

  .show-movie-tab {
    padding-left: 31px;
  }

  .tech-borders {
    text-align: center !important;
  }

  .tech-videos {
    text-align: center;
  }

  /* #series-rating {
        height: 10.1118803024292px;
        width: 10.1118803024292px;
        background: linear-gradient(0deg, #2DD0FF, #2DD0FF),
            linear-gradient(180deg, #2DD0FF 0%, #1891B4 100%);
        border: 0.3px solid #2DD0FF
    } */

  /* .d-title-feature-2 {
        padding-top: 134px !important;
    } */

  /* #series-rating-mob {
        display: block;
    } */

  /* #series-rating {
        display: none;
    } */

  .ratings span {
    letter-spacing: 0.3px;
    font-size: 10px;
    padding-top: 0 !important;
  }

  .d-season {
    letter-spacing: 0.3px;
    font-size: 11px;
    /* line-height: 11px; */
    padding-top: 8px;
  }

  .tranding-block2 {
    height: 410px;
  }

  .tranding-block2 .d-set-featured {
    padding-top: 200px;
  }

  /* .series-btn {
        margin-top: 86% !important;
    } */

  .d-set-prev {
    left: 15px !important;
  }

  .d-set-next {
    right: 15px !important;
  }

  .d-trending-info {
    padding: 50px 0 0 0;
  }

  .d-season1-series {
    font-size: 35px;
  }

  .d-title-txt-one {
    font-size: 22px;
  }

  .d-txt-one {
    font-size: 12px;
    line-height: 19px;
  }

  .d-set-featured-video,
  .d-set-featured-channel-photo {
    padding-top: 302px;
  }

  .events-title {
    width: 70%;
    margin: 0 auto;
  }

  .d-parallax-window {
    padding: 0;
  }

  .d-para-window {
    padding-top: 20px;
    padding-bottom: 22px;
  }

  .d-parallax-img {
    width: 350px;
    margin-top: 25px;
  }

  .timeline-text {
    font-size: 15px;
    line-height: 18px;
    top: 33px;
  }

  .show-movie-tabs {
    padding: 28px 15px 41px 15px;
  }

  .view-movie-details {
    margin-left: 9px;
    margin-top: 6px;
    margin-left: 15px;
    margin-right: 15px;
  }

  #details-subtitle-mob {
    display: block;
    padding-top: 10px;
  }

  #details-subtitle-mob p {
    text-align: center;
  }

  #details-subtitle-mob h4 {
    text-align: center;
  }

  #details-subtitle {
    display: none;
  }

  .details-share-mob {
    display: block;
  }

  .show-tab-details {
    display: block;
  }

  .details-action-content p {
    overflow: unset;
    text-overflow: unset;
    display: unset;
    padding-right: 0;
    font-size: 14px;
    line-height: 31px;
  }

  .detail-tab-content {
    padding-left: 19px;
  }

  .show-detail-tab {
    margin-right: 13px;
    float: left;
  }

  .detail-tab-content {
    padding-left: 12px;
  }

  .show-tabs-icon {
    display: none;
  }

  .details-contents-button-mob {
    margin-top: 11px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .details-contents-button {
    display: none;
  }

  .drama-btn {
    margin-left: 0px;
    margin-top: 21px;
  }

  .comedy-btn {
    margin-left: 0px;
    margin-top: 21px;
  }

  .top-cast {
    clear: left;
    padding-top: 5px;
  }

  .m-set-prev {
    left: -2px !important;
  }

  .m-set-next {
    right: 2px !important;
  }

  .sm-set-next {
    right: -3px !important;
  }

  .sm-set-prev {
    left: -3px !important;
  }

  .movie-tab-item {
    font-size: 17px;
    line-height: 31px;
  }

  .details-of-movie h4 {
    width: 116px;
  }

  .details-of-movie p {
    width: 100%;
    padding-left: 0;
    overflow: unset;
    text-overflow: unset;
    display: unset;
    white-space: unset;
  }

  .movie-starring-content p {
    width: auto;
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
  }

  .movie-starrings p {
    width: auto;
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
  }

  .movie-description p {
    width: auto;
    font-size: 14px;
    padding-top: 10px;
  }

  .show-detailtab-img {
    height: 217px;
    width: 147px;
  }

  .movies-reviews {
    display: none;
  }

  .show-like-details {
    padding-top: 13px;
  }

  .movie-name {
    font-size: 45px;
    line-height: 49px;
  }

  .show-episode {
    height: 45px;
    width: 123px;
    background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
    border: 2px solid #2dd0ff;
    box-shadow: 0px 4px 10px 2px #00000080;
    font-size: 13px;
    line-height: 19px;
    border: none;
  }

  .show-episode span {
    font-size: 12px;
  }

  .show-rent {
    height: 45px;
    width: 100px;
    font-size: 13px;
    margin-left: 8px;
    background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
    border: none;
  }

  .show-rent span {
    font-size: 13px;
  }

  .show-like-btn {
    margin-left: 8px;
    background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
    border: none;
  }

  .show-watchlist-icon {
    margin-left: 8px;
    background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
    border: none;
  }

  .show-terms p {
    padding-bottom: 10px;
    border-bottom: 1px solid #2dd0ff;
    font-size: 12px;
    text-align: center;
    /* margin-left: -9px !important; */
  }

  .show-dinner-btn {
    display: none;
  }

  .show-mob-btn {
    display: block;
    height: 44px;
    width: 44px;
    border-radius: 10px !important;
    padding: 0 !important;
    background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
    margin-left: 9px;
  }

  .show-episodes-btn {
    display: flex;
  }

  .movie-app-img {
    height: 217px;
    width: 147px;
  }

  .ratings-review {
    display: block;
  }

  .show-ratings-review {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .write-review-btn {
    font-size: 14px;
    height: 45px;
    width: 220px;
    border-radius: 30px !important;
  }

  .review-description {
    padding-left: 0;
    padding-top: 21px;
  }

  .show-count-data {
    padding-bottom: 23px;
  }

  .comments-show {
    flex-direction: column-reverse;
    display: flex;
  }

  .create-comment {
    display: none;
  }

  .create-comment1 {
    display: block;
  }

  .comment-title1 {
    display: block;
    width: 100%;
  }

  .set-comment-title {
    margin-top: 15px;
    font-size: 14px !important;
    line-height: 21px !important;
    height: 96px !important;
    border-radius: 15px !important;
  }

  .create-comment1 p {
    font-size: 17px;
    line-height: 31px;
  }

  .create-comment1 {
    margin-top: 13px;
  }

  .comment-title {
    display: none;
  }

  .set-comment-btn {
    text-align: center;
  }

  .sorted-top-comment p {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    padding-top: 5px;
  }

  .filter-comment p {
    font-size: 14px;
    text-align: center;
    line-height: 21px;
  }

  .filter-comment {
    justify-content: center;
    padding-top: 24px;
    align-items: center;
  }

  .comment-button-visit {
    height: 45px;
    width: 220px;
    border-radius: 30px !important;
    background: linear-gradient(180deg, #f4eca4 0%, #e2aa19 100%);
    border: 1px solid #e2aa19;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }

  .show-comment-sec {
    margin-top: 24px;
    padding: 12px;
    border-radius: 10px;
  }

  .view-tab1 span {
    font-size: 14px;
    line-height: 21px;
  }

  .view-tab1 p {
    font-size: 14px;
    line-height: 21px;
  }

  .review-purchase1 p {
    font-size: 14px;
    line-height: 25px;
    padding-top: 10px;
  }

  .review-purchase1 h6 {
    font-size: 12px;
    line-height: 18px;
    padding-top: 3px;
  }

  .review-report1 {
    padding-top: 3px;
  }

  #set-comment-border {
    padding-bottom: 10px;
  }

  .review-report1 span {
    font-size: 12px;
    line-height: 18px;
  }

  .review-report1 p {
    font-size: 12px;
    line-height: 18px;
    padding-left: 15px;
  }

  .review-comment-img {
    margin-left: 15px;
  }

  .review-share-img {
    margin-left: 15px;
  }

  .comment-sec-new {
    padding-top: 0;
  }

  .comment-para p {
    font-size: 14px;
    line-height: 25px;
  }

  #view-tab1 {
    padding-top: 21px;
  }

  .comment-reply-sec {
    padding-top: 20px;
  }

  .set-reply-title {
    height: 36px !important;
    border-radius: 18px !important;
    font-size: 12px;
    line-height: 18px;
    padding-top: 7px;
    padding-bottom: 0px;
  }

  .like-comment-icon {
    padding-top: 12px;
  }

  div#pills-Comments {
    padding-top: 24px;
  }

  .rating-progressbar p {
    font-size: 14px;
    line-height: 21px;
  }

  .rating-progressbar span {
    font-size: 14px;
    line-height: 21px;
  }

  .set-progress-bg {
    width: 220px !important;
  }

  .sorted-top-review p {
    line-height: 21px;
    font-size: 14px;
    text-align: center;
    padding-top: 8px;
  }

  .filter-review p {
    font-size: 14px;
    line-height: 21px;
  }

  .filter-review {
    justify-content: center;
    align-items: center;
  }

  .comment-vector-img {
    height: 15px;
    width: 15px;
  }

  .show-subtitle {
    display: none;
  }

  .show-time-details-mobile {
    display: block;
  }

  .show-movie-description {
    display: none !important;
  }

  .show-extratab-img {
    height: 75px;
    width: 115px;
    border-radius: 15px;
  }

  .show-terms span {
    font-size: 12px !important;
  }

  .movie-tab-item span {
    font-size: 17px;
    line-height: 31px;
  }

  .series-tabs-icon {
    display: none;
  }

  .filter-comment h6 {
    font-size: 14px;
    line-height: 21px;
  }

  .movie-name p {
    margin: 0 !important;
  }

  .show-star-content {
    padding-top: 4px;
  }

  .movie-extra {
    padding-top: 24px;
    border-bottom: none !important;
    padding-bottom: 0px !important;
    align-items: center;
  }

  .mobile-season {
    border-bottom: 1px solid #616161;
    padding-bottom: 16px;
  }

  .show-season-description {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #bdbdbd;
    padding-top: 5px;
    margin-bottom: 0px;
    width: 100%;
    display: block;
  }

  .photos-extra-img {
    height: 75px;
    width: 115px;
    border-radius: 15px;
  }

  .remaining-photo p {
    font-size: 14px;
    line-height: 31px;
  }

  .p-set-prev {
    top: 19% !important;
    left: -4px !important;
  }

  .p-set-next {
    right: -29.5% !important;
    top: 19% !important;
  }

  .remaining-photo {
    width: 18%;
  }

  .main-content.extra-tabs-set {
    width: 78%;
  }

  .show-home-tabs {
    padding-top: 24px;
  }

  .details-show-movie {
    padding-top: 11px;
  }

  .details-of-movie {
    padding-top: 9px;
  }

  .top-cast p {
    font-size: 17px;
    line-height: 32px;
    padding-bottom: 0px;
    padding-top: 18px;
  }

  .show-cast-agency {
    display: none;
  }

  .show-img-boxes h4 {
    font-size: 14px;
    line-height: 26px;
    padding-top: 7px;
  }

  .top-cast .show-img-boxes p {
    font-size: 14px;
    line-height: 19px;
  }

  .details-of-movie h4 {
    font-size: 14px;
    line-height: 31px;
  }

  .details-of-movie p {
    font-size: 13px;
    line-height: 19px;
  }

  .extra-photo {
    font-size: 17px;
    line-height: 31px;
    padding-bottom: 0px;
    padding-top: 28px;
  }

  .movies-content {
    padding-top: 28px;
  }

  .movies-content p {
    font-size: 17px;
    line-height: 31px;
    padding-bottom: 0px;
  }

  .filter-review h6 {
    font-size: 14px;
    line-height: 21px;
  }

  .details-moive-btn {
    margin-right: 0px;
    margin-top: 20px;
  }

  img.img-fluid.logo {
    max-width: 102px !important;
    width: 102px !important;
  }

  video.d-video-banner {
    height: 300px !important;
  }

  #home-slider h1.slider-text {
    font-size: 32px;
    padding-top: 4px !important;
    line-height: 40px !important;
  }

  .d-movie-title {
    font-size: 24px !important;
    line-height: 24px !important;
    padding-top: 12px;
  }

  .d-view-text {
    font-size: 14px;
    line-height: 14px;
    padding-right: 16px;
  }

  .set-view-all-text {
    margin-bottom: 16px !important;
  }

  .d-brand-network {
    font-size: 28px;
    line-height: 24px;
  }

  img.img-fluid.d-influ-img {
    height: 192px;
    width: 132px;
  }

  .d-app-img {
    height: 192px;
    width: 132px;
  }

  section#iq-tvthrillers {
    margin-top: 64px !important;
  }

  .event-videos {
    height: 298px !important;
    padding: 0 !important;
  }

  img.d-img1 {
    height: auto !important;
    width: 128px !important;
  }

  .d-img-brand {
    height: auto !important;
    width: 148px !important;
  }

  .d-btn {
    padding: 0 !important;
    height: 40px;
    width: 171px;
    font-size: 16px;
    line-height: 38px;
  }

  #brand-btn {
    margin-top: 28px !important;
  }

  .d-brand-btn {
    padding: 0 !important;
    height: 40px;
    width: 171px;
    font-size: 16px;
    line-height: 38px;
  }

  .main-content.set-main-brand {
    padding-top: 24px !important;
    padding-bottom: 24px;
  }

  .event-video-events {
    height: 298px !important;
  }

  .event-video-series {
    height: 298px !important;
    padding: 0 !important;
  }

  img.count-series-img {
    width: 224px;
    height: 56px;
    margin-bottom: 20px;
  }

  .education-block {
    height: 300px;
    margin-top: 76px;
  }

  .education-block-img {
    height: 300px !important;
  }

  .set-app-footer {
    display: block;
    text-align: center;
  }

  #set-list-footer {
    margin-left: 0px;
    margin-top: 40px;
  }

  footer.footer-one .widget .textwidget p {
    font-size: 14px;
    line-height: 24px;
    padding-top: 28px;
  }

  h1.slider-text.text-uppercase {
    font-size: 28px;
    line-height: 32px;
  }

  .series-listname {
    font-size: 28px;
    line-height: 32px;
  }

  .WEI-txt {
    padding-top: 20px;
    font-size: 16px;
    line-height: 24px;
  }

  #d-series-network {
    margin-top: 64px;
  }

  p.slider-text.d-slide-txt {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 0px;
  }

  #app-mob-text {
    line-height: 16px;
  }

  #footer-container {
    width: 91% !important;
  }

  #footer-container .footer-standard.row {
    margin: 0 !important;
  }

  li.faq-list {
    padding-right: 0 !important;
  }

  .c-logo {
    width: 102px;
    max-width: 102px;
  }

  footer .footer-standard .widget ul li {
    padding-right: 40px;
  }

  .info-share-mob a {
    width: 48px;
    height: 48px;
    font-size: 24px;
    line-height: 44px;
    position: relative;
    display: inline-block;
    text-align: center;
    margin-right: 0px;
    color: #2dd0ff;
    border-radius: 50%;
    background: linear-gradient(180deg,
        rgba(97, 97, 97, 0.75) 0%,
        rgba(0, 0, 0, 0.75) 100%);
    transition: all 0.4s ease 0s;
    border: 2px solid #616161;
    text-decoration: none !important;
  }

  .info-share {
    display: none;
  }

  .info-share-mob {
    margin-top: 36px;
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 100px !important;
    padding: 0 !important;
  }

  footer.footer-one .footer-top {
    padding-top: 48px !important;
    padding-bottom: 30px !important;
  }

  .main-header {
    padding: 20px 0;
  }

  .e-brand-prev {
    top: 55.8% !important;
  }

  .d-set-prev {
    top: 48.1% !important;
  }

  .d-set-next {
    top: 48.1% !important;
  }

  .b-set-next {
    top: 28% !important;
  }

  .b-set-prev {
    top: 28% !important;
  }

  .musicartists-img {
    height: 192px;
    width: 132px;
  }

  .world-event-stock {
    margin: 0 auto;
    display: block;
  }

  .set-section-stock {
    text-align: center;
    padding-right: 0px;
  }

  .set-section-stock1 {
    text-align: center;
    padding-right: 0px;
    padding-top: 24px;
  }

  .set-section-stock2 {
    text-align: center;
    padding-top: 24px;
  }

  .secure-connect {
    font-size: 16px;
    padding-top: 16px;
  }

  .world-stock-img {
    height: 168px;
    width: 300px;
  }

  #series-bg-img .d-btn {
    margin-top: 32px !important;
  }

  #border-set {
    height: 0 !important;
  }

  .world-event-stock {
    padding-top: 32px;
    padding-bottom: 24px;
  }

  .maintenance-phase {
    /* width: 132px; */
    height: 60px;
    padding: 8px 8px 12px 8px !important;
  }

  .podcast-img {
    height: 132px;
    /* width: 132px; */
  }

  p.set-maintenance {
    font-size: 14px;
    line-height: 20px;
  }

  div#series-bg-img {
    height: 390px;
  }

  .show-podcast-tabs {
    padding: 28px 15px 41px 15px;
  }

  .podcast-footer {
    padding: 28px 15px 41px 15px;
  }

  .set-btns-now {
    display: block;
  }

  .podcast-center-left1 {
    height: 75px;
    width: 115px;
  }

  .podcast-center-right {
    margin-left: 17px;
  }

  .podcast-episode-text {
    font-size: 20px;
    line-height: 30px;
  }

  .podcast-episodes {
    padding-top: 7px;
  }

  .podcast-calender-sec p {
    font-size: 12px;
    line-height: 16px;
    padding-left: 8px;
  }

  .podcast-calender-sec img {
    height: 14px;
    width: 14px;
  }

  .podcast-timing-sec img {
    height: 14px;
    width: 14px;
  }

  .podcast-timing-sec p {
    font-size: 12px;
    line-height: 16px;
    padding-left: 8px;
  }

  .podcast-para {
    font-size: 14px;
    line-height: 26px;
    display: unset;
  }

  .show-Subscribe-btn {
    margin-top: 10px;
    margin-left: 0px;
  }

  p.podcast-episode-para {
    display: none;
  }

  p.podcast-episode-para.podcast-episode-para1 {
    display: block;
  }

  .podcast-center {
    justify-content: unset;
    padding-top: 24px;
    border-bottom: none !important;
    padding-bottom: 0px !important;
    align-items: center;
  }

  img.podcast-center-left2 {
    width: 24px;
  }

  button.btn.podcast-center-left2 {
    left: 6px;
    bottom: 0px;
  }

  .podcast-footer-list2 .rhap_main {
    flex-direction: column;
  }

  .podcast-footer-list {
    display: block;
  }

  .podcast-footer-list1 p {
    display: block;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 0 !important;
    text-align: center;
    margin-left: 10px;
  }

  .podcast-footer-list2 p {
    display: none;
  }

  .podcast-footer-list1 {
    display: flex;
    align-items: center;
  }

  .podcast-footer-list2 .rhap_stacked .rhap_controls-section {
    margin-left: 0px;
  }

  .podcast-footer-list2 {
    padding: 0;
    margin-top: 13px;
  }

  .podcast-footer-list2 .rhap_time {
    font-size: 12px !important;
  }

  .podcast-footer-list2 .rhap_main-controls {
    flex: 0 1 auto;
  }

  .podcast-footer-list2 button.rhap_button-clear.rhap_main-controls-button.rhap_rewind-button {
    margin-right: 10px;
  }

  .podcast-footer-list2 button.rhap_button-clear.rhap_main-controls-button.rhap_forward-button {
    margin-left: 0px;
  }

  .podcast-sec-right1 {
    height: auto;
    width: 100%;
  }

  .b-set-next {
    right: 15px !important;
  }

  .b-set-prev {
    left: 15px !important;
  }

  #donate-now {
    line-height: 37px;
  }

  img.d-video-banner {
    height: 300px !important;
  }

  #set-watchlist-row .col-1-5 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .watchlist-container {
    padding: 25px 15px;
  }

  .footer-podscast {
    height: 615px;
  }

  svg.podcast-center-left2 {
    width: 24px;
  }

  #view-all-movie {
    flex: 0 0 32.666667%;
    max-width: 32.666667%;
  }

  h3.text-uppercase.view-all-text-movie {
    font-size: 30px;
  }

  .watchlist-container .epi-desc.p-3 {
    padding: 8px 10px !important;
  }

  #watchlist-episodename {
    font-size: 16px;
    line-height: 22px;
  }

  #set-seconds-hours {
    font-size: 12px;
    line-height: 18px;
  }

  #watchlist-date {
    font-size: 12px;
    line-height: 18px;
  }

  #view-all-movie1 {
    flex: 0 0 32.666667%;
    max-width: 32.666667%;
  }

  /* 
    .header-profile p {
        display: none !important;
    } */

  .video-mobile-back .video-series-title {
    font-size: 16px;
    line-height: 20px;
  }

  .video-mobile-back .video-back {
    width: 25px !important;
    height: 25px !important;
  }

  .video-mobile-back .video-series-episode {
    font-size: 12px;
    line-height: 18px;
  }

  .ticket-add {
    width: 100%;
    margin: 40px auto;
    display: block;
    box-shadow: unset !important;
    border: unset !important;
    border-radius: unset;
  }

  .buy-ticket-payment {
    margin: 0 auto;
    padding: 15px;
  }

  .buy-ticket-make {
    width: 100%;
  }

  .buy-ticket-title {
    margin-bottom: 24px;
  }

  .buy-ticket-description p {
    line-height: 21px;
    padding-right: 5px !important;
  }

  .ticket-payments {
    padding-top: 10px;
  }

  .buy-ticket-card {
    width: 100%;
  }

  .buy-ticket-payment {
    width: 400px;
  }

  .app-event-img {
    display: none;
  }

  #app-event-img {
    display: block;
    width: 400px;
    margin: 0 auto;
  }

  #address-modal {
    margin: 0 auto;
    max-width: 98% !important;
  }

  #address-modal1 {
    margin: 0 auto;
    max-width: 98% !important;
  }

  .payment-form-field {
    width: 100% !important;
  }

  .payment-form-field1 {
    width: 100% !important;
  }

  .card-details-date {
    flex-direction: column;
  }

  #tickets-details {
    width: 94%;
    margin: 0 auto;
  }

  .tickets-address h4 {
    padding-bottom: 31px;
    padding-top: 62px;
  }

  #tickets-details h6 {
    font-size: 24px;
    line-height: 26px;
    padding-bottom: 13.5px;
    text-align: center;
    padding-top: 23px;
  }

  #tickets-details .tickets-toggle img {
    height: 16px;
  }

  #tickets-details .ticketreserve-acc .tickets-toggle img {
    height: 40px;
  }

  #tickets-details .ticketreserve-acc .tickets-toggle .show-more {
    height: 14.56px;
  }

  #tickets-details .card-header {
    padding: 21.5px 8.5px 23.32px 12.5px;
  }

  .tickets-toggle p {
    font-size: 18px;
    line-height: 20px;
    padding-left: 5px;
  }

  .tickets-loc-address {
    width: 86%;
    margin: 0 auto;
    padding-top: 47px;
  }

  #tickets-details .tickets-toggle h4 {
    font-size: 20px;
    line-height: 22px;
  }

  #tickets-details .tickets-amount span {
    display: none;
  }

  #tickets-details .tickets-amount {
    justify-content: center;
  }

  .tickets-loc-address p {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.69px;
    line-height: 26px;
  }

  #tickets-details .tickets-description {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0pc 8.5px 28.5px 31px;
  }

  #tickets-details .tickets-dates {
    width: 100%;
    padding-top: 12px;
    padding-left: 0px;
  }

  #tickets-details .tickets-dates p {
    text-align: left;
    padding-bottom: 6px;
    padding-left: 10px;
  }

  #tickets-details .tickets-addremove {
    justify-content: flex-start;
  }

  #addtocart-web {
    display: none;
  }

  #addtocart-mob {
    display: block;
  }

  #addtocart-mob {
    margin-top: 0;
  }

  .tickets-addmob {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #tickets-details .addtocart {
    margin-top: 0px;
    margin-right: 16.5px;
  }

  #tickets-details .tickets-another-description h4 {
    font-size: 20px;
    line-height: 24px;
  }

  #tickets-details #addcart {
    margin-left: 20px;
    margin-right: 6.5px;
  }

  #tickets-details .tickets-adddscription p {
    padding-right: 13px;
  }

  #addtocart-modal {
    min-height: 500px;
  }

  .view-cartmodal {
    padding-top: 45px;
    padding-bottom: 60px;
  }

  #addtocart-modal .addtocart-information p {
    padding-left: 14.5px;
    padding-right: 12.5px;
  }

  #addtocart-modal .add-new {
    display: none;
  }

  #addtocart-modal .addtocart-information h4 {
    padding-left: 14.5px;
    padding-right: 12.5px;
  }

  #addtocart-modal .addtocart-information .hand-img {
    margin-top: 45px;
  }

  .portalApp-live-content p {
    font-size: 24px;
    line-height: 36px;
  }

  .portalApp-live-content span {
    font-size: 24px;
    line-height: 36px;
  }

  .portalApp-live-content h4 {
    font-size: 24px;
    line-height: 36px;
  }

  .portal-about .education-block {
    height: 230px;
    padding-top: 0px;
    margin-top: 144px;
  }

  .portal-about .education-block-img {
    height: 230px !important;
  }

  .portal-about .d-btn {
    background: #003087 !important;
    border: 2px solid #003087 !important;
    border-radius: 100px !important;
    color: #c2ffd7 !important;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
    font-size: 16px;
    height: 40px;
    line-height: 38px;
    padding: 0 !important;
    width: 171px;
    margin-top: 15px;
  }

  .portal-about h1.slider-text.text-uppercase {
    font-size: 26px;
    line-height: 28px;
  }

  .portal-about .WEI-txt {
    font-size: 16px;
    line-height: 24px;
    padding-top: 10px;
    width: 90% !important;
  }

  .portal-about #donate-now {
    margin-left: 11px;
    line-height: 37px;
  }

  .app-banner .d-movie-title {
    font-size: 22.4px !important;
    line-height: 25px !important;
    height: 40px;
    padding-top: 0px !important;
  }

  .app-banner .main-content.set-main-brand {
    padding-top: 27px !important;
    padding-bottom: 47.5px !important;
  }

  .app-banner .d-set-prev {
    display: none !important;
  }

  .app-banner .d-set-next {
    display: none !important;
  }

  .app-banner .b-set-prev {
    display: none !important;
  }

  .app-banner .b-set-next {
    display: none !important;
  }

  .portal-accountcreation-left .createacoount-form {
    flex-direction: column;
  }

  .portal-accountcreation-left .form-group {
    width: 100%;
  }

  .portal-accountcreation-left .createacoount-form p {
    width: 100%;
  }

  .portal-accountcreation-left .createacoount-form .create-address {
    height: 41px;
    padding: 0 6px;
  }

  .portal-accountcreation-left .createacoount-form .create-zipcode {
    height: 41px;
    width: 100%;
    margin-right: 9px;
    padding: 0 6px;
  }

  .portal-accountcreation-left .createacoount-form #zipcode .find-zipcode {
    height: 41px;
    padding-top: 12px;
    padding: 0 6px;
  }

  .portal-accountcreation-left #Cust-Info #select-set {
    background-position: calc(100% - 12px) 17px !important;
    background-size: 13px !important;
  }

  .portal-accountcreation-left .createacoount-form .create-signin {
    width: 100%;
    height: 41px;
    padding: 0 6px;
  }

  .portal-accountcreation-left .createacoount-form .create-input {
    width: 100%;
    height: 41px;
    padding: 0 6px;
  }

  .portal-accountcreation-left .progress-accountcretaion {
    width: 100%;
  }

  .portal-accountcreation-left #address-form {
    width: 100%;
  }

  #payment-selection .credit-card-info {
    height: auto;
    padding: 21px 17px 10px 15px;
    margin-top: 11px;
  }

  #payment-selection .paypal-method {
    height: auto;
    padding: unset;
    margin-top: 98px;
    width: 55%;
    display: flex;
    border: unset;
    border-radius: unset;
    background-color: unset;
  }

  #payment-selection .credit-card-details {
    padding: 25px 34px 30px 28px;
    border: 1px solid #dde3e8;
    border-radius: 3px;
    background-color: #ffffff;
  }

  .applogonew-checkout {
    height: 87px;
    width: 184px;
    margin-top: 15px;
  }

  #tickets_cart_icon {
    height: 46px;
    width: 45px;
  }

  .header-lineup-checkout .header-tickets-img {
    padding-top: 27px;
  }

  .portal-accountcreation-left {
    padding-top: 4px;
  }

  .portal-accountcreation-left a.nav-item.nav-link {
    height: 62px;
    line-height: 19px;
  }

  .portal-accountcreation-left a.nav-item.nav-link.active {
    height: 62px;
    line-height: 19px;
  }

  .portal-accountcreation {
    width: 100%;
  }

  .portal-accountcreation .tab-contents-set {
    margin-left: 24px;
    margin-right: 21px;
  }

  .portal-accountcreation .tab-contents-set1 {
    margin-left: 14px;
    margin-right: 16px;
  }

  .portal-accountcreation-left .create-acoount-content {
    padding-top: 16px;
  }

  .portal-accountcreation-left .createacoount-form {
    padding-top: 0px;
  }

  .portal-accountcreation-left .createacoount-form .form-group {
    padding-top: 6px;
  }

  .portal-accountcreation-left .createacoount-form p {
    font-size: 12px;
    line-height: 19px;
    padding-top: 6px;
  }

  .portal-accountcreation-left .createacoount-form #accept-terms {
    padding-top: 18px;
    width: 100%;
  }

  #accept-pwd #accept-terms {
    padding-top: 6px;
  }

  .portal-accountcreation-left .checkout-btn {
    padding-top: 19px;
    padding-bottom: 19px;
    flex-direction: column-reverse;
  }

  .portal-accountcreation-left .checkout-btn .Create-Account {
    width: 329px;
  }

  .portal-accountcreation-left .checkout-returncart {
    justify-content: unset;
    padding-top: 20px;
    width: 100%;
  }

  .portal-accountcreation-left #Cust-Info h1 {
    display: block;
    color: #1b2125;
    font-family: "Avenir";
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 10px;
  }

  .portal-accountcreation-left #Cust-Info #cust-text {
    display: none;
  }

  .portal-accountcreation-left .create-acoount-content p {
    color: #fff;
    font-family: "Avenir";
    font-size: 22px;
    line-height: 30px;
  }

  .portal-accountcreation-left #Cust-Info .createacoount-form {
    padding-top: 25px;
  }

  .portal-accountcreation-left #Cust-Info .checkout-btn {
    padding-top: 44px;
  }

  .portal-accountcreation-left #Cust-Info .checkout-btn .Create-Account {
    margin-bottom: 188px;
  }

  #payment-selection .credit-cardinfos {
    padding-top: 18px;
  }

  #mob-stateview {
    flex-direction: row;
  }

  #mob-stateview .web-state {
    display: none;
  }

  #mob-stateview .mob-state {
    display: block;
    margin-right: 9px;
    width: 65%;
    position: relative;
  }

  .select-mobview {
    border-left: 1px solid #dde3e8;
    width: 38px;
    position: absolute;
    height: 41px;
    top: 33px;
    right: 0;
  }

  #mob-stateview .find-zipcode {
    display: none;
  }

  #payment-selection .credit-cardinfos .create-card {
    height: 41px;
    padding: 0 6px;
  }

  #payment-selection .credit-cardinfos .cardnum-image {
    display: none;
  }

  #payment-selection .credit-cardinfos .cvv-img {
    right: 14px;
    bottom: 9px;
  }

  #payment-selection .credit-cardinfos .cvvcode {
    height: 41px;
    padding: 0 6px;
  }

  #payment-selection .credit-cardinfos .date {
    height: 41px;
    width: 91%;
    padding: 0 6px;
  }

  #payment-selection .credit-cardinfos .create-name {
    height: 41px;
    padding: 0 6px;
  }

  #payment-selection .credit-cardinfos .cardname-cvv .expiry-date {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 0px;
  }

  #payment-selection .credit-cardinfos .cardname-cvv {
    margin-top: 0px;
    flex-direction: column;
  }

  #payment-selection .credit-cardinfos .create-name {
    width: 100%;
  }

  #payment-selection .checkout-btn {
    padding-top: 36px;
  }

  #payment-selection .Create-Account {
    height: 54px;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 46px;
  }

  #payment-selection .paypal-method .credit-card .radio-label p {
    display: none;
  }

  #payment-selection .paypal-method .credit-card .radio-label span {
    display: none;
  }

  #payment-selection #apple-pay {
    display: flex;
    margin-left: 9px;
  }

  #payment-selection #credit-card-details {
    padding: 0;
    border: unset;
    border-radius: unset;
  }

  .soon-text {
    width: 96%;
    font-size: 32px;
    line-height: 38px;
  }

  .event-left-bgcontent h1 {
    font-size: 34px;
    line-height: 49px;
    height: auto;
  }

  .event-sale {
    padding-top: 57px;
  }

  .event-sale h5 {
    font-size: 24px;
    line-height: 24px;
    padding: 0 20px;
    letter-spacing: 0.58px;
  }

  .event-sale span {
    font-size: 20px;
    line-height: 24px;
    padding: 0 20px;
    letter-spacing: 0.69px;
  }

  .event-sale h4 {
    margin-top: 28px;
    font-size: 32px;
    line-height: 35px;
    height: auto;
    padding: 0 20px;
  }

  .event-up-bgcontent h4 {
    font-size: 18px;
    line-height: 23px;
  }

  .event-left-bgcontent p {
    font-size: 36px;
    line-height: 42px;
  }

  #event-sale-content1 .event-left-bgcontent p {
    font-size: 36px;
    line-height: 42px;
  }

  .tickets-address {
    padding-top: 0px;
  }

  .portal-accountcreation-left .createacoount-form #zipcode {
    margin-right: 10px;
  }

  .app-conatctus {
    width: 90%;
    padding: 21px 16px 33px 16px;
  }

  .app-conatctus .conatctname {
    width: 100%;
  }

  .app-conatctus .message-inbox {
    width: 100%;
  }

  .portal-conatct .conatct-box {
    display: none;
  }

  .portal-conatct {
    margin-top: 40px;
  }

  .app-confirmation p.myaccount {
    width: 90%;
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 44px;
  }

  .app-confirmation h5 {
    font-size: 20px;
    line-height: 20px;
    padding-bottom: 64px;
  }

  .portal-accountcreation-left .checkout-returncart #back-cart-billing {
    display: none;
  }

  .portal-accountcreation-left .checkout-returncart #back-cart-mobile {
    display: block;
  }

  #purchasepolicy-modal .purchase-policy-content {
    width: 90%;
    padding: 22.5px 10.5px 0 10px;
  }

  .your-ticktesimg {
    flex-direction: column;
    align-items: center;
  }

  #addtowallet-items {
    border-bottom: 1px solid #4a4a4a;
    padding-bottom: 38px;
    margin-bottom: 53px;
  }

  .Your-Tickets .Add-Wallet {
    opacity: unset;
    width: 278px;
  }

  .Your-Tickets {
    width: 80%;
  }

  #purchasepolicy-modal .purchasepolicy-cancel {
    flex-direction: column;
    padding: 0 15px;
  }

  .purchaseCheck {
    margin-right: 13px;
  }

  #purchasepolicy-modal {
    min-height: 621px;
    height: auto;
  }

  .chekckout-privacypolicy {
    flex-direction: column;
    padding-top: 17px;
  }

  #purchasepolicy-modal .purchasepolicy-accept {
    margin: 15px 0;
  }

  #purchasepolicy-modal .purchasepolicy-cancel p {
    padding-right: 0;
  }

  .upload-logo-image {
    width: 100%;
  }

  .upload-logo-image img {
    height: 90px;
    width: 90px;
  }

  .conatctus-confirmation {
    margin: 150px 0;
  }

  #tickets-details .ticketreserve-acc .card-header {
    padding: 19px 10px 17px 10px;
    height: auto !important;
  }

  #tickets-details .ticketreserve-acc .tickets-toggle .info-img {
    height: 25px;
  }

  #tickets-details .ticketreserve-acc .tickets-toggle p {
    font-size: 18px;
    padding-left: 10px;
  }

  #tickets-details .ticketreserve-acc .tickets-toggle span {
    font-size: 24px;
    line-height: 28px;
  }

  #tickets-details .ticketreserve-acc .tickets-toggle .ticket-reserve {
    font-size: 20px;
    line-height: 24px;
  }

  #tickets-details .ticketreserve-acc .ticketreserve-description {
    padding: 6px 10px 21.5px 59px;
  }

  #tickets-details #ticketreserve-acc .tickets-toggle .pay-laterimg {
    padding: 10px 0 10px 7px;
  }

  #tickets-details #ticketreserve-acc .pay-later {
    height: 27px;
    width: 27px;
  }

  #tickets-details #ticketreserve-acc .tickets-toggle .ticket-reserve {
    font-size: 16px;
    line-height: 20px;
  }

  #tickets-details #ticketreserve-acc .tickets-toggle span {
    font-size: 18px;
    line-height: 22px;
  }

  #tickets-details #ticketreserve-acc .card-header {
    height: auto !important;
  }

  #tickets-details #ticketreserve-acc .ticketreserve-description {
    padding: 0px 7px 0px 7px;
  }

  #tickets-details #ticketreserve-acc .ticketreserve-description p>span {
    font-size: 16px;
    line-height: 20px;
  }

  #tickets-details #ticketreserve-acc .ticketreserve-description p>b {
    font-size: 16px;
    line-height: 20px;
  }

  .ticket-paylater {
    padding-left: 5px;
    padding-right: 5px;
  }

  #tickets-details #ticketreserve-acc .ticket-paylater p {
    font-size: 14px;
  }

  #tickets-details #ticketreserve-acc .ticket-paylater .paylater-radio p {
    padding-left: 5px;
    text-align: left;
  }

  #tickets-details .text-radio>input {
    width: 16px;
    height: 16px;
  }

  #tickets-details-addcart #ticketreserve-acc .tickets-toggle .pay-laterimg {
    padding: 10px 0 10px 7px;
  }

  #tickets-details-addcart #ticketreserve-acc .pay-later {
    height: 27px;
    width: 27px;
  }

  #tickets-details-addcart #ticketreserve-acc .tickets-toggle .ticket-reserve {
    font-size: 16px;
    line-height: 20px;
  }

  #tickets-details-addcart #ticketreserve-acc .tickets-toggle span {
    font-size: 18px;
    line-height: 22px;
  }

  #tickets-details-addcart #ticketreserve-acc .tickets-toggle b {
    font-size: 18px;
    line-height: 22px;
  }

  #tickets-details-addcart #ticketreserve-acc .card-header {
    height: auto !important;
  }

  #tickets-details-addcart #ticketreserve-acc .ticketreserve-description {
    padding: 0px 7px 0px 7px;
  }

  #tickets-details-addcart #ticketreserve-acc .ticketreserve-description p>span {
    font-size: 16px;
    line-height: 20px;
  }

  #tickets-details-addcart #ticketreserve-acc .ticketreserve-description p>b {
    font-size: 16px;
    line-height: 20px;
  }

  #tickets-details-addcart .ticket-paylater {
    padding-left: 5px;
    padding-right: 5px;
  }

  #tickets-details-addcart #ticketreserve-acc .ticket-paylater p {
    font-size: 14px;
  }

  #tickets-details-addcart #ticketreserve-acc .ticket-paylater .paylater-radio p {
    padding-left: 5px;
    text-align: left;
  }

  #tickets-details-addcart .text-radio>input {
    width: 16px;
    height: 16px;
  }

  .account-profileinfo-right {
    width: 50%;
  }

  .account-profileinfo-left {
    width: 50%;
  }

  .account-profileinfo-right {
    width: 80%;
  }

  .account-profileinfo-left {
    width: 20%;
  }

  .account-profileinfo-field {
    align-items: center;
    padding-bottom: 5px;
    padding-top: 10px;
  }

  .account-profileinfo .account-name {
    font-size: 14px;
    line-height: 18px;
    padding: 0 5px 0 10px;
  }

  .account-profileinfo-left p {
    font-size: 15px;
    padding-bottom: 0px;
  }

  #account-info-tabs .tab-content {
    height: auto;
    overflow-y: unset;
  }

  .send-ticket-information {
    margin: 38px auto;
    margin-bottom: 0;
    width: 91%;
  }

  .ticket-transfer-main {
    height: auto;
    overflow-y: unset;
  }

  .selectTicket-transfer {
    width: 100%;
  }

  .send-ticket-info {
    padding: 15px 10px 6px 0px;
  }

  .send-ticket-form .create-input {
    width: 100%;
  }

  .send-ticket-form #rmv-mob {
    display: none;
  }

  .send-tickets-btns {
    flex-direction: column;
  }

  .back-bottomtext {
    display: none;
  }

  .backtomy-ticktes {
    display: none;
  }

  .backto-ticktes h2 {
    font-size: 20px;
    margin-top: 0px;
    padding-top: 18px;
  }

  .send-tickets-btns .CANCEL {
    margin-top: 22px;
  }

  .backto-ticktes .closeicon-white {
    display: block;
    text-align: right;
  }

  .backto-ticktes .closeicon-white img {
    margin-right: 15px;
    margin-top: -50px;
    cursor: pointer;
  }

  #cardheader-text {
    font-size: 18px;
    line-height: 22px;
    padding: 13px;
  }

  #checkout-cardpay .card-body {
    padding: 15px;
  }

  #cardpay-acc .show-details .cardnumber {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  #checkout-cardpay .paymentinfo-form .save {
    margin-top: 20px;
  }

  .payment-info-container .card-text {
    font-size: 20px;
  }

  .addnewcard-modal .paymentinfo-form {
    padding: 0px;
  }

  .addnewcard-modal .create-acoount-content {
    margin-top: 0px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .addnewcard-modal .create-acoount-content p {
    color: #1b2125;
    font-family: "Avenir";
    font-size: 22px;
    line-height: 30px;
  }

  .addnewcard-modal .paymentinfo-form .save {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .location-img {
    margin-top: 175px;
  }

  .time-header {
    font-size: 29px;
  }

  .set-staticbrand {
    display: none;
  }

  .types-ofportals {
    gap: 37px;
    padding-bottom: 0px;
    flex-wrap: wrap;
  }

  .portals-network-container {
    padding-bottom: 40px;
  }

  .portal-description p {
    font-size: 20px;
  }

  .app-banner .main-content.set-main-brand {
    padding: 51px 10px !important;
  }

  .mobile-portallogo {
    display: block;
  }

  .countdown-container {
    background: #000226;
  }

  #favorites-contens .swiper-button-prev {
    display: none;
  }

  #favorites-contens .swiper-button-next {
    display: none;
  }

  #order-confiramtion-modal {
    max-width: 98%;
  }

  #order-confiramtion-modal .modal-content {
    padding: 0px 15px 30px 15px;
  }

  .portal-order-confiramtion-modal {
    margin-top: 23px;
  }

  #order-confiramtion-modal .icon-btn {
    top: 7px;
    right: 7px;
  }

  #order-confiramtion-modal .top-hr {
    width: 100%;
    margin-top: 9px;
    margin-bottom: 35px;
  }

  .stripe-sponsor-modal .order-title {
    padding-top: 28px;
  }

}

@media only screen and (max-width: 650px) {

  #top-swiper .banner-text {
    font-size: 45px;
    letter-spacing: 1.66px;
  }

  .ordersAccordion h4 {
    color: #000000;
    font-family: "Inter-Regular";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    /* line-height: 26px; */
    padding-left: 10px;
    margin: 0;
  }

  #payment-selection form {
    width: auto !important;
  }

  #add-cart-modal .tickets-add {
    width: auto;
    padding-top: 5px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 13px;
  }

  #addtocart-modal .tickets-festival {
    /* display: flex; */
    width: auto;
    justify-content: flex-start;
    /* flex-direction: column;
        margin: 32px 0 36px 0;
        padding: 0; */
  }

  /* .portal-accountcreation-left #Cust-Info .stripe-payment-subtotal h1 {
    color: #585858;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    padding-bottom: 0px;
  } */

  .stripe-sponsor-modal {
    max-width: 98% !important;
  }
}

@media only screen and (max-width: 576px) {
  .mob-dropdown {
    width: 50% !important;
  }

  #ticketPolicyModal {
    max-width: unset !important;
  }

  .d-set-next {
    right: 15px !important;
  }

  .d-set-prev {
    left: 15px !important;
  }

  .d-set-overflow {
    padding: 0 !important;
    padding-left: 0px !important;
  }

  #favorites-contens .swiper-container {
    padding-bottom: 0px !important;
  }

  /* .d-appbgimg {
    background-image: url("../images/half-logo.png");
    background-attachment: unset;
    background-position-y: 28px !important;
    background-position: unset;
    background-size: unset !important;
  } */

  header#main-header {
    position: unset !important;
  }

  .iq-main-slider {
    padding-top: 0px !important;
  }

  .d-brand-img {
    width: 168px !important;
    height: 112px !important;
  }

  /* #d-tvchannel-slider {
        width: 217px !important;
        height: 147px !important;
        margin-right: 8px !important;
    } */

  .d-tvchannel-img {
    width: 200px !important;
    height: 134px !important;
  }

  img.d-img2 {
    width: 26px !important;
  }

  .edu-description {
    font-size: 15px;
  }

  .app-content h4 {
    padding-right: 10px;
    font-size: 36px;
    line-height: 36px;
    padding-left: 10px;
  }

  #tech-section {
    width: 100%;
  }

  .d-title-txt-one {
    font-size: 10px;
    padding-bottom: 2px;
    line-height: 11px;
    padding-top: 40px;
  }

  .d-txt-one {
    font-size: 6px;
    letter-spacing: 0.3px;
    font-size: 6px;
    line-height: 11px;
  }

  #slider-ratting {
    margin-right: 4px !important;
    margin-top: 11px !important;
    margin-bottom: 12px !important;
  }

  p.trending-dec.d-txt-one {
    display: none;
  }

  #space-series {
    margin-top: 24px !important;
  }

  .d-title-txt-one {
    display: none;
  }

  .series-btn {
    margin-top: 0px !important;
  }

  div#feature-bgimg {
    height: 272px !important;
  }

  .form-bg-mobile {
    background-size: contain !important;
    /* background: url(../images/mobile.png); */
    background-repeat: no-repeat;
    background-position: top !important;
  }

  .sign-in-page {
    background: none !important;
  }

  .sign-in-page .height-self-center {
    height: auto !important;
    border-radius: 15px;
  }

  #set-sign-in {
    border: unset;
    width: 100%;
    border-radius: unset;
    background: unset;
  }

  .sign-in-logo {
    margin-top: 26px;
    margin-bottom: 16px;
  }

  #sign-in-logo {
    padding-top: 93px;
  }

  .sign-in-logo1 {
    margin-top: 26px;
    margin-bottom: 16px;
  }

  .form-bg-mobile1 {
    margin-top: 24px;
    padding-top: 148px;
  }

  #form-bg-mobile1 {
    margin-top: 24px;
    padding-top: 96px;
  }

  #sign-in-logo1 {
    padding-top: 0;
  }

  .forgot-pwd {
    padding-top: 0px;
  }

  /* input#txtLastname {
        width: 159px;
    }

    input#txtFirstname {
        width: 159px;
    } */

  .d-title-feature-2 {
    padding-top: 184px !important;
  }

  .d-ratings-and-season {
    display: none !important;
  }

  .series-btn {
    line-height: 40px;
    margin-top: 9% !important;
  }

  .trending-button {
    text-align: center;
  }

  .tranding-block2 {
    height: 272px;
  }

  .tranding-block2 .d-set-featured {
    padding-top: 80px;
  }

  #set-sign-in {
    margin: 0 !important;
  }

  .d-image-1,
  .d-image-2 {
    width: 250px;
  }

  .d-set-featured-video,
  .d-set-featured-channel-photo {
    padding-top: 45px;
  }

  .d-title-feature-2 {
    padding-top: 11px !important;
  }

  .d-series-set-btn {
    justify-content: center !important;
  }

  .set-channel-img {
    background: none;
    padding-top: 40px !important;
    min-height: auto !important;
    padding-bottom: 0 !important;
  }

  .channel-registration {
    width: 90%;
    padding: 0;
    background: none;
    border: none;
  }

  .network-timeline {
    width: 97%;
  }

  .select-channel-subtype {
    width: 100% !important;
  }

  .select-channel-type {
    width: 100% !important;
  }

  .set-domain {
    width: auto;
    margin-bottom: 30px;
  }

  .movie-app-img {
    height: 217px;
    width: 147px;
  }

  .show-profile-img {
    height: 82px;
    width: 82px;
  }

  .show-rating-details h3 {
    font-size: 14px !important;
    line-height: 31px !important;
  }

  .show-rating-details h4 {
    font-size: 14px !important;
    line-height: 31px !important;
    padding-left: 14px;
  }

  .show-tv-btn {
    height: 21px;
    width: 62px;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0em;
    margin-left: 10px;
  }

  .show-like-details p {
    font-size: 14px;
    line-height: 21px;
  }

  .movie-bgimg {
    height: 219px;
  }

  .show-movie-details {
    padding-top: 0px;
  }

  .detail-action-btn {
    font-size: 14px;
    line-height: 21px;
    height: 21px;
    width: 62px;
    border-radius: 0px !important;
    border: 1px solid #bdbdbd;
    background: none !important;
    margin-left: 8px;
  }

  .show-rating-details p {
    font-size: 14px;
    line-height: 31px;
  }

  .show-count-data p {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #bdbdbd;
    padding-top: 5px;
  }

  .review-rating-calc p {
    font-size: 14px;
    line-height: 21px;
    padding-left: 7px;
  }

  .review-tab-description {
    padding: 10px;
    border-radius: 15px;
  }

  .view-tab span {
    font-size: 14px;
    line-height: 21px;
  }

  .view-tab p {
    font-size: 14px;
    line-height: 21px;
  }

  .verified-ratings p {
    font-size: 14px;
    line-height: 21px;
    padding-top: 10px;
  }

  .review-purchase h5 {
    font-size: 12px;
    line-height: 18px;
    padding-top: 8px;
  }

  .review-purchase p {
    font-size: 14px;
    padding-top: 5px;
  }

  .review-purchase h6 {
    font-size: 12px;
    line-height: 18px;
    padding-top: 4px;
  }

  .review-report {
    padding-top: 15px;
  }

  .review-report p {
    font-size: 12px;
    line-height: 18px;
    padding-left: 40px;
  }

  .review-tab-description {
    margin-top: 24px;
  }

  .movie-tab-item {
    padding-right: 13px !important;
  }

  .set-accordion-ratings .MuiSvgIcon-root {
    font-size: 1.5rem !important;
  }

  .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd {
    height: 7px !important;
    width: 12px !important;
  }

  img.topreview-vector {
    height: 7px;
    width: 12px;
  }

  .show-star-content h5 {
    font-size: 14px;
    line-height: 31px;
  }

  .app-series p {
    font-size: 13px;
    line-height: 17px;
  }

  #series-season {
    padding-right: 13px;
  }

  #pills-season-tab {
    padding-right: 5px !important;
  }

  .season-vector {
    height: 7px;
    width: 12px;
    margin-right: 13px;
  }

  .show-movie-tab {
    padding-left: 13px;
  }

  .show-movie-tab h4 {
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .remaining-photo {
    width: 24%;
  }

  .main-content.extra-tabs-set {
    width: 70%;
  }

  .p-set-next {
    right: -43.5% !important;
  }

  video.movie-bgvideo {
    height: 219px;
  }

  .WEI-txt {
    padding-top: 20px;
    width: 90% !important;
    font-size: 16px;
    line-height: 24px;
  }

  h1.slider-text.text-uppercase {
    font-size: 28px;
    line-height: 32px;
  }

  .series-listname {
    font-size: 28px;
    line-height: 32px;
  }

  #home-slider h1.slider-text {
    font-size: 32px;
    padding-top: 4px !important;
    line-height: 40px !important;
  }

  #d-container {
    padding-left: 16px !important;
    padding-right: 0 !important;
    width: auto !important;
  }

  section#iq-brand {
    margin-top: 64px !important;
  }

  #b-container {
    padding-left: 16px !important;
    padding-right: 0 !important;
    width: auto !important;
  }

  .info-share-mob li::marker {
    display: none !important;
  }

  .app-channel-signin {
    font-size: 14px;
    line-height: 22px;
    width: 88px;
    height: 28px;
    padding: 0 !important;
  }

  .app-channel-btn {
    font-size: 14px;
    line-height: 22px;
    width: 169px;
    height: 28px;
    padding: 0 !important;
  }

  .privacy-text-sec p {
    font-size: 28px;
    line-height: 76px;
  }

  .privacy-text-sec {
    height: 80px;
  }

  .contact-privacy {
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 36px;
    padding-bottom: 23px !important;
  }

  #set-privacy-policy {
    width: 96%;
  }

  .set-bottom-privacy {
    padding-top: 29px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .contacting-us {
    font-size: 32px;
    line-height: 32px;
  }

  .d-bnr-btn {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .d-btn-view-now {
    margin-left: 0px;
  }

  /* 
    .d-bnr-btn .d-btn {
        margin-top: 20px;
    } */

  .show-podcast-details {
    padding-top: 0px;
  }

  .show-Listen-btn {
    width: 100%;
    height: 54.09px;
    font-size: 22.6923px;
  }

  .show-Subscribe-btn {
    width: 100%;
    height: 54.09px;
    font-size: 22.6923px;
    margin-left: 0px;
    margin-top: 13px;
  }

  p.podcast-episode-para.podcast-episode-para1 {
    font-size: 14px;
    padding-top: 10px;
  }

  .podcast-timing-sec {
    margin-left: 10px;
  }

  .podcast-footer-list1 img {
    width: 55px;
  }

  .podcast-footer-list2 p {
    font-size: 14px;
    line-height: 20px;
  }

  #set-donate-btn {
    display: block;
  }

  #header-mobile {
    flex-direction: column;
    align-items: flex-end !important;
  }

  #header-mobile li {
    margin-right: 0px !important;
    margin-top: 15px !important;
  }

  .more-menu .iq-sub-dropdown {
    right: 15px;
    top: 143px;
    width: 200px !important;
  }

  #set-watchlist-row .col-1-5 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .watchlist-container {
    padding: 10px 15px;
    margin-top: 0 !important;
  }

  .watchlist-container .tab-content {
    margin-top: 47px;
  }

  .footer-podscast {
    height: 638px;
  }

  .main-header {
    padding: 12px 0;
  }

  .payment-img {
    margin-top: 0px;
    width: 60px;
  }

  #payment-msg {
    margin-top: 15px;
  }

  #payment-back {
    margin-top: 20px !important;
  }

  #view-all-podcast {
    flex: 0 0 32.666667%;
    max-width: 32.666667%;
  }

  #view-all-movie1 {
    flex: 0 0 49.666667%;
    max-width: 49.666667%;
  }

  .iq-privacy-policy2 {
    padding: 30px 15px;
  }

  .iq-accordion .iq-accordion-details {
    padding: 15px 15px;
  }

  .iq-accordion .iq-accordion-title {
    padding: 30px 75px 30px 15px;
  }

  .faq-col {
    padding: 0 10px !important;
  }

  #set-sign-in1 {
    background: unset !important;
    padding: 0px;
    border-radius: unset !important;
    border: unset !important;
  }

  #set-sign-in {
    height: auto;
    box-shadow: unset !important;
    padding: 0 16px !important;
  }

  #set-input-width {
    padding-top: 24px;
  }

  .sign-in-input {
    height: 44px !important;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.5);
  }

  .sign-in-input-pwd {
    height: 44px !important;
  }

  #set-input-width input::placeholder {
    color: #797979 !important;
  }

  input,
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"],
  textarea {
    padding: 0 16px;
  }

  #set-input-width .second-name {
    padding-left: 7px !important;
  }

  #set-sign-in1 .input-group {
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.5);
  }

  #sign-inbtn {
    font-size: 16px;
  }

  #info-logopages {
    width: 180px;
    height: 72px;
  }

  #container-signin {
    padding: 0 !important;
  }

  .forgot-input {
    height: 44px !important;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.5);
  }

  .set-input-width {
    padding-top: 40px !important;
  }

  #forgot-inbtn {
    height: 44px;
    border-radius: 25px !important;
    font-size: 16px;
  }

  .set-reset-height {
    height: 0px;
  }

  .set-reset-height #set-input-width {
    padding-top: 0px;
  }

  #header-container {
    padding: 0 16px;
  }

  .iq-sub-dropdown.dropdown-menu.dropdown-menu-right.dropdown-menu.show {
    z-index: 9;
  }

  .iq-sub-dropdown.dropdown-menu.dropdown-menu-right.dropdown-menu.show {
    /* height: 212px !important;
        position: fixed !important;
        bottom: 0 !important;
        top: 0 !important;
        width: 100% !important; */
    transform: unset !important;
    background: transparent !important;
    z-index: 99;
    /* border-radius: 35px 35px 0px 0px !important; */
  }

  svg.black-polygon {
    display: none;
  }

  #right-sidetoggle .iq-card-body.iq-card-body {
    padding: 36px 16px 40px 16px;
  }

  #right-sidetoggle .app-channel-create {
    width: 100%;
    height: 40px;
    font-size: 16px;
    line-height: 35px;
  }

  #right-sidetoggle .toggle-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    margin-bottom: 20px;
  }

  #right-sidetoggle .toggle-text-line {
    width: 70px;
  }

  #right-sidetoggle .app-channel-signin {
    width: 100%;
    height: 40px;
    font-size: 16px;
    line-height: 36px;
  }

  .dropdown-menu {
    /* right: 0 !important;
        bottom: 0 !important; */
    min-width: 100% !important;
  }

  .iq-card.iq-card.shadow-none.m-0 {
    background: linear-gradient(180deg,
        #181818 10.62%,
        #000000 94.74%) !important;
    border-radius: 35px 35px 0px 0px !important;
  }

  .MuiPaper-root {
    background-color: unset !important;
  }

  #d-header {
    height: 82px !important;
  }

  #toggle-contents {
    width: 100%;
    padding: 28px 16px;
  }

  .footer-icon {
    text-align: center;
  }

  div#right-sidetoggle {
    width: 100%;
  }

  .header-profile p {
    display: none;
  }

  .error-content {
    padding: 10px;
    display: block !important;
    height: auto !important;
    margin-bottom: 50px;
  }

  .error-image {
    width: 200px;
  }

  .error-code {
    font-size: 60px !important;
    margin-top: 10px;
  }

  .error-text {
    font-size: 30px;
    margin-top: 20px;
  }

  .left-logo {
    justify-content: left !important;
  }

  .header-profile.small {
    flex: unset !important;
    width: auto;
  }

  h4.mb-4.basic-information {
    text-align: center;
  }

  .change-pwd-save {
    justify-content: center;
  }

  .change-pwd-text {
    text-align: center;
  }

  #iq-main-slider {
    padding-top: 0px !important;
  }

  #video-section .jw-breakpoint-1 .jw-display .jw-icon,
  .jw-breakpoint-1 .jw-display .jw-svg-icon {
    width: 33px;
    height: 33px;
    line-height: 44px;
  }

  #error-page {
    margin-top: 0px;
  }

  .buy-ticket-payment {
    width: 100%;
  }

  .buy-payment-summary {
    padding: 30px 10px;
  }

  .buy-ticket {
    padding-top: 0px;
  }

  .buy-ticket-card {
    padding: 25px 13px;
  }

  .buy-ticket-title h4 {
    font-size: 16px;
    line-height: 22px;
  }

  .buy-ticket-make {
    padding: 15px;
  }

  .purchase-policy {
    text-align: center;
  }

  .purchasePolicyModal-buttonBar>button {
    min-width: 132px;
    height: 36px;
    font-size: 15px;
    line-height: 28px;
  }

  #set-list-footer .privacy-footer {
    padding-right: 43px !important;
  }

  .purchase-policy {
    flex-direction: column;
  }

  .customer-billing-section {
    padding: 23px 15px;
  }

  #set-payment-status {
    border: unset;
    width: 100%;
    border-radius: unset;
    background: unset;
    box-shadow: unset !important;
    padding: 0 16px !important;
  }

  #payment-mobile {
    margin-top: 40px;
  }

  #set-btn-dedign {
    flex-direction: column;
    padding: 0 15px;
  }

  /* .portalApp-footer-img .APP.small {
    height: 77px;
    width: 80px;
  } */

  .event-sale-content {
    flex-direction: column;
    padding-top: 21px;
  }

  #event-sale-content {
    padding-top: 0px;
  }

  .event-up-bgcontent {
    width: 100%;
  }

  .event-left-bgcontent {
    width: 100%;
  }

  .event-text-mob {
    display: block;
    color: #003087;
    font-family: "Impact";
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.89px;
    text-align: center;
    text-shadow: 0 12px 11px #000000;
    padding-top: 30px;
    padding-bottom: 14px;
  }

  .event-up-bgcontent.event-up-announce h4 {
    font-size: 18px;
    line-height: 30px;
  }

  #event-mob-text {
    display: none;
  }

  #mob-left-bg p {
    line-height: 48px;
  }

  #mob-left-bg1 p {
    line-height: 48px;
  }

  .event-sale-bgcontent {
    background-image: unset;
  }

  .robot-icon {
    width: 288px;
    margin-right: 0px;
  }

  .gettickets-btn {
    height: 58px;
    width: 220px;
    border: 2.4px solid #ffffff;
    font-size: 25px;
    letter-spacing: 0.97px;
    line-height: 27px;
  }

  .mob-bobble-images .bobbleimage-mobile {
    position: absolute;
    width: 100%;
    z-index: -10;
    display: unset !important;
    max-height: 100%;
  }

  .mob-bobble-images .bobbleimage-mobile1 {
    position: absolute;
    width: 100%;
    z-index: -10;
    display: unset !important;
  }

  .mob-bobble-images {
    position: relative;
    width: 100%;
  }

  .portal-loc-address p {
    font-size: 22px;
    letter-spacing: 0px;
    line-height: 27px;
  }

  .portal-loc-address span {
    font-size: 22px;
    letter-spacing: 0px;
    line-height: 27px;
  }

  .location-icon {
    margin-bottom: 252px;
  }

  .portal-loc-address {
    width: 80%;
    padding-top: 50px;
  }

  .portal-location {
    padding-top: 85px;
  }

  #tickets-details .tickets-another-description {
    align-items: flex-start;
    justify-content: unset;
    flex-direction: column;
    padding: 6px 19.5px 23px 19px;
  }

  #add-cart-modal {
    margin: 0;
  }

  .app-banner img.img-fluid.d-influ-img {
    height: 167px;
    width: 120px;
  }

  .app-banner .d-brand-img {
    height: 78.7px !important;
    width: 130.7px !important;
    border: 0.7px solid #979797 !important;
    border-radius: 7px !important;
    background-color: #d8d8d8;
  }

  #d-container {
    padding-left: 15px !important;
    padding-right: 15px !important;
    width: auto !important;
  }

  .app-banner .d-view-text {
    padding-right: 0px;
  }

  #b-container {
    padding-left: 16px !important;
    padding-right: 15px !important;
    width: auto !important;
  }

  .smokebg {
    border-top: 5px solid #003087;
    border-bottom: 5px solid #003087;
    margin-bottom: 98px;
  }

  .app-bgimage {
    background-size: contain;
    /* background-image: url("../images/mobile-space-background.png"); */
    opacity: 0.4;
  }

  #footerbg-mobile {
    display: block;
  }

  #footerbg-web {
    display: none;
  }

  #addtocart-modal .date-sch {
    padding-left: 25px;
  }

  #addtocart-modal .tickets-festival p {
    width: auto;
    /* padding-top: 9px; */
  }

  #addtocart-modal .view-cartmodal1 p {
    font-size: 18px;
  }

  .app-confirmation p {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 22px;
  }

  .app-confirmation .locked-in {
    font-size: 20px;
    line-height: 24px;
  }

  .app-confirmation h2 {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 43px;
  }

  .app-confirmation h4 {
    font-size: 20px;
    padding-bottom: 71px;
  }

  .app-confirmation span {
    font-size: 20px;
  }

  .app-confirmation h6 {
    font-size: 22px;
  }

  .app-confirmation {
    padding-top: 77px;
  }

  .app-confirmation {
    width: auto;
    margin: 0 13px;
  }

  .app-confirmation .add-tickets1 {
    height: 219px;
    width: 387px;
  }

  .app-confirmation .add-tickets2 {
    height: 219px;
    width: 387px;
    margin-top: 43px;
  }

  .app-confirmation h6 {
    padding-top: 45px;
  }

  .app-confirmation .confirm-border {
    margin: 40px auto;
  }

  .portal-accountinfo {
    width: 100%;
  }

  .portal-accountinfo a.nav-item.nav-link.active {
    height: 50px;
    font-size: 22px;
    line-height: 50px;
  }

  .portal-accountinfo a.nav-item.nav-link {
    height: 50px;
    font-size: 22px;
    line-height: 50px;
  }

  /* .Your-Tickets span {
    font-size: 20px;
    line-height: 28px;
  }

  .Your-Tickets h4 {
    font-size: 20px;
    line-height: 28px;
  } */

  .Your-Tickets h5 {
    font-size: 20px;
    line-height: 28px;
  }

  .Your-Tickets h3 {
    /* font-size: 16px;
    line-height: 21px; */
    padding-bottom: 20px;
  }

  .account-profileinfo h5 {
    font-size: 20px;
    line-height: 28px;
    margin-top: 20px;
  }

  .account-profileinfo-left p {
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 0px;
  }

  .account-profileinfo-right .account-username {
    font-size: 18px;
    line-height: 25px;
  }

  .Your-Tickets {
    padding-top: 20px;
    padding-bottom: 48px;
    width: 90%;
  }

  .account-profileinfo .Edit-Profile {
    margin-top: 57px;
    /* margin-bottom: 63px; */
    margin-bottom: 10px;
  }

  .account-profileinfo {
    padding-top: 40px;
  }

  .app-confirmation .location-icon-confirm {
    margin-bottom: 40px;
  }

  #add-cart-modal #tickets-add {
    width: 96%;
  }

  .portal-accountcreation-left #merchant-account {
    padding: 0;
  }

  #set-mob-drawer .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    width: 100%;
  }

  div#set-mob-drawer {
    background: black;
  }

  #event-sale-content1 .event-left-bgcontent {
    background-image: unset;
    width: 100%;
    border-right: 0;
  }

  #event-sale-content .event-up-bgcontent {
    width: 100%;
  }

  .event-sale-content1 {
    flex-direction: column;
  }

  #event-sale-content1 {
    margin-top: 0px;
  }

  #event-sale-content1 .centerline-set {
    display: none;
  }

  #addtocart-modal .applogoticktet {
    height: 84px;
    width: 82px;
  }

  .set-bobbleimage .bobbleimage {
    display: none;
  }

  .portal-faq .card-header {
    height: 47px;
    padding: 0px 10px 0 10px;
  }

  .portal-faq {
    width: 94%;
  }

  .portal-faq .faq-questions p {
    font-size: 14px;
    line-height: 18px;
  }

  .portal-faq .card-body {
    font-size: 14px;
    padding: 10px 10px 10px 10px;
  }

  .portal-faq h4 {
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 11px;
    padding-left: 0px;
  }

  .app-conatctus {
    width: 90%;
  }

  .portal-privacypolicy {
    width: 94%;
  }

  .portal-privacypolicy .privacy-contact {
    padding-top: 0px;
  }

  .dropdown-menu.dropdown-menu-right {
    display: none !important;
  }

  .bottomDrawer {
    display: block !important;
  }

  .btn-tickets-home button {
    width: 216px;
    height: 45px;
    border-radius: 14.8px;
    font-size: 21.6px;
    letter-spacing: 0.82px;
  }

  .portal-accountcreation-left #Cust-Info #select-set {
    background-image: url("../images/select-arrow.svg") !important;
    background-position: calc(100% - 0px) 0px !important;
    background-size: 34px !important;
    padding-right: 28px;
  }

  .artists-description {
    padding-top: 46px;
  }

  .artists-description p {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.71px;
    padding: 0 10px;
  }

  #addtocart-modal .portal-qty h6 {
    font-size: 18px;
    line-height: 22px;
    padding-left: 11px;
  }

  .portal-accountcreation-right .accountcreate-subtotal h2 {
    font-size: 14px;
  }

  .portal-accountcreation-right .accountcreate-total h2 {
    line-height: 24px;
  }

  #addtocart-modal .portal-accountcreation-right {
    width: 96%;
    padding: 0 15px;
  }

  .portal-accountcreation-left a.nav-item.nav-link span {
    display: block;
    padding: 0;
  }

  img.About-image {
    height: auto;
    width: 100%;
  }

  .portal-conatct .upload-logo-image .set-brandlogo-txt {
    justify-content: center;
    flex-direction: column;
  }

  .tickets-toggle p {
    width: 70%;
    text-align: left;
  }

  #tickets-details.sponsorProducts .card-header h4 {
    width: 70%;
    flex: unset;
  }

  #tickets-details.sponsorProducts .card-header p {
    font-size: 16px;
    line-height: 21px;
  }

  #home-slider .slick-bg {
    height: 300px !important;
  }

  #home-slider .slick-bg img {
    height: 300px;
  }

  #home .swiper-button-next {
    top: 50.8% !important;
  }

  #home .swiper-button-prev {
    top: 50.8% !important;
  }

  #home .swiper-button-next {
    right: 21px;
  }

  #home .swiper-button-prev {
    left: 21px;
  }

  #tickets-details.sponsorProducts button {
    align-items: center;
  }

  #forgotpassword .create-return-form {
    padding-top: 20px;
  }

  h3.text-uppercase.view-all-text-movie {
    font-size: 24px;
  }

  #tickets-details #ticketreserve-acc .tickets-toggle .show-moretickets p {
    font-size: 14px;
    padding-right: 0px;
    padding-left: 3px;
    flex: 1;
  }

  #tickets-details #ticketreserve-acc .tickets-toggle p {
    font-size: 16.5px;
    flex: 1;
    padding-right: 5px;
    padding-left: 10px;
  }

  #tickets-details #ticketreserve-acc .show-moretickets {
    padding-right: 5px;
    height: 75px;
  }

  #tickets-details-addcart #ticketreserve-acc .tickets-toggle .show-moretickets p {
    font-size: 14px;
    padding-right: 0px;
    padding-left: 3px;
    flex: 1;
  }

  #tickets-details-addcart #ticketreserve-acc .tickets-toggle p {
    font-size: 16.5px;
    flex: 1;
  }

  #tickets-details-addcart #ticketreserve-acc .show-moretickets {
    padding-right: 5px;
    height: 75px;
  }

  #tickets-details .tickets-another-description h4 {
    font-size: 18px;
    line-height: 22px;
  }

  #tickets-details .tickets-addanother .ticket-addfield {
    width: 23px;
    font-size: 20px;
    line-height: 24px;
  }

  .tickets-addremove svg {
    height: 30px;
    width: 30px;
  }

  #tickets-details .tickets-addremove .ticket-addfield {
    width: 23px;
    font-size: 20px;
    line-height: 24px;
  }

  .tickets-addanother svg {
    height: 30px;
    width: 30px;
  }

  .reserve-pricetext {
    font-size: 14px;
    margin-top: -11px;
  }

  .portal-accountcreation-left #merchant-register-btn .Create-Account {
    width: 100%;
  }

  .accessticket-banner h2 {
    display: none;
  }

  .access-ticket {
    padding-top: 30px;
  }

  .access-ticket h1 {
    font-family: "Avenir";
    font-size: 22px;
    margin-bottom: 24px;
    text-transform: uppercase;
  }

  .access-ticket .Create-Account {
    font-family: "Avenir";
    margin-bottom: 29px;
  }

  .access-ticket h2 {
    font-family: "Avenir";
    margin-bottom: 21px;
    margin-top: 0px;
  }

  .access-ticket h6 {
    padding-top: 37px;
  }

  .accessticket .accessticket-banner h2 {
    display: block;
  }

  .accessticket-banner h6 {
    display: none;
  }

  #addtocart-modal-account .checkout-btn .Create-Account {
    width: 100%;
  }

  #addtocart-modal-account .create-acoount-content {
    margin-top: 0px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .ticket-section {
    margin-left: 0px;
  }

  .send-ticket-info .text-radio {
    margin-right: 10px;
  }

  .payment-info-container {
    width: 90%;
  }

  .show-details .cardnumber {
    padding-left: 10px;
    font-size: 16px;
    line-height: 20px;
  }

  .show-details .showdetails-radio input {
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }

  .show-details .card-imgs {
    width: 30px;
    height: 20px;
  }

  .select-cardtype .dropdown-menu.show {
    min-width: 160px !important;
  }

  .show-details {
    padding: 4px 10px;
  }

  .location-img {
    top: 43%;
    margin-top: 0;
  }

  .concert-img {
    padding: 0 10px;
  }

  #blue-banner {
    display: block;
    width: 100%;
  }

  .blue-banner {
    display: none;
  }

  .concert-img h4 {
    display: none;
  }

  #concert-img h4 {
    display: block;
  }

  #concert-img {
    position: absolute;
    bottom: 60px;
    top: unset;
  }
}

@media only screen and (max-width: 480px) {

  #top-swiper .banner-text {
    font-size: 38px;
    letter-spacing: 1.66px;
  }

  .form-bg-mobile1 {
    padding-top: 122px;
  }

  #d-container2 {
    width: 100% !important;
  }

  .series-btn {
    margin-top: 10% !important;
  }

  .d-title-feature {
    font-size: 28px;
  }

  .app-channel-btn {
    font-size: 14px;
  }

  .app-channel-signin {
    font-size: 14px;
  }

  header .navbar ul li a,
  header .navbar ul li.menu-item a {
    padding: 10px !important;
  }

  .d-tvchannel-img {
    width: 148px !important;
    height: 95px !important;
  }

  .network-timeline {
    width: 96%;
  }

  .channel-registration {
    width: 95%;
  }

  /* .container-fluid {
        padding-left: 0 !important;
        padding-right: 0 !important;
    } */

  /* .show-movie-pills {
        overflow-x: scroll;
    } */

  .main-content.extra-tabs-set {
    width: 70%;
  }

  .remaining-photo {
    width: 25%;
  }

  .p-set-next {
    right: -46.5% !important;
  }

  header .navbar ul li a,
  header .navbar ul li.menu-item a {
    padding: 0px !important;
  }

  .footer-podscast {
    height: 654px;
  }

  #view-all-movie {
    flex: 50%;
    max-width: 50%;
  }

  div#viewall-row {
    margin-top: 19px;
  }

  #view-all-podcast {
    flex: 50%;
    max-width: 50%;
    padding: 0 32px;
  }

  #sign-in-logo1 {
    padding-top: 0;
  }

  #app-event-img {
    width: 100%;
  }

  #set-list-footer .privacy-footer {
    padding-right: 0px !important;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
  }

  #tickets-details .tickets-toggle h4 {
    font-size: 18px;
    line-height: 20px;
  }

  .tickets-toggle p {
    font-size: 16px;
    line-height: 18px;
    padding-left: 4px;
  }

  #tickets-details .tickets-description p {
    font-size: 14px;
    line-height: 19px;
    margin-top: 10px;
  }

  #tickets-details.sponsorProducts .tickets-description p {
    font-size: 14px;
    line-height: 19px;
    margin-top: 2px;
  }

  #tickets-details .tickets-dates p {
    font-size: 18px !important;
    padding-left: 14px;
  }

  #tickets-details .tickets-description span {
    font-size: 14px;
    line-height: 19px;
  }

  #tickets-details .tickets-description {
    padding: 0pc 8.5px 28.5px 20px;
  }

  #tickets-details .addtocart {
    width: 135px;
  }

  #tickets-details .tickets-adddscription p {
    font-size: 14px;
    line-height: 19px;
    padding-right: 8px;
  }

  #tickets-details #addcart {
    display: none;
  }

  #tickets-details #add-mobcart {
    display: block;
    margin-top: 18px;
  }

  #tickets-details .tickets-another-description h4 {
    margin-bottom: 7px;
  }

  .portal-accountcreation-left .checkout-btn .Create-Account {
    width: 100%;
  }

  .ordersAccordion .card-header {
    padding: 21.5px 10.5px 23.32px 10px;
  }

  /* .Your-Tickets span {
    font-size: 14px;
    line-height: 24px;
  }

  .ordersAccordion h4 {
    font-size: 15px !important;
    padding-left: 8px;
  } */

  .ordersAccordion .ticketContainer {
    flex-direction: column;
  }

  .ticketContainer .barcodeImage {
    margin-bottom: 15px;
  }

  .ticketContainer .ticketDetails {
    padding: 10px 10px;
  }

  .ticketContainer .ticketDetails>p {
    font-size: 14px;
  }

  .app-banner .d-movie-title {
    font-size: 16.4px !important;
    line-height: 20px !important;
    height: 20px;
  }

  .app-banner .d-view-text {
    font-size: 14px;
    line-height: 18px;
  }

  #tickets-details .ticketreserve-acc .tickets-toggle p {
    font-size: 16px;
  }

  #tickets-details .ticketreserve-acc .tickets-toggle .ticket-reserve {
    font-size: 18px;
    line-height: 20px;
  }

  #tickets-details .ticketreserve-acc .tickets-toggle span {
    font-size: 20px;
    line-height: 24px;
  }

  #tickets-details .ticketreserve-acc .card-header {
    padding: 11px 10px 7px 10px;
  }

  #tickets-details .ticketreserve-acc .ticketreserve-description {
    padding: 6px 10px 21.5px 49px;
  }

  #tickets-details .ticketreserve-description h2 {
    font-size: 16px;
    line-height: 20px;
  }

  #tickets-details .ticketreserve-description h5 {
    font-size: 16px;
    line-height: 20px;
  }

  #tickets-details-addcart .ticketreserve-acc .tickets-toggle p {
    font-size: 16px;
  }

  #tickets-details-addcart .ticketreserve-acc .tickets-toggle .ticket-reserve {
    font-size: 18px;
    line-height: 20px;
  }

  #tickets-details-addcart .ticketreserve-acc .tickets-toggle span {
    font-size: 20px;
    line-height: 24px;
  }

  #tickets-details-addcart .ticketreserve-acc .card-header {
    padding: 11px 10px 7px 10px;
  }

  #tickets-details-addcart .ticketreserve-acc .card-body {
    padding: 6px 10px 21.5px 49px;
  }

  #tickets-details-addcart .ticketreserve-description h2 {
    font-size: 16px;
    line-height: 20px;
  }

  #tickets-details-addcart .ticketreserve-description h5 {
    font-size: 16px;
    line-height: 20px;
  }

  .ticket-banner {
    width: 362px;
    margin: 0 auto;
  }

  .accessticket-banner-content h5 {
    font-size: 20px;
    line-height: 22px;
  }

  .accessticket-banner-content {
    top: 19px;
  }

  .ticket-banner-img .send-ticket-info h4 {
    font-size: 13px;
    line-height: 11px;
    padding-top: 7px;
  }

  .ticket-banner-img .send-ticket-info p>span {
    font-size: 15px;
  }

  .ticket-banner-img .send-ticket-info p {
    font-size: 15px;
    line-height: 19px;
  }

  .ticket-banner-img .ticket-section h2 {
    font-size: 13px;
    line-height: 17px;
  }

  .ticket-banner-img .ticket-section h2>span {
    font-size: 15px;
    line-height: 17px;
  }

  .accessticket-banner-content .send-ticket-info {
    padding-left: 151px;
  }

  .send-ticket-info .text-radio>input {
    height: 20px;
    width: 20px;
  }

  .text-radio>input+label::before {
    height: 20px;
    width: 20px;
  }

  #send-ticket-info .text-radio>input {
    height: 20px;
    width: 20px;
  }

  .text-radio>input:checked+label::before {
    background-size: 16px 18px;
  }

  .paymentinfo-form .save {
    width: 100%;
  }

  .brand-list-1 {
    /* width: 90%; */
    width: 100px;
    height: 77px;
  }

  .brand-list-2 {
    /* width: 90%; */
    width: 200px;
    height: 77px;
  }

  .mobile-brand {
    justify-content: center;
    align-items: center;
  }

  .countdown-btn {
    /* width: 90%; */
    width: 341px;
    height: 53px;
  }

  #checkout-cardpay .stripeButton {
    width: 100%;
  }

  .live-concert {
    width: 100%;
    height: auto;
    padding: 15px 10px;
  }

  .order-mainsection {
    height: auto;
    padding: 56px 15px;
  }

  .ordernow-btn {
    width: 100%;
  }
}

@media only screen and (max-width: 420px) {

  #top-swiper .banner-text {
    font-size: 35px;
    letter-spacing: 1.66px;
  }

  .location-img h4 {
    font-size: 35px;
    line-height: 46px;
  }

  .concert-img h4 {
    font-size: 35px;
    line-height: 45px;
  }

  .newhome-concert h5 {
    font-size: 35px;
    line-height: 45px;
  }

  #portals-ticketcard .ticket-info {
    width: 100%;
    padding: 20px 17px;
  }

  .buyticket-btns {
    width: 100%;
  }

  #portals-ticketcard .order-button {
    width: 100%;
  }

  .link-text {
    font-size: 18px;
  }

}

@media only screen and (max-width: 389px) {

  #top-swiper .banner-text {
    font-size: 35px;
    letter-spacing: 1.66px;
  }

  footer .footer-standard .widget ul li {
    padding-right: 20px;
  }

  .footer-podscast {
    height: 700px;
  }

  .show-episode-text {
    margin-left: 0px !important;
    width: 96px;
    font-size: 12px;
  }

  .show-movie-tab h4 {
    font-size: 12px;
  }

  .show-movie-tab {
    padding-left: 10px;
  }

  .show-time-details-mobile {
    margin-top: 4px;
  }

  #view-all-movie1 {
    flex: 100% 1;
    max-width: 100%;
  }

  #sign-in-logo1 {
    padding-top: 0;
  }

  #tickets-details .addtocart {
    margin-right: 0px;
  }

  .portal-about h1.slider-text.text-uppercase {
    font-size: 23px;
    line-height: 26px;
  }

  .portal-about .WEI-txt {
    font-size: 14px;
    line-height: 17px;
    padding-top: 7px;
  }

  .portal-about .d-btn {
    margin-top: 10px;
  }

  .portal-about #donate-now {
    margin-left: 0px;
  }

  #addtocart-modal .view-cartmodal1 p {
    padding-right: 28px;
  }

  #addtocart-modal .view-cartmodal1 .Checkout {
    margin-left: 32px;
  }

  .app-confirmation .add-tickets1 {
    width: 100%;
  }

  .portal-accountinfo a.nav-item.nav-link.active {
    font-size: 20px;
  }

  .portal-accountinfo a.nav-item.nav-link {
    font-size: 20px;
  }

  .ticket-transfer-quantity .send-ticket-info p>span {
    font-size: 16px;
  }

  .ticket-transfer-quantity .send-ticket-info p {
    font-size: 16px;
  }

  .ticket-transfer-quantity .ticket-section h2 {
    font-size: 14px;
  }

  .ticket-transfer-quantity .ticket-section h2>span {
    font-size: 16px;
  }

  .ticket-transfer-quantity .ticket-section h3 {
    font-size: 14px;
  }

  .ticket-transfer-quantity .ticket-section h3>span {
    font-size: 16px;
  }

  .order-image {
    width: 90%;
    height: auto;
  }

}

@media only screen and (max-width: 375px) {

  #top-swiper .banner-text {
    font-size: 35px;
    letter-spacing: 1.66px;
  }

  #tech-section {
    width: 100%;
    margin: 0 auto;
  }

  .tech-border {
    padding: 5px !important;
  }

  .d-title-feature {
    font-size: 28px;
  }

  .form-bg-mobile1 {
    padding-top: 108px;
  }

  #form-bg-mobile1 {
    padding-top: 77px;
  }

  #sign-in-logo {
    padding-top: 62px;
  }

  #sign-in-logo1 {
    padding-top: 0;
  }

  input#txtLastname {
    width: 100%;
  }

  input#txtFirstname {
    width: 100%;
  }

  #set-input-width {
    width: 100%;
    margin: 0 auto;
  }

  #sign-inbtn:enabled {
    width: 100%;
  }

  #sign-inbtn:disabled {
    width: 100%;
  }

  .d-parallax-img {
    width: 98%;
  }

  .detail-actions {
    display: block;
  }

  .detail-action-btn {
    margin-left: 0px;
  }

  .show-episode {
    font-size: 11px;
    line-height: 15px;
  }

  .show-episode span {
    font-size: 11px;
  }

  .show-star-content h5 {
    font-size: 13px;
    line-height: 19px;
    padding-right: 9px;
  }

  .show-rating-details p {
    font-size: 13px;
    line-height: 19px;
  }

  .show-rating-details h4 {
    font-size: 13px !important;
    line-height: 19px !important;
    padding-left: 8px;
  }

  .show-rating-details h3 {
    font-size: 13px !important;
    line-height: 19px !important;
    padding-left: 8px;
  }

  .show-tv-btn {
    height: 19px;
    width: 53px;
    font-size: 12px;
    line-height: 16px;
    margin-left: 8px;
  }

  .show-mob-btn {
    margin-right: 9px;
  }

  .remaining-photo {
    width: 32%;
  }

  .main-content.extra-tabs-set {
    width: 60%;
  }

  .p-set-next {
    right: -70.5% !important;
  }

  button.btn.show-rent {
    font-size: 11px;
    line-height: 15px;
  }

  a#donate-now {
    margin-top: 10px !important;
    margin-left: 0;
  }

  #view-all-podcast {
    padding: 0 25px;
  }

  #forgot-inbtn {
    width: 100% !important;
  }

  .text-body-pwd {
    width: 100%;
  }

  #tickets-details .card-header {
    padding: 13.5px 6.5px 15.32px 6.5px;
    height: 56.82px !important;
  }

  #tickets-details .tickets-toggle h4 {
    font-size: 16px;
    line-height: 18px;
  }

  .tickets-toggle p {
    font-size: 14px;
    line-height: 18px;
  }

  #tickets-details .tickets-toggle img {
    height: 12px;
  }

  #tickets-details .ticketreserve-acc .tickets-toggle img {
    height: 30px;
  }

  #tickets-details .ticketreserve-acc .tickets-toggle .show-more {
    height: 15.56px;
  }

  #tickets-details-addcart .ticketreserve-acc .tickets-toggle .show-more {
    height: 15.56px;
  }

  #addtocart-modal .view-cartmodal .Checkout {
    margin-left: 17px;
  }

  #addtocart-modal .view-cartmodal p {
    padding-right: 17px;
  }

  .create-return .Create-Account {
    width: 100%;
  }

  .create-return .Sign-In {
    width: 100%;
  }

  .create-return .create-return-form {
    width: 100%;
  }

  .create-return .create-return-form .create-signin {
    width: 100%;
  }

  .portal-accountcreation-left .checkout-btn .Create-Account {
    width: 100%;
  }

  #payment-selection .paypal-method {
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  #payment-selection #apple-pay {
    margin-left: 0px;
    margin-top: 10px;
  }

  #addtocart-modal .Proceed-Checkout-btn {
    width: 90%;
  }

  #add-cart-modal1 .GET-TICKETS {
    width: 90%;
  }

  .portal-location .location-image {
    height: auto;
    width: 90%;
  }

  #payment-selection .Create-Account {
    width: 100%;
  }

  .add-new-address-btn {
    width: 100%;
  }

  #tickets-details #ticketreserve-acc .tickets-toggle span {
    font-size: 16px;
    line-height: 20px;
  }

  #tickets-details #ticketreserve-acc .tickets-toggle .ticket-reserve {
    font-size: 14px;
    line-height: 18px;
  }

  #tickets-details #ticketreserve-acc .tickets-toggle p {
    font-size: 14px;
    line-height: 18px;
  }

  #tickets-details #ticketreserve-acc .ticketreserve-description p>span {
    font-size: 14px;
    line-height: 18px;
  }

  #tickets-details #ticketreserve-acc .ticketreserve-description p>b {
    font-size: 14px;
    line-height: 18px;
  }

  #tickets-details-addcart #ticketreserve-acc .tickets-toggle span {
    font-size: 16px;
    line-height: 20px;
  }

  #tickets-details-addcart #ticketreserve-acc .tickets-toggle b {
    font-size: 16px;
    line-height: 20px;
  }

  #tickets-details-addcart #ticketreserve-acc .tickets-toggle .ticket-reserve {
    font-size: 14px;
    line-height: 18px;
  }

  #tickets-details-addcart #ticketreserve-acc .tickets-toggle p {
    font-size: 14px;
    line-height: 18px;
  }

  #tickets-details-addcart #ticketreserve-acc .ticketreserve-description p>span {
    font-size: 14px;
    line-height: 18px;
  }

  #tickets-details-addcart #ticketreserve-acc .ticketreserve-description p>b {
    font-size: 14px;
    line-height: 18px;
  }

  .accessticket-banner-content h5 {
    font-size: 16px;
    line-height: 20px;
  }

  .accessticket-banner-content {
    top: 13px;
  }

  .ticket-banner-img {
    text-align: center;
  }

  .accessticket-banner-content .send-ticket-info {
    padding-top: 20px;
  }

  .ticket-banner {
    width: 300px;
  }

  .accessticket-banner-content .send-ticket-info {
    padding-left: 120px;
  }

  .location-img h4 {
    font-size: 28px;
    line-height: 36px;
  }

  .concert-img h4 {
    font-size: 28px;
    line-height: 36px;
  }

  .newhome-concert h5 {
    font-size: 28px;
    line-height: 36px;
  }

  .newhome-concert h6 {
    font-size: 24px;
    line-height: 33px;
  }

  .app-header .header-toggle-drawer {
    padding-left: 20px;
    padding-right: 10px;
  }

  .portals-logo {
    margin: 0px 0 0px 0;
    height: 27px;
    width: 198px;
  }

  .countdown-number>p:nth-child(1) {
    font-size: 50px;
  }
}

@media only screen and (max-width: 360px) {

  #top-swiper .banner-text {
    font-size: 35px;
    letter-spacing: 1.66px;
  }

  .sign-in-forgot-pwd {
    width: auto;
  }

  .sign-in-input {
    font-size: 15px !important;
  }

  .sign-in-input-pwd {
    font-size: 15px !important;
  }

  .forgot-input {
    font-size: 15px !important;
  }

  .login-acc {
    padding-top: 0px;
  }

  .sign-in-page {
    background-position-y: 20% !important;
  }

  .tx-uppercase {
    font-size: 14px;
  }

  .d-image-1,
  .d-image-2 {
    width: 200px !important;
  }

  .channel-registration {
    width: 100%;
  }

  .set-progress-bg {
    width: 136px !important;
  }

  .our-ratings {
    width: 261px !important;
  }

  p.slider-text.d-slide-txt {
    font-size: 16px;
    line-height: 20px;
  }

  #home-slider h1.slider-text {
    font-size: 28px;
    line-height: 35px !important;
    margin: 0 10px !important;
  }

  .d-brand-network {
    font-size: 24px;
    line-height: 24px;
  }

  .d-movie-title {
    font-size: 20px !important;
  }

  .d-view-text {
    font-size: 12px;
    line-height: 14px;
  }

  .podcast-center-right {
    margin-left: 10px;
  }

  #view-all-podcast {
    padding: 0 10px;
  }

  #sign-in-logo1 {
    padding-top: 0;
  }

  #video-section #customVideo .jw-button-color:not(.jw-icon-cast),
  #customVideo .jw-button-color.jw-toggle.jw-off:not(.jw-icon-cast) {
    margin-left: -5px;
  }

  #addtocart-modal .addtocart-information h4 {
    font-size: 21px;
    line-height: 29px;
  }

  #addtocart-modal .addtocart-information p {
    font-size: 21px;
    line-height: 29px;
  }

  #addtocart-modal .tickets-festival {
    padding: 30px 5px 29px 10px;
  }

  #addtocart-modal #tickets-festival {
    padding: 15px 10px 24px 10px;
  }

  #addtocart-modal .date-sch {
    padding-left: 15px;
  }

  #addtocart-modal .portal-accountcreation-right {
    width: 87%;
  }

  .header-lineup-text p {
    font-size: 18px;
  }

  .header-lineup-text h4 {
    font-size: 16px;
  }

  #addtocart-modal .portal-qty h6 {
    font-size: 14px;
    line-height: 20px;
    padding-left: 7px;
  }

  .addciright-logo {
    padding: 10px 10px 15px 10px;
  }

  .addciright-logo p {
    width: 160px;
  }

  .backto-ticktes h2 {
    font-size: 18px;
  }

  .access-ticket .Create-Account {
    width: 100%;
  }

  .access-ticket .create-signin {
    width: 100%;
  }

  .access-ticket h2 {
    font-size: 20px;
  }

  .access-ticket h1 {
    font-size: 20px;
  }

  .order-promo {
    width: 95%;
  }

  .link-text {
    font-size: 15px;
  }
}